import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, ChartBarIcon, CheckIcon, ExclamationIcon, LocationMarkerIcon, MinusIcon, PlusIcon, ReplyIcon, WifiIcon, CursorClickIcon } from '@heroicons/react/outline';
import cn from "classnames";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import SwitchTailwind from './switchTailwind';

import snapchatLocations from '../assets/json/snapchat_locations.json';
import snapchatLanguages from '../assets/json/snapchat_languages.json';
import moment from 'moment';

import { adgroupSnapchat } from '../validators/adgroupSnapchat';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions'

class EditLiveSnapchatAdgroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            currency: "",
            error: false,
            wizard: false,
            open: false,
            adgroup: {},
            updated: {},
            client: {},
            loader: false,
            snapchat_pixels: [],
            minAge: { id: 13, name: "13", value: "13" },
            maxAge: { id: 50, name: "50+", value: "50_PLUS" },
            regulated_content: { id: 2, name: "No", value: false },
            auto_expand: { id: 1, name: "Yes", value: true },
            auto_interest: { id: 1, name: "Yes", value: true },
            auto_custom: { id: 1, name: "Yes", value: true },
            custom_audience: { id: 2, name: "No", value: false },
            moat: { id: 2, name: "No", value: false },
            double_verify: { id: 2, name: "No", value: false },
            custom_audience_types: { id: 1, name: "Included", value: true },
            category_type: { id: 1, name: "Included", value: true },
            audience_type: { id: 0, name: "Select ...", value: false },
            lifestyle_category: { id: 0, name: "Click here", value: false },
            lifestyle_category_1: { id: 0, name: "Click here", value: false },
            lifestyle_category_2: { id: 0, name: "Click here", value: false },
            optimization_goal: { id: 3, name: 'Click', value: "SWIPES" },
            pacing: { id: 1, name: "Standard", value: "STANDARD" },
            bid_cap: 0,
            bid_strategy: { id: 1, name: "Auto-Bid (Recommended)", value: "AUTO_BID", allowed: ["impressions", "swipes", "share", "story_opens"] },
            interests_lifestyles: [],
            tab: { id: 1, name: "Settings" },
            all_ages: [],
            selected_audiences: [],
            optimization_goals: [
                { id: 1, name: 'Impressions', value: "IMPRESSIONS", pixel: false, allowed: ["brand_awareness", "app_installs", "video_views"] },
                { id: 2, name: 'Click', value: "SWIPES", pixel: false, allowed: ["web_view", "brand_awareness", "promote_places", "app_installs", "traffic_website", "traffic_app", "engagement", "lead_generation", "app_conversions", "web_conversion"] },
                { id: 3, name: 'App installs', value: "APP_INSTALLS", pixel: false, allowed: ["app_installs", "app_conversions"] },
                { id: 4, name: '2 seccond video view', value: "VIDEO_VIEWS", pixel: false, allowed: ["video_views"] },
                { id: 5, name: '15 second video view', value: "VIDEO_VIEWS_15_SEC", pixel: false, allowed: ["video_views"] },
                // // Not sure about uses, could be "share" ???
                { id: 6, name: 'Uses', value: "USES", pixel: false, allowed: ["brand_awareness"] },
                { id: 7, name: 'Story opens', value: "STORY_OPENS", pixel: false, allowed: ["brand_awareness", "engagement", "lead_generation", "website_conversions"] },
                { id: 8, name: 'App add to cart', value: "APP_ADD_TO_CART", pixel: false, allowed: ["app_installs", "app_conversions"] },
                { id: 9, name: 'App purchase', value: "APP_PURCHASE", pixel: false, allowed: ["app_installs", "app_conversions"] },
                { id: 10, name: 'App signup', value: "APP_SIGNUP", pixel: false, allowed: ["app_installs", "app_conversions"] },
                { id: 11, name: 'App reengage open views', value: "APP_REENGAGE_OPEN", pixel: false, allowed: [""] },
                { id: 12, name: 'App reengage purchase', value: "APP_REENGAGE_PURCHASE", pixel: false, allowed: [""] },
                { id: 13, name: 'Lead form submissions', value: "LEAD_FORM_SUBMISSIONS", pixel: false, allowed: ["lead_generation"] },
                // // PIXEL REQUIRED
                { id: 14, name: 'Pixel page view', value: "PIXEL_PAGE_VIEW", pixel: true, allowed: ["traffic_website", "website_conversions"] },
                { id: 15, name: 'Pixel add to cart', value: "PIXEL_ADD_TO_CART", pixel: true, allowed: ["app_installs", "website_conversions"] },
                { id: 16, name: 'Pixel purchase', value: "PIXEL_PURCHASE", pixel: true, allowed: ["app_installs", "engagement", "website_conversions"] },
                { id: 17, name: 'Pixel signup', value: "PIXEL_SIGNUP", pixel: true, allowed: ["app_installs", "lead_generation", "website_conversions"] },
                //{ id: 18, name: 'Share', value: "SHARE", pixel: false, allowed: ["brand_awareness", "engagement"] },
            ],
            tabs: [
                { id: 1, name: "Settings" },
                { id: 2, name: "Budget & Schedule" },
                { id: 3, name: "Placements" },
                { id: 4, name: "Locations" },
                { id: 5, name: "Demographics" },
                { id: 6, name: "Audiences" },
                { id: 7, name: "Devices" },
                { id: 8, name: "Delivery" },
            ],
            content_placements_options: [
                { id: 1, name: "News", value: "NEWS" },
                { id: 2, name: "Entertainment", value: "ENTERTAINMENT" },
                { id: 3, name: "Science & Technology", value: "SCIENCE_TECHNOLOGY" },
                { id: 4, name: "Beauty & Fashion", value: "BEAUTY_FASHION" },
                { id: 5, name: "Men's Lifestyle", value: "MENS_LIFESTYLE" },
                { id: 6, name: "Women's Lifestyle", value: "WOMENS_LIFESTYLE" },
                { id: 7, name: "Gaming", value: "GAMING" },
                { id: 8, name: "General Lifestyle", value: "GENERAL_LIFESTYLE" },
                { id: 9, name: "Food", value: "FOOD" },
                { id: 10, name: "Sports", value: "SPORTS" },
                { id: 11, name: "Young & Bold", value: "YOUNG_BOLD" }

            ],
            auto_placements: { id: 1, name: "Yes", value: true },
            custom_placements: { id: 2, name: "No", value: false },
            placement_story: { id: 1, name: "Yes", value: true },
            placement_content: { id: 1, name: "Yes", value: true },
            placement_spotlight: { id: 1, name: "Yes", value: true },
            placement_feed: { id: 1, name: "Yes", value: true },
            placement_categories: { id: 1, name: "Yes", value: true },
            between_contetnt: { id: 1, name: "Yes", value: true },
            selected_content_placements: [],
            bid_strategies: [
                { id: 1, name: "Auto-Bid (Recommended)", value: "AUTO_BID", allowed: ["impressions", "swipes", "share", "story_opens"] },
                { id: 2, name: "Target Cost", value: "TARGET_COST", allowed: ["swipes", "share", "story_opens"] },
                { id: 3, name: "Max Bid", value: "LOWEST_COST_WITH_MAX_BID", allowed: ["impressions", "swipes", "share", "story_opens"] },
            ],
            status_values: [
                { id: 1, name: 'Active', value: "ACTIVE" }, { id: 2, name: 'Paused', value: "PAUSED" }
            ],
            genders: [
                { id: 1, name: "All genders", value: "GENDER_UNLIMITED" },
                { id: 2, name: "Men only", value: "GENDER_MALE" },
                { id: 3, name: "Female only", value: "GENDER_FEMALE" }
            ],
            budget_modes: [
                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
            ],
            languages: {
                search: "",
                loading: false,
                options: snapchatLanguages.map((item) => { return { name: item.name, id: item.id, included: true } }),
                type: { id: 1, name: "Included", value: true }
            },
            locations: {
                search: "",
                loading: false,
                options: JSON.parse(JSON.stringify(snapchatLocations)).map((item, index) => {
                    if (item.parent_id && !item.metro) {
                        let parent = snapchatLocations.filter((inner_item) => { return inner_item.id === item.parent_id })[0].name
                        item.name = item.name + ", " + parent.charAt(0).toUpperCase() + parent.slice(1) + ", " + "region";
                    } else if (item.metro) {
                        let parent = snapchatLocations.filter((inner_item) => { return inner_item.id === item.parent_id })[0].name
                        item.name = item.name + " (" + item.regions.toUpperCase() + "), " + parent.charAt(0).toUpperCase() + parent.slice(1) + ", " + "metro";

                    }
                    return item
                }),
                type: { id: 1, name: "Included", value: true },
            },
            genders: [
                { id: 1, name: "All genders", value: "ALL", included: true },
                { id: 2, name: "Male only", value: "MALE", included: false },
                { id: 3, name: "Female only", value: "FEMALE", included: false }
            ],
            gender: { id: 1, name: "All genders", value: "ALL", included: true },
            operating_systems: [
                { id: 1, name: "All", value: "ALL", included: true },
                { id: 2, name: "Android", value: "ANDROID", included: false },
                { id: 3, name: "iOS", value: "iOS", included: false },
            ],
            connection_types: [
                { id: 1, name: "All", value: "ALL", included: true },
                { id: 2, name: "Cell", value: "CELL", included: false },
                { id: 3, name: "Wifi", value: "WIFI", included: false },
            ],
            all_os_versions: [],
            min_os_version: { id: 0, name: "Select ...", value: false },
            max_os_version: { id: 0, name: "Select ...", value: false },
            selected_languages: [],
            selected_locations: [],
            selected_lifestyles: [],
            selected_carriers: [],
            selected_devices: [],
            all_carriers: [],
            all_devices: [],
            search_category: "",
            custom_audiences: {
                search: "",
                options: [],
            },
            locked: false,
            map: { id: 1, name: "List View", value: false },
            center: { lat: 40.64, lng: -73.96 },
            zoom: 6,
            place: null,
            place_default: {
                "latitude": 59.32932349999999,
                "longitude": 18.0685808,
                "radius": 30000,
                "name": "(SE) Stockholm, Sweden, (59.329 lat, 18.069 lng, 30km)",
                "custom": true,
                "negative": false,
                "included": true,
                "include": true,
                "id": 4919442428,
                "code": "se",
                "lat": 59.32932349999999,
                "lng": 18.0685808
            },
            location_type: { id: 1, name: "Included", value: true },
            google_maps_options: []
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adgroup: this.props.adgroup,
            client: this.props.client,
            wizard: this.props.wizard,
            campaigns: this.props.campaigns,
            locked: this.props.locked ? this.props.locked : false,
            loading_map: true
        });

        await this.functions.listCarriers();
        await this.functions.listDevices();
        await this.functions.listCustomAudiences();
        await this.functions.parseData();
        this.functions.getSnapchatPixel();
        //await this.functions.parseData(true);
        for (let i = 13; i <= 50; i++) {
            let ageObj = {
                id: i,
                name: i === 50 ? `${i}+` : `${i}`,
                value: i === 50 ? `${i}_PLUS` : `${i}`,
            };
            this.state.all_ages.push(ageObj);
        }
        await this.promisedSetState({
            all_ages: this.state.all_ages
        })
        if (this.state.client && this.state.client.currency && this.state.client.currency.snapchat) {
            await this.promisedSetState({
                currency: this.state.client.currency.snapchat
            })
        }

        this.functions.map().then((response) => {
            try {
                this.autocomplete = new response.maps.places.AutocompleteService();
                this.geocoder = new response.maps.Geocoder;
            } catch (e) { }
        });

        try {
            if (Object.keys(this.state.adgroup).length > 0 && Array.isArray(this.state.adgroup.locations) && this.state.adgroup.locations.length > 0) {
                let only_custom = true;
                for (let location of this.state.adgroup.locations) {
                    if (!location.hasOwnProperty('custom')) {
                        only_custom = false;
                        break;
                    }
                }

                if (only_custom) {
                    this.state.map = { id: 2, name: "Map View", value: true }
                    this.state.place = this.state.adgroup.locations[0];
                    if (!this.state.place.lat) {
                        this.state.place.lat = this.state.place.latitude;
                    }
                    if (!this.state.place.lng) {
                        this.state.place.lng = this.state.place.longitude;
                    }
                    this.promisedSetState({ map: this.state.map, place: this.state.place });
                }
            }
        } catch (error) { }
        console.log(this.state.adgroup, "==================")
        console.log(moment(this.renders.startDate()).format("YYYY-MM-DD"), "2222==================")
        await this.promisedSetState({ loading_map: false });
    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {
        update: async (data = false) => {
            if (!this.state.wizard) {
                this.props.onSuccess(false);
                this.props.onLoader(true);
                this.promisedSetState({
                    error: false
                });
                if (!data) {
                    if (Object.keys(this.state.updated).length > 0) {
                        let body = JSON.parse(JSON.stringify(this.state.adgroup.origin_data));
                        delete body.locations;
                        delete body.partial_parced;
                        [
                            "channel", "level", "account", "status", "meta", "metrics",
                            "internal_campaign", "loading", "client", "client_name",
                            "scheduleDatesActivated", "scheduleDates", "budgetPlanerActivated",
                            "budgetPlanerTotal", "scheduleActivated", "scheduleMonday",
                            "scheduleTuesday", "scheduleWednesday", "scheduleThursday",
                            "scheduleFriday", "scheduleSaturday", "scheduleSunday", "campaign"
                        ].map((key) => {
                            delete body[key];
                        });
                        body.status = this.renders.status().value;
                        if (this.state.updated) {
                            if (this.state.updated.name) body.name = this.state.updated.name;
                            if (this.state.updated.pixel_id) body.pixel_id = this.state.updated.pixel_id.pixel_id;
                            if (this.state.updated.daily_budget) body.daily_budget_micro = this.renders.dailyBudget() * 1000000;
                            if (this.state.updated.lifetime_budget) body.lifetime_budget_micro = this.renders.lifetimeBudget() * 1000000;
                            if (this.state.updated.start_date) body.start = this.state.updated.start_date;
                            if (this.state.updated.end_date) body.end = this.state.updated.start_date;
                            if (this.state.updated.locations) {
                                body.targeting.geos = this.state.updated.locations.filter((item) => { return !item.custom }).map(location => {
                                    if (location.parent_id && !location.metro) {
                                        return {
                                            country_code: location.code,
                                            region_id: [location.id],
                                        }
                                    } else if (location.parent_id && location.metro) {
                                        return {
                                            country_code: location.parent_code,
                                            metro_id: [location.external_id],
                                        }
                                    } else {
                                        return {
                                            country_code: location.code,
                                        }
                                    }
                                })

                                if (this.state.updated.locations.filter((item) => { return item.custom }).length > 0) {
                                    let code = this.state.updated.locations.filter((item) => { return item.custom }).map((item) => { return item.code });
                                    code.forEach(item => {
                                        if (item && body.targeting.geos.filter((inner_item) => { return inner_item.country_code === item }).length === 0) {
                                            body.targeting.geos.push({ country_code: item })
                                        }
                                    })

                                    let included_customs = this.state.updated.locations.filter((item) => { return item.custom && item.included });
                                    let excluded_customs = this.state.updated.locations.filter((item) => { return item.custom && !item.included });

                                    body.targeting.locations = [
                                        {
                                            circles: included_customs.map((item) => {
                                                return {
                                                    latitude: item.lat ? item.lat : item.latitude,
                                                    longitude: item.lng ? item.lng : item.longitude,
                                                    radius: item.new_location ? item.radius / 1000 : item.radius,
                                                    name: item.name,
                                                    ...(item.new_location ? { unit: "KILOMETERS" } : {})
                                                }
                                            }),
                                            operation: "INCLUDE"
                                        },
                                        ...(excluded_customs.length > 0 ? [{
                                            circles: excluded_customs.map((item) => {
                                                return {
                                                    latitude: item.lat ? item.lat : item.latitude,
                                                    longitude: item.lng ? item.lng : item.longitude,
                                                    radius: item.new_location ? item.radius / 1000 : item.radius,
                                                    name: item.name,
                                                    ...(item.new_location ? { unit: "KILOMETERS" } : {})
                                                }
                                            }),
                                            operation: "EXCLUDE"
                                        }] : [])
                                    ]
                                } else {
                                    delete body.targeting.locations;
                                }
                                body.end = this.state.updated.start_date;
                            }
                            if (this.state.updated.auto_placements || this.state.updated.custom_placements || this.state.updated.placement_story || this.state.updated.placement_content || this.state.updated.placement_spotlight || this.state.updated.placement_feed || this.state.updated.selected_content_placements) {
                                body.placement_v2 = {
                                    config: this.renders.autoPlacements().value ? "AUTOMATIC" : "CUSTOM",
                                }
                                if (!this.renders.autoPlacements().value) {
                                    body.placement_v2.platforms = ["SNAPCHAT"];
                                    body.placement_v2.snapchat_positions = [];
                                    if (this.renders.categories().value) body.placement_v2.snapchat_positions.push("INSTREAM");
                                    if (this.renders.stories().value) body.placement_v2.snapchat_positions.push("INTERSTITIAL_USER");
                                    if (this.renders.content().value) body.placement_v2.snapchat_positions.push("INTERSTITIAL_CONTENT");
                                    if (this.renders.spotlight().value) body.placement_v2.snapchat_positions.push("INTERSTITIAL_SPOTLIGHT");
                                    if (this.renders.feed().value) body.placement_v2.snapchat_positions.push("FEED");
                                    if (this.renders.selected_content_placements().length > 0) {
                                        if (this.renders.selected_content_placements().filter((item) => { return item.included }).length > 0) {
                                            body.placement_v2.inclusion = {
                                                content_types: this.renders.selected_content_placements().filter((item) => { return item.included }).map((item) => { return item.value })
                                            }
                                        }
                                        if (this.renders.selected_content_placements().filter((item) => { return !item.included }).length > 0) {
                                            body.placement_v2.exclusion = {
                                                content_types: this.renders.selected_content_placements().filter((item) => { return !item.included }).map((item) => { return item.value })
                                            }
                                        }
                                    }

                                }
                                if (body.placement_v2.snapchat_positions && body.placement_v2.snapchat_positions.length === 0) {
                                    body.placement_v2 = {
                                        config: "AUTOMATIC"
                                    };
                                }
                            }

                            if (this.state.updated.regulated_content) {
                                body.targeting.regulated_content = this.state.updated.regulated_content.value;
                            }
                            if (this.state.updated.min_age || this.state.updated.max_age || this.state.updated.gender || this.state.updated.languages) {
                                body.targeting.demographics = [{}];
                                body.targeting.demographics[0].min_age = this.renders.minAge().value;
                                if (this.renders.maxAge().id !== 50) body.targeting.demographics[0].max_age = this.renders.maxAge().value;
                                if (this.renders.gender().id !== 1) {
                                    body.targeting.demographics[0].gender = this.renders.gender().value;
                                };
                                body.targeting.demographics[0].languages = this.renders.languages().map((item) => { return item.id });
                            }

                            if (this.state.updated.auto_expand || this.state.updated.auto_interest || this.state.updated.auto_custom) {
                                body.enable_targeting_expansion = this.renders.autoExpand().value;
                                body.targeting.auto_expansion_options = {
                                    interest_expansion_option: { enabled: this.renders.autoInterest().value },
                                    custom_audience_expansion_option: { enabled: this.renders.autoCustom().value }
                                }
                            }
                            if (this.state.updated.selected_audiences) {
                                if (this.state.updated.selected_audiences.length > 0) {
                                    body.targeting.segments = [];
                                    if (this.state.updated.selected_audiences.filter((item) => { return item.included }).length > 0) {
                                        body.targeting.segments.push({ segment_id: this.state.updated.selected_audiences.filter((item) => { return item.included }).map((item) => { return item.id }) })
                                    }
                                    if (this.state.updated.selected_audiences.filter((item) => { return !item.included }).length > 0) {
                                        body.targeting.segments.push({ segment_id: this.state.updated.selected_audiences.filter((item) => { return !item.included }).map((item) => { return item.id }), operation: "EXCLUDE" })
                                    }
                                } else {
                                    body.targeting.segments = [];
                                }
                            }

                            if (this.state.updated.selected_lifestyles) {
                                if (this.state.updated.selected_lifestyles.length > 0) {
                                    body.interests = [
                                        {
                                            category_id: this.state.updated.selected_lifestyles.map((item) => { return item.id }),
                                            operation: "INCLUDE"
                                        }
                                    ]
                                } else delete body.interests;
                            }
                            if (this.state.updated.selected_carriers || this.state.updated.selected_devices || this.state.updated.min_os_version || this.state.updated.max_os_version || this.state.updated.connection_types || this.state.updated.operating_system) {
                                body.targeting.devices = [{}];
                                body.targeting.devices[0].operation = "INCLUDE";
                                if (this.renders.selected_carriers().length > 0) body.targeting.devices[0].carrier_id = this.renders.selected_carriers().map((item) => { return item.id });
                                if (this.renders.selected_devices().length > 0) body.targeting.devices[0].marketing_name = this.renders.selected_devices().map((item) => { return item.id });
                                if ((this.state.updated.operating_system && this.state.updated.operating_system.id !== 1) || (this.state.operating_systems.filter((item) => { return item.included && item.id !== 1 }).length > 0)) {
                                    body.targeting.devices[0].os_type = (this.state.updated.operating_system ? this.state.updated.operating_system.value : this.state.operating_systems.filter((item) => { return item.included && item.id !== 1 })[0].value)
                                }
                                if ((this.state.updated.connection_types && this.state.updated.connection_types.id !== 1) || (this.state.connection_types.filter((item) => { return item.included && item.id !== 1 }).length > 0)) {
                                    body.targeting.devices[0].connection_type = (this.state.updated.connection_types ? this.state.updated.connection_types.value : this.state.connection_types.filter((item) => { return item.included && item.id !== 1 })[0].value)
                                }
                                if (this.state.updated.min_os_version) body.targeting.devices[0].os_version_min = this.state.updated.min_os_version.name;
                                if (this.state.updated.max_os_version) body.targeting.devices[0].os_version_max = this.state.updated.max_os_version.name;



                            }
                            if (this.state.updated.moat || this.state.updated.double_verify) {
                                body.measurement_provider_names = [];
                                if (this.renders.moat().value) body.measurement_provider_names.push("MOAT_SS");
                                if (this.renders.doubleVerify().value) body.measurement_provider_names.push("DOUBLEVERIFY");

                            }
                            if (this.state.updated.bid_cap) {
                                body.bid_micro = this.renders.bidCap() * 1000000;
                            }
                            if (this.state.updated.bid_strategy) {
                                if (this.state.updated.bid_strategy.value === "TARGET_COST") {
                                    body.bid_micro = this.renders.bidCap() * 1000000;
                                    body.pacing_type = "STANDARD"
                                    body.target_bid = true;
                                    body.auto_bid = false;

                                } else if (this.state.updated.bid_strategy.value === "LOWEST_COST_WITH_MAX_BID") {
                                    body.bid_micro = this.renders.bidCap() * 1000000;
                                    body.pacing_type = this.renders.pacing().value;
                                    body.target_bid = false;
                                    body.auto_bid = false;

                                } else {
                                    if (body.bid_micro) delete body.bid_micro;
                                    body.pacing_type = "STANDARD"
                                    body.target_bid = false;
                                    body.auto_bid = true;
                                }
                                //body.optimization_goal = this.state.updated.optimization_goal.value;
                            }
                            if (this.state.updated.pacing) {
                                body.pacing_type = this.renders.pacing().value;
                            }
                            body.start = moment(body.start);
                            if (body.end) body.end = moment(body.end);
                            Object.keys(body).map((key) => {
                                if (body[key] === null) {
                                    delete body[key];
                                }
                            })
                        }
                        body.changelog = {
                            initial_data: this.state.adgroup,
                            updated_data: this.state.updated,
                            level: "adgroup",
                            order: window.location.pathname.split("/").pop()
                        }
                        try {
                            let response = await this.calls.updateLiveAdgroup(body);
                            if (response.data.error) {
                                this.promisedSetState({
                                    error: response.data.error
                                });
                            }
                        } catch (error) {
                            //this.props.onError(error.error ? error.error : "Something went wrong");
                        }
                    }
                } else {
                    this.promisedSetState({
                        loading_status: true
                    });
                    try {
                        if (data.value === "PAUSED") {
                            this.props.onPauseAdgroup(true);
                        } else {
                            this.props.onActivateAdgroup(true);
                        }

                        this.state.updated.status = data;
                        this.promisedSetState({
                            loading_status: false,
                            updated: this.state.updated
                        });

                    } catch (error) {
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                if (!this.state.error) {
                    if (!data) this.props.onSuccess(true);
                }
                this.props.onLoader(false);
            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        getSnapchatPixel: async () => {
            try {
                if (this.state.snapchat_pixels.length === 0) {
                    let response = await this.calls.getSnapchatPixel();
                    this.setState({ snapchat_pixels: response.data })
                }
            } catch (error) {
                console.log(error)
            }
        },
        listOsVersions: async (type) => {
            return new Promise(async (resolve) => {
                try {
                    let response = await this.calls.listOsVersions({ type: type });
                    await this.promisedSetState({ all_os_versions: response.data })
                } catch (error) {

                }
                resolve();
            })
        },
        listInterests: async () => {
            return new Promise(async (resolve) => {
                try {
                    if (this.renders.locations().length > 0) {
                        let buffer = [];
                        let countries = [];
                        this.renders.locations().map((item) => {
                            if (item.metro) {
                                if (!countries.includes(this.state.locations.options.filter((inner_item) => { return inner_item.id === item.parent_id })[0].code)) {
                                    countries.push(this.state.locations.options.filter((inner_item) => { return inner_item.id === item.parent_id })[0].code);
                                }
                            } else if (!countries.includes(item.code)) {
                                countries.push(item.code);
                            }
                        })
                        for (let i = 0; i < countries.length; i++) {
                            // GET for each
                            let response = await this.calls.listInterests(countries[i]);
                            let response2 = await this.calls.listInterestsVisitors(countries[i]);
                            if (response.data || response2.data) {
                                if (buffer.length === 0) {
                                    buffer = response.data.concat(response2.data);
                                } else {
                                    buffer = JSON.parse(JSON.stringify(buffer.map((item) => {
                                        if (response.data.filter((inner_item) => { return inner_item.id === item.id }).length > 0 || response2.data.filter((inner_item) => { return inner_item.id === item.id }).length > 0) {
                                            return item;
                                        } else return null;
                                    }).filter((item) => { return item })));
                                }
                            }
                        }
                        await this.promisedSetState({
                            interests_lifestyles: buffer
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
                resolve();
            })
            /*
            if (Array.isArray(this.state.updated.selected_lifestyles) && this.state.updated.selected_lifestyles.length > 0) {
                let error = false;
                for (let i = 0; i < this.state.updated.selected_lifestyles.length; i++) {
                    if (this.state.interests_lifestyles.filter((item) => {
                        return item.id === this.state.updated.selected_lifestyles[i].id
                    }).length > 0) {
                    } else {
                        this.state.updated.selected_lifestyles = this.state.updated.selected_lifestyles.filter((item) => {
                            return item.id !== this.state.updated.selected_lifestyles[i].id
                        })
                        error = true;
                    }
                }
                this.promisedSetState({
                    updated: this.state.updated,
                    interestError: error
                })
            }*/
        },
        listCarriers: async () => {
            return new Promise(async (resolve) => {
                try {
                    let response = await this.calls.listCarriers();
                    response.data.map(carrier => {
                        carrier.name = carrier.name + " (" + carrier.valid_country.toUpperCase() + ")"
                        return carrier
                    })
                    await this.promisedSetState({ all_carriers: response.data })
                } catch (error) {
                    console.log(error)
                }
                resolve();
            })
        },
        listDevices: async () => {
            return new Promise(async (resolve) => {
                try {
                    let response = await this.calls.listDevices();
                    await this.promisedSetState({ all_devices: response.data })
                } catch (error) {
                    console.log(error)
                }
                resolve();
            })
        },
        listCustomAudiences: async () => {
            return new Promise(async (resolve) => {
                try {
                    let response = await this.calls.listCustomAudiences();
                    this.state.custom_audiences.options = response.data;
                    this.setState({ custom_audiences: this.state.custom_audiences })
                } catch (error) {
                    console.log(error)
                }
                resolve();
            })
        },
        parseData: async () => {
            // SLOWLY MOVE THIS TO BACKEND IF THERE IS TIME
            return new Promise(async (resolve) => {

                if (this.state.adgroup.auto_bid === false || this.state.adgroup.target_bid === true) {
                    await this.promisedSetState({
                        bid_strategy: this.state.adgroup.target_bid ? { id: 2, name: "Target Cost", value: "TARGET_COST", allowed: ["swipes", "share", "story_opens"] } : { id: 3, name: "Max Bid", value: "LOWEST_COST_WITH_MAX_BID", allowed: ["impressions", "swipes", "share", "story_opens"] }
                    })
                }
                if (this.state.adgroup.bid_micro !== null) {
                    await this.promisedSetState({
                        bid_cap: this.state.adgroup.bid_micro
                    })
                }
                if (this.state.adgroup.pacing_type === "ACCELERATED") {
                    await this.promisedSetState({
                        pacing: { id: 2, name: "Accelerated", value: "ACCELERATED" }
                    })
                }
                if (this.state.adgroup.optimization_goal) {
                    if (this.state.optimization_goals.filter((item) => { return item.value === this.state.adgroup.optimization_goal }).length > 0) {
                        await this.promisedSetState({
                            optimization_goal: this.state.optimization_goals.filter((item) => { return item.value === this.state.adgroup.optimization_goal })[0]
                        })
                    }
                }
                if (Array.isArray(this.state.adgroup.measurement_provider_names)) {
                    if (this.state.adgroup.measurement_provider_names.includes("MOAT_SS")) {
                        await this.promisedSetState({
                            moat: { id: 1, name: "Yes", value: "MOAT_SS" }
                        })
                    }
                    if (this.state.adgroup.measurement_provider_names.includes("DOUBLEVERIFY")) {
                        await this.promisedSetState({
                            double_verify: { id: 1, name: "Yes", value: "DOUBLEVERIFY" }
                        })
                    }
                }
                if (this.state.adgroup.placement_v2) {
                    if (this.state.adgroup.placement_v2.config === "CUSTOM") {

                        if (this.state.adgroup.placement_v2.inclusion && Array.isArray(this.state.adgroup.placement_v2.inclusion.content_types)) {
                            await this.promisedSetState({
                                selected_content_placements: this.state.selected_content_placements.concat(this.state.content_placements_options.filter((item) => { return this.state.adgroup.placement_v2.inclusion.content_types.includes(item.value) }).map((item) => { return { ...item, ...{ included: true } } }))
                            })
                        }
                        if (this.state.adgroup.placement_v2.exclusion && Array.isArray(this.state.adgroup.placement_v2.exclusion.content_types)) {
                            await this.promisedSetState({
                                selected_content_placements: this.state.selected_content_placements.concat(this.state.content_placements_options.filter((item) => { return this.state.adgroup.placement_v2.exclusion.content_types.includes(item.value) }).map((item) => { return { ...item, ...{ included: false } } }))
                            })
                        }
                        if (Array.isArray(this.state.adgroup.placement_v2.snapchat_positions)) {
                            await this.promisedSetState({
                                placement_story: this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_USER") ? this.state.placement_story : { id: 2, name: "No", value: false },
                                placement_content: this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_CONTENT") ? this.state.placement_content : { id: 2, name: "No", value: false },
                                placement_spotlight: this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_SPOTLIGHT") ? this.state.placement_spotlight : { id: 2, name: "No", value: false },
                                placement_feed: this.state.adgroup.placement_v2.snapchat_positions.includes("FEED") ? this.state.placement_feed : { id: 2, name: "No", value: false },
                                placement_categories: this.state.adgroup.placement_v2.snapchat_positions.includes("INSTREAM") ? this.state.placement_categories : { id: 2, name: "No", value: false },
                                between_contetnt: this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_USER") && this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_CONTENT") && this.state.adgroup.placement_v2.snapchat_positions.includes("INTERSTITIAL_SPOTLIGHT") ? this.state.between_contetnt : { id: 2, name: "No", value: false },
                            })
                        }
                        await this.promisedSetState({
                            custom_placements: { id: 1, name: "Yes", value: true },
                            auto_placements: { id: 2, name: "No", value: false }
                        })

                    }
                }

                if (this.state.adgroup.targeting) {
                    // //LOCATIONS
                    // if (this.state.adgroup.targeting.locations) {
                    //     this.state.adgroup.targeting.locations[0].circles.map((item) => {
                    //         this.state.selected_locations.push({
                    //             ...item,
                    //             ...{
                    //                 custom: true,
                    //                 negative: false,
                    //                 new_location: true,
                    //                 included: true,
                    //                 id: Math.floor((Math.random() * 9999999999) + 1),
                    //                 radius_units: "KILOMETERS",
                    //                 code: ((item.name.match(/\((.*?)\)/) || [])[1] || '').toLowerCase()
                    //             }
                    //         })
                    //     })
                    //     await this.promisedSetState({
                    //         selected_locations: this.state.selected_locations
                    //     })
                    // } else if (this.state.adgroup.targeting.geos) {
                    //     this.state.adgroup.targeting.geos.map((item) => {
                    //         if (item.region_id) {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.id === item.region_id[0] })[0], ...{ included: true } })
                    //         } else if (item.metro_id) {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.external_id === item.metro_id[0] })[0], ...{ included: true } })
                    //         } else {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.code === item.country_code && !inner_item.parent_id })[0], ...{ included: true } })
                    //         }
                    //     })
                    //     await this.promisedSetState({
                    //         selected_locations: this.state.selected_locations
                    //     })
                    // }

                    // OLD
                    // if (this.state.adgroup.targeting.geos) {
                    //     this.state.adgroup.targeting.geos.map((item) => {
                    //         if (item.region_id) {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.id === item.region_id[0] })[0], ...{ included: true } })
                    //         } else if (item.metro_id) {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.external_id === item.metro_id[0] })[0], ...{ included: true } })
                    //         } else {
                    //             this.state.selected_locations.push({ ...this.state.locations.options.filter((inner_item) => { return inner_item.code === item.country_code && !inner_item.parent_id })[0], ...{ included: true } })
                    //         }
                    //     })
                    //     await this.promisedSetState({
                    //         selected_locations: this.state.selected_locations
                    //     })
                    // }

                    if (this.state.adgroup.targeting.regulated_content === true) {
                        await this.promisedSetState({
                            regulated_content: { id: 1, name: "Yes", value: true }
                        })
                    }
                    if (Array.isArray(this.state.adgroup.targeting.demographics)) {
                        if (this.state.adgroup.targeting.demographics[0].min_age) {
                            await this.promisedSetState({
                                minAge: { id: this.state.adgroup.targeting.demographics[0].min_age, name: this.state.adgroup.targeting.demographics[0].min_age, value: this.state.adgroup.targeting.demographics[0].min_age }
                            })
                        }
                        if (this.state.adgroup.targeting.demographics[0].max_age) {
                            let max = { id: this.state.adgroup.targeting.demographics[0].max_age, name: this.state.adgroup.targeting.demographics[0].max_age, value: this.state.adgroup.targeting.demographics[0].max_age }
                            if (this.state.adgroup.targeting.demographics[0].max_age == "50_PLUS") {
                                max = { id: 50, name: "50+", value: "50_PLUS" }
                            }
                            await this.promisedSetState({
                                maxAge: max
                            })
                        }
                        if (this.state.adgroup.targeting.demographics[0].gender) {
                            if (this.state.genders.filter((item) => { return item.value === this.state.adgroup.targeting.demographics[0].gender }).length > 0) {
                                await this.promisedSetState({
                                    gender: { ...JSON.parse(JSON.stringify(this.state.genders.filter((item) => { return item.value === this.state.adgroup.targeting.demographics[0].gender })[0])), ...{ included: true } },
                                    genders: JSON.parse(JSON.stringify(this.state.genders)).map((item) => { if (item.value === this.state.adgroup.targeting.demographics[0].gender) { item.included = true } else item.included = false; return item })
                                })
                            }
                        }
                        if (this.state.adgroup.targeting.demographics[0].languages) {
                            await this.promisedSetState({
                                selected_languages: JSON.parse(JSON.stringify(this.state.languages.options.filter((item) => { return this.state.adgroup.targeting.demographics[0].languages.includes(item.id) }))),
                            })

                        }
                        if (!this.state.adgroup.targeting.enable_targeting_expansion) {
                            await this.promisedSetState({
                                auto_expand: { id: 2, name: "No", value: false }
                            })
                        } else {
                            if (this.state.adgroup.targeting.auto_expansion_options && this.state.adgroup.targeting.auto_expansion_options.custom_audience_expansion_option && this.state.adgroup.targeting.auto_expansion_options.interest_expansion_option) {
                                if (!this.state.adgroup.targeting.auto_expansion_options.custom_audience_expansion_option.enabled) {
                                    await this.promisedSetState({
                                        auto_custom: { id: 2, name: "No", value: false }
                                    })
                                }
                                if (!this.state.adgroup.targeting.auto_expansion_options.interest_expansion_option.enabled) {
                                    await this.promisedSetState({
                                        auto_interest: { id: 2, name: "No", value: false }
                                    })
                                }

                            }
                        }
                        if (Array.isArray(this.state.adgroup.targeting.segments)) {
                            await this.promisedSetState({
                                custom_audience: { id: 1, name: "Yes", value: true }
                            })

                            for (let i = 0; i < this.state.adgroup.targeting.segments.length; i++) {
                                let exclude = [];
                                let include = [];
                                if (this.state.adgroup.targeting.segments[i].operation && this.state.adgroup.targeting.segments[i].operation === "EXCLUDE") {
                                    this.state.adgroup.targeting.segments[i].segment_id.map((item) => {
                                        if (this.state.custom_audiences.options.filter((inner_item) => { return item === inner_item.id }).length > 0) {
                                            exclude.push(this.state.custom_audiences.options.filter((inner_item) => { return item === inner_item.id })[0])
                                        }
                                    })
                                } else {
                                    this.state.adgroup.targeting.segments[i].segment_id.map((item) => {
                                        if (this.state.custom_audiences.options.filter((inner_item) => { return item === inner_item.id }).length > 0) {
                                            include.push(this.state.custom_audiences.options.filter((inner_item) => { return item === inner_item.id })[0])
                                        }
                                    })
                                }
                                if (exclude.length > 0 || include.length > 0) {
                                    if (include.length > 0) {
                                        include = include.map((item) => { item.included = true; return item });
                                        this.state.selected_audiences = this.state.selected_audiences.concat(include)
                                    }
                                    if (exclude.length > 0) {
                                        exclude = exclude.map((item) => { item.included = false; return item });
                                        this.state.selected_audiences = this.state.selected_audiences.concat(exclude)
                                    }
                                    await this.promisedSetState({
                                        selected_audiences: this.state.selected_audiences
                                    })
                                }
                            }

                        }
                    }
                    if (Array.isArray(this.state.adgroup.targeting.devices)) {
                        if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" }).length > 0) {
                            if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_type) {
                                this.state.operating_systems = this.state.operating_systems.map((item) => {
                                    if (item.value !== this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_type) {
                                        item.included = false;
                                    } else {
                                        item.included = true;
                                    }
                                    return item;
                                })
                                await this.promisedSetState({
                                    operating_systems: this.state.operating_systems
                                })
                                await this.functions.listOsVersions(this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_type);
                                if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_version_max) {
                                    await this.promisedSetState({
                                        max_os_version: this.state.all_os_versions.filter((item) => { return item.name === this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_version_max })[0]
                                    })

                                }
                                if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_version_min) {
                                    await this.promisedSetState({
                                        min_os_version: this.state.all_os_versions.filter((item) => { return item.name === this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].os_version_min })[0]
                                    })

                                }

                            }
                            if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].connection_type) {
                                this.state.connection_types = this.state.connection_types.map((item) => {
                                    if (item.value !== this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].connection_type) {
                                        item.included = false;
                                    } else {
                                        item.included = true;
                                    }
                                    return item;
                                })
                                await this.promisedSetState({
                                    connection_types: this.state.connection_types
                                })
                            }

                            if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].carrier_id) {
                                this.state.selected_carriers = this.state.all_carriers.filter((item) => { return this.state.adgroup.targeting.devices.filter((inner_item) => { return inner_item.operation === "INCLUDE" })[0].carrier_id.includes(item.id) }).map((item) => { item.included = true; return item });
                                await this.promisedSetState({
                                    selected_carriers: this.state.selected_carriers
                                })
                            }
                            if (this.state.adgroup.targeting.devices.filter((item) => { return item.operation === "INCLUDE" })[0].marketing_name) {
                                this.state.selected_devices = this.state.all_devices.filter((item) => { return this.state.adgroup.targeting.devices.filter((inner_item) => { return inner_item.operation === "INCLUDE" })[0].marketing_name.includes(item.id) }).map((item) => { item.included = true; return item });
                                await this.promisedSetState({
                                    selected_devices: this.state.selected_devices
                                })
                            }



                        }

                    }
                    if (this.state.interests_lifestyles.length < 1) {
                        await this.functions.listInterests();
                    }
                    if (Array.isArray(this.state.adgroup.targeting.interests)) {
                        for (let i = 0; i < this.state.adgroup.targeting.interests.length; i++) {
                            if (this.state.adgroup.targeting.interests[i].operation === "INCLUDE") {
                                if (Array.isArray(this.state.adgroup.targeting.interests[i].category_id)) {
                                    this.state.adgroup.targeting.interests[i].category_id.map((item) => {
                                        if (this.state.interests_lifestyles.filter((inner_item) => { return inner_item.id === item }).length > 0) {
                                            this.state.selected_lifestyles.push({ ...this.state.interests_lifestyles.filter((inner_item) => { return inner_item.id === item })[0], ...{ included: true } })
                                        }
                                    })
                                }

                            }
                        }
                        await this.promisedSetState({
                            selected_lifestyles: this.state.selected_lifestyles
                        })
                    }

                }
                resolve();
            })
        },
        searchGoogleLocations: async () => {
            try {
                let self = this;
                if (self.state.map_search !== "") {
                    await self.promisedSetState({ loading_locations: true });
                    self.autocomplete.getPlacePredictions({
                        input: this.state.map_search
                    }, function (predictions, status) {
                        if (status === 'OK' && predictions && predictions.length > 0) {
                            self.promisedSetState({
                                loading_locations: false,
                                google_maps_options: predictions.map((item) => {
                                    item.name = item.description;
                                    item.custom = true;
                                    return item;
                                })
                            });
                        } else {
                            self.promisedSetState({ loading_locations: true, google_maps_options: [] });
                        }
                    });
                } else {
                    self.promisedSetState({ loading_locations: true, google_maps_options: [] });
                }
            } catch (error) {
                console.log(error)
            }
        },
        map: () => {
            if (!this.googleMapsPromise && !window.google) {
                this.googleMapsPromise = new Promise((resolve) => {
                    window.resolveGoogleMapsPromise = () => {
                        resolve(window.google);
                        delete window.resolveGoogleMapsPromise;
                    };
                    const script = document.createElement("script");
                    const API = "AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow";
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                    script.async = true;
                    document.body.appendChild(script);
                });
            } else {
                this.googleMapsPromise = new Promise((resolve) => {
                    resolve(window.google);
                });
            }
            return this.googleMapsPromise;
        },
        locationsOptions: () => {
            try {
                if (Array.isArray(this.state.locations.options) && this.state.locations.options.length > 0) {
                    // IF MAP VIEW IS SELECTED
                    if (this.state.map.value) {
                        if (this.state.google_maps_options.length > 0) {
                            return this.state.google_maps_options
                        } else {
                            return []
                        }
                        // IF LIST VIEW IS SELECTED
                    } else if (!this.state.map.value) {
                        let has_custom = false;
                        if (this.renders.locations().some((inner_item) => inner_item.custom)) {
                            has_custom = true;
                        }
                        if (this.state.locations.search !== "") {
                            return Array.isArray(this.state.locations.options) && this.state.locations.options.length > 0 ?
                                this.state.locations.options.filter((item) => {
                                    let not_selected = this.renders.locations().filter((inner_item) => {
                                        return item.id === inner_item.id
                                    }).length < 1;
                                    let match = item.name.toLowerCase().includes(this.state.locations.search.toLowerCase());
                                    if (has_custom) {
                                        return not_selected && match && !item.metro && !item.regions && !item.parent_id && !item.external_id;
                                    } else {
                                        return not_selected && match;
                                    }
                                }) : []
                        } else {
                            return []
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    };

    calls = {
        updateLiveAdgroup: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateLiveAdgroup?client=" + this.state.client.id + "&adgroup=" + this.state.adgroup.id + "&channel=snapchat";
            return apiRegister.call(options, url);
        },
        getSnapchatPixel: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/getSnapchatPixel?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listInterests: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listInterests?client=" + this.state.client.id + "&country_code=" + data;
            return apiRegister.call(options, url);
        },
        listDevices: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listDevices?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listCarriers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listCarriers?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listOsVersions: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/snapchat/listOsVersions?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listInterestsVisitors: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listInterestsVisitors?client=" + this.state.client.id + "&country_code=" + data;
            return apiRegister.call(options, url);
        },
        listCustomAudiences: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listCustomAudiences?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else {
                    return this.state.adgroup.name;
                }
            } catch (error) {
                return "";
            }
        },
        snapPixel: () => {
            try {
                if ('snap_pixel' in this.state.updated) {
                    return this.state.updated.snap_pixel;
                } else if (this.state.adgroup.pixel_id) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        optimizationGoal: () => {
            return this.state.optimization_goal;
        },
        pixel_id: () => {
            try {
                if ('pixel_id' in this.state.updated) {
                    return this.state.updated.pixel_id;
                } else if (this.state.adgroup.pixel_id) {
                    return this.state.snapchat_pixels.filter((item) => { return item.pixel_id === this.state.adgroup.pixel_id })[0];
                } else {
                    return { id: 0, name: "Select...", value: "" };
                }
            } catch (error) {
                return { id: 0, name: "Select...", value: "" };
            }
        },
        budgetMode: () => {
            try {
                if (this.state.adgroup.delivery_constraint === "DAILY_BUDGET") {
                    return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAILY" };
                } else if (this.state.adgroup.delivery_constraint === "LIFETIME_BUDGET") {
                    return { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_LIFETIME" };
                }
            } catch (error) {
                return { id: 0, name: "...", value: "" };
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget / 1000000;
                } else if (this.state.adgroup.daily_budget_micro) {
                    return this.state.adgroup.daily_budget_micro / 1000000;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if ('gender' in this.state.updated) {
                    return this.state.updated.gender;
                } else {
                    return this.state.genders.filter((item) => { return item.included })[0];
                }
            } catch (error) {
                console.log("GENDER ERROR")
            }
        },
        bidStrategy: () => {
            try {
                if ('bid_strategy' in this.state.updated) {
                    return this.state.updated.bid_strategy;
                } else {
                    return this.state.bid_strategy;
                }
            } catch (error) {
                return "";
            }
        },
        pacing: () => {
            try {
                if ('pacing' in this.state.updated) {
                    return this.state.updated.pacing;
                } else {
                    return this.state.pacing;
                }
            } catch (error) {
                return "";
            }
        },
        bidCap: () => {
            try {
                if ('bid_cap' in this.state.updated) {
                    return this.state.updated.bid_cap / 1000000;
                } else {
                    return this.state.bid_cap / 1000000;
                }
            } catch (error) {
                return "";
            }
        },
        lifetimeBudget: () => {
            try {
                if ('lifetime_budget' in this.state.updated) {
                    return this.state.updated.lifetime_budget / 1000000;
                } else if (this.state.adgroup.lifetime_budget_micro) {
                    return this.state.adgroup.lifetime_budget_micro / 1000000;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        locations: () => {
            try {
                if ('locations' in this.state.updated) {
                    return this.state.updated.locations;
                } else if (this.state.adgroup.locations) {
                    return this.state.adgroup.locations;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        selected_audiences: () => {
            try {
                if ('selected_audiences' in this.state.updated) {
                    return this.state.updated.selected_audiences;
                } else if (this.state.selected_audiences) {
                    return this.state.selected_audiences;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        selected_carriers: () => {
            try {
                if ('selected_carriers' in this.state.updated) {
                    return this.state.updated.selected_carriers;
                } else if (this.state.selected_carriers) {
                    return this.state.selected_carriers;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        selected_devices: () => {
            try {
                if ('selected_devices' in this.state.updated) {
                    return this.state.updated.selected_devices;
                } else if (this.state.selected_devices) {
                    return this.state.selected_devices;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        selected_lifestyles: () => {
            try {
                if ('selected_lifestyles' in this.state.updated) {
                    return this.state.updated.selected_lifestyles;
                } else if (this.state.selected_lifestyles) {
                    return this.state.selected_lifestyles;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        selected_content_placements: () => {
            try {
                if ('selected_content_placements' in this.state.updated) {
                    return this.state.updated.selected_content_placements;
                } else if (this.state.selected_content_placements) {
                    return this.state.selected_content_placements;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        minOsVersion: () => {
            try {
                if ('min_os_version' in this.state.updated) {
                    return this.state.updated.min_os_version;
                } else {
                    return this.state.min_os_version;
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: false };
            }
        },
        maxOsVersion: () => {
            try {
                if ('max_os_version' in this.state.updated) {
                    return this.state.updated.max_os_version;
                } else {
                    return this.state.max_os_version;
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: false };
            }
        },
        autoExpand: () => {
            try {
                if ('auto_expand' in this.state.updated) {
                    return this.state.updated.auto_expand;
                } else if (this.state.auto_expand) {
                    return this.state.auto_expand;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        autoCustom: () => {
            try {
                if ('auto_custom' in this.state.updated) {
                    return this.state.updated.auto_custom;
                } else if (this.state.auto_custom) {
                    return this.state.auto_custom;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        moat: () => {
            try {
                if ('moat' in this.state.updated) {
                    return this.state.updated.moat;
                } else if (this.state.moat) {
                    return this.state.moat;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        doubleVerify: () => {
            try {
                if ('double_verify' in this.state.updated) {
                    return this.state.updated.double_verify;
                } else if (this.state.double_verify) {
                    return this.state.double_verify;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        customAudience: () => {
            try {
                if ('custom_audience' in this.state.updated) {
                    return this.state.updated.custom_audience;
                } else if (this.state.custom_audience) {
                    return this.state.custom_audience;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        autoInterest: () => {
            try {
                if ('auto_interest' in this.state.updated) {
                    return this.state.updated.auto_interest;
                } else if (this.state.auto_interest) {
                    return this.state.auto_interest;
                }
            } catch (error) {
                return { id: 0, name: "Error handle", value: false };
            }
        },
        languages: () => {
            try {
                if ('languages' in this.state.updated) {
                    return this.state.updated.languages;
                } else if (this.state.selected_languages) {
                    return this.state.selected_languages;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else {
                    return this.state.adgroup.start ? moment(this.state.adgroup.start).format("YYYY-MM-DD HH:mm") : null;
                }
            } catch (error) {
                return null;
            }
        },
        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? this.state.updated.end_date : null;
                } else {
                    return this.state.adgroup.end ? moment(this.state.adgroup.end).format("YYYY-MM-DD HH:mm") : null;
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        regulatedContent: () => {
            try {
                if ('regulated_content' in this.state.updated) {
                    return this.state.updated.regulated_content;
                } else {
                    return this.state.regulated_content;
                }
            } catch (error) {
                return null;
            }
        },
        minAge: () => {
            try {
                if ('min_age' in this.state.updated) {
                    return this.state.updated.min_age;
                } else {
                    return this.state.minAge;
                }
            } catch (error) {
                return null;
            }
        },
        maxAge: () => {
            try {
                if ('max_age' in this.state.updated) {
                    return this.state.updated.max_age;
                } else {
                    return this.state.maxAge;
                }
            } catch (error) {
                return null;
            }
        },
        status: () => {
            try {
                if ('status' in this.state.updated) {
                    return this.state.updated.status;
                } else if (this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() }).length > 0) {
                    return this.state.status_values.filter((item) => { return item.value.toLowerCase() === this.state.adgroup.status.toLowerCase() })[0];
                } else {
                    return { id: 0, name: 'Click here ...', value: "PAUSED" }
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        autoPlacements: () => {
            try {
                if ('auto_placements' in this.state.updated) {
                    return this.state.updated.auto_placements;
                } else {
                    return this.state.auto_placements;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        customPlacements: () => {
            try {
                if ('custom_placements' in this.state.updated) {
                    return this.state.updated.custom_placements;
                } else {
                    return this.state.custom_placements;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        spotlight: () => {
            try {
                if ('placement_spotlight' in this.state.updated) {
                    return this.state.updated.placement_spotlight;
                } else {
                    return this.state.placement_spotlight;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        feed: () => {
            try {
                if ('placement_feed' in this.state.updated) {
                    return this.state.updated.placement_feed;
                } else {
                    return this.state.placement_feed;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        between_contetnt: () => {
            try {
                if ('between_contetnt' in this.state.updated) {
                    return this.state.updated.between_contetnt;
                } else {
                    return this.state.between_contetnt;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        stories: () => {
            try {
                if ('placement_story' in this.state.updated) {
                    return this.state.updated.placement_story;
                } else {
                    return this.state.placement_story;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        content: () => {
            try {
                if ('placement_content' in this.state.updated) {
                    return this.state.updated.placement_content;
                } else {
                    return this.state.placement_content;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        categories: () => {
            try {
                if ('placement_categories' in this.state.updated) {
                    return this.state.updated.placement_categories;
                } else {
                    return this.state.placement_categories;
                }
            } catch (error) {
                return { id: 0, name: 'Click here ...', value: "PAUSED" }
            }
        },
        snapchatPixel: () => {
            try {
                if ("snapchat_pixel" in this.state.updated) {
                    return this.state.updated.snapchat_pixel;
                } else if (this.state.pixel_id) {
                    return this.state.pixel_id;
                } else {
                    return { id: 0, name: "Select...", value: "" };
                }
            } catch (error) {
                return { id: 0, name: "Select...", value: "" };
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        let selected_targeting = (type, add = false, small = false) => {
            return (
                <>
                    {
                        small && type === "locations" &&
                        <>
                            {
                                this.renders[type]().sort(function (x, y) {
                                    return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                                }).map((item, index) => {
                                    let included_amount = this.renders[type]().filter((item) => { return item.included }).length;
                                    let excluded_amount = this.renders[type]().filter((item) => { return !item.included }).length;
                                    return (
                                        <Fragment>
                                            {
                                                index == 0 && included_amount > 0 &&
                                                <div className="text-xs font-medium text-gray-700 mb-1">
                                                    Included {add ? add : ""}
                                                </div>
                                            }
                                            {
                                                (included_amount == index) && excluded_amount > 0 &&
                                                <div className="text-xs font-medium text-gray-700 mb-1">
                                                    Excluded
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                })}
                        </>
                    }
                    <div className={(small ? "-mt-4 flex-row flex-wrap" : "flex-col") + " col-span-6 flex"}>
                        {
                            this.renders[type]().sort(function (x, y) {
                                return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                            }).map((item, index) => {
                                let included_amount = this.renders[type]().filter((item) => { return item.included }).length;
                                let excluded_amount = this.renders[type]().filter((item) => { return !item.included }).length;
                                return (
                                    <Fragment>
                                        {
                                            index == 0 && included_amount > 0 && !small &&
                                            <div className="text-xs font-medium text-gray-700 mb-1">
                                                Included {add ? add : ""}
                                            </div>
                                        }
                                        {
                                            (included_amount == index) && excluded_amount > 0 && !small &&
                                            <div className="text-xs font-medium text-gray-700 mb-1">
                                                Excluded
                                            </div>
                                        }
                                        <div className={(index !== 0 && !small ? "mt-2 " : "") + (item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + (small ? " m-1" : " flex-1") + (type === "locations" ? ((this.state.map.value === false && !item.custom) || (this.state.map.value === true && item.custom) ? " opacity-100 cursor-pointer" : " opacity-50 cursor-default") : "") + " flex rounded-full items-center py-1.5 px-3 text-sm font-medium"}
                                            style={{ width: "fit-content" }}
                                        >
                                            <div className="flex flex-1"
                                                onClick={async () => {
                                                    if (this.state.map.value && item.custom) {
                                                        item.lat = item.lat ? item.lat : item.latitude;
                                                        item.lng = item.lng ? item.lng : item.longitude;
                                                        await this.promisedSetState({
                                                            place: item,
                                                            updateMap: true
                                                        });
                                                        await this.promisedSetState({
                                                            updateMap: false
                                                        });
                                                    }
                                                }}
                                            >
                                                {type === "locations" &&
                                                    <span className="h-5 w-5 mr-1"
                                                        onClick={async () => {
                                                            if (this.state.map.value && item.custom) {
                                                                item.lat = item.lat ? item.lat : item.latitude;
                                                                item.lng = item.lng ? item.lng : item.longitude;
                                                                await this.promisedSetState({
                                                                    place: item,
                                                                    updateMap: true
                                                                });
                                                                await this.promisedSetState({
                                                                    updateMap: false
                                                                });
                                                            }
                                                        }}>
                                                        <LocationMarkerIcon />
                                                    </span>
                                                }
                                                {item.name}
                                            </div>
                                            {type === "locations" && item.custom &&
                                                <span className="h-5 w-5 ml-2 cursor-pointer"
                                                    onClick={async () => {
                                                        if (this.state.map.value && item.custom) {
                                                            item.lat = item.lat ? item.lat : item.latitude;
                                                            item.lng = item.lng ? item.lng : item.longitude;
                                                            await this.promisedSetState({
                                                                place: item,
                                                                updateMap: true
                                                            });
                                                            await this.promisedSetState({
                                                                updateMap: false
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <CursorClickIcon />
                                                </span>
                                            }
                                            <button
                                                onClick={async () => {
                                                    this.state.updated[type] = JSON.parse(JSON.stringify(this.renders[type]())).filter((filtered) => {
                                                        return filtered.id !== item.id;
                                                    })
                                                    if (type === "locations" && this.renders[type].length === 0) {
                                                        this.state.updated.selected_lifestyles = [];
                                                        this.state.updated.selected_visitors = [];
                                                        await this.promisedSetState({
                                                            interests_lifestyles: [],
                                                            interests_visitors: [],
                                                            place: this.state.place_default
                                                        })
                                                    } else if (type === "locations") {
                                                        this.functions.listInterests();
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                type="button"
                                                className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                            >
                                                <span className="sr-only">Remove</span>
                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                </svg>
                                            </button>
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                    </div >
                </>
            )
        }



        return (
            <>
                <div className="grid grid-cols-6 gap-4">

                    <div className="col-span-6">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.map((tab) => (
                                    <div
                                        onClick={() => (
                                            this.setState({
                                                tab: tab
                                            })
                                        )}
                                        key={tab.name}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={cn(
                                            tab.id == this.state.tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                            'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                        )}
                                        aria-current={tab.id == this.state.tab.id ? 'page' : undefined}
                                    >
                                        <div>
                                            {
                                                (
                                                    (tab.id === 2 && (adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true, (moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)).start_date ||
                                                        adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true).end_date))

                                                ) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                            }
                                        </div>
                                        {tab.name}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {/*Basic*/}
                    {
                        this.state.tab.id === 1 &&
                        <div className="col-span-6 grid grid-cols-6 gap-2">
                            <div className="col-span-3">
                                <InputTailwind
                                    label={"Adgroup name"}
                                    value={this.renders.name()}
                                    onChange={(value) => {
                                        this.state.updated.name = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Status</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.status().name}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.status().value === "ACTIVE" ? true : false}
                                            disabled={this.state.locked}
                                            onSwitch={async () => {
                                                if (!this.state.locked) {
                                                    if (this.renders.status().value === "ACTIVE") {
                                                        this.functions.update({ id: 2, name: 'Paused', value: "PAUSED" });
                                                    } else if (this.renders.status().value === "PAUSED") {
                                                        this.functions.update({ id: 1, name: 'Active', value: "ACTIVE" });
                                                    }
                                                }
                                            }}
                                        />
                                        {
                                            this.state.loading_status &&
                                            <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-2">
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Snap Pixel</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.renders.snapPixel() ? "Attached" : "Not attached"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.renders.snapPixel()}
                                            disabled={this.state.adgroup.pixel_id ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.snap_pixel = !this.renders.snapPixel();
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-4">
                                <DropdownTailwind
                                    label={"Snap Pixel"}
                                    selected={this.renders.pixel_id() ? this.renders.pixel_id() : { id: 0, name: "Select...", value: "" }}
                                    options={this.state.snapchat_pixels}
                                    locked={this.renders.snapPixel() ? false : true}
                                    disabled={this.renders.snapPixel() ? false : true}
                                    onChange={(value) => {
                                        this.state.updated.pixel_id = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                        </div>


                    }


                    {/*BUDGET & SCHEDULING */}
                    {
                        this.state.tab.id === 2 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 col-gap-2">
                                <div className="col-span-2">
                                    <DropdownTailwind
                                        label={"Mode"}
                                        selected={this.renders.budgetMode()}
                                        options={[]}
                                        locked={true}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <InputTailwind
                                        label={"Budget"}
                                        //error={adgroupSnapchat.validate(this.state.updated).budget}
                                        leftSection={(this.state.currency !== "" ? true : false)}
                                        leftSectionLabel={(this.state.currency)}
                                        value={this.renders.budgetMode().id === 1 ? this.renders.dailyBudget() : this.renders.lifetimeBudget()}
                                        type={'number'}
                                        onChange={async (value) => {
                                            if (this.renders.budgetMode().id === 1) {
                                                this.state.updated.daily_budget = value * 1000000;
                                            } else {
                                                this.state.updated.lifetime_budget = value * 1000000;
                                            }
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-span-6 grid grid-cols-6 col-gap-2 gap-4">
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-span-6 gap-2">
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"Start date"}
                                                placeholder={(moment().unix() > moment(this.state.adgroup.start).unix() ? moment(this.renders.startDate()).format("YYYY-MM-DD") : "")}
                                                error={adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true, (moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)).start_date}
                                                disabled={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                locked={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                                onChange={(value) => {
                                                    let time = moment(this.renders.startDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.start_date = moment(new_date);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                label={"Start time"}
                                                placeholder={(moment().unix() > moment(this.state.adgroup.start).unix() ? moment(this.renders.startDate()).format("HH:mm") : "")}
                                                disabled={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                locked={(moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)}
                                                // error={campaignSnapchat.validate(this.state.updated).start_date}
                                                value={moment(this.renders.startDate()).format("HH:mm")}
                                                onChange={(value) => {
                                                    let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    this.state.updated.start_date = moment(new_date);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 col-span-6 gap-2">
                                        <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                disabled={!this.renders.endDate()}
                                                labelRight={this.renders.endDate() ? "Disable" : "Enable"}
                                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                                error={adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true).end_date}
                                                value={this.renders.endDate() ? moment(this.renders.endDate()).format("YYYY-MM-DD") : ""}
                                                onChange={(value) => {
                                                    let time = moment(this.renders.endDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.end_date = moment(new_date);
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.renders.endDate()) {
                                                        this.state.updated.end_date = null;
                                                    } else {
                                                        let new_date = moment(this.renders.startDate()).add(1, 'days');
                                                        this.state.updated.end_date = moment(new_date);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.endDate() &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    //error={campaignSnapchat.validate(this.state.updated, this.state.campaign, true).start_date}
                                                    value={moment(this.renders.endDate()).format("HH:mm")}
                                                    onChange={(value) => {
                                                        let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.updated.end_date = moment(new_date);
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    (adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true, (moment().unix() > moment(this.state.adgroup.start).unix() ? true : false)).start_date ||
                                        adgroupSnapchat.validate({ start: this.renders.startDate(), end: this.renders.endDate() }, this.state.campaigns, true).end_date) &&
                                    <div className='col-span-6'>
                                        <div className="bg-orange-100 rounded-md col-span-3 p-4 text-sm font-medium text-orange-500">
                                            OBS! Adgroup date range is outside of the Campaign date range.
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {/*PLACEMENTS*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 col-gap-2 gap-4">
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Automatic placements (Recommended)</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.autoPlacements().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.autoPlacements().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.autoPlacements().value) {
                                                        this.state.updated.auto_placements = { id: 2, name: "No", value: false };
                                                        this.state.updated.custom_placements = { id: 1, name: "Yes", value: true };
                                                    } else {
                                                        this.state.updated.auto_placements = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.custom_placements = { id: 2, name: "No", value: false };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Custom placements</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.customPlacements().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.customPlacements().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.customPlacements().value) {
                                                        this.state.updated.custom_placements = { id: 2, name: "No", value: false };
                                                        this.state.updated.auto_placements = { id: 1, name: "Yes", value: true };
                                                    } else {
                                                        this.state.updated.custom_placements = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.auto_placements = { id: 2, name: "No", value: false };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.renders.customPlacements().value &&
                                    <div className='col-span-6 grid grid-cols-8 gap-4'>
                                        <div className="col-span-8 text-lg flex-1 flex leading-6 font-semibold text-gray-900">
                                            Edit placement
                                        </div>
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Between Content</div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.between_contetnt().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.between_contetnt().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.between_contetnt().value) {
                                                                this.state.updated.between_contetnt = { id: 2, name: "No", value: false };
                                                                this.state.updated.placement_story = { id: 2, name: "No", value: false };
                                                                this.state.updated.placement_content = { id: 2, name: "No", value: false };
                                                                this.state.updated.placement_spotlight = { id: 2, name: "No", value: false };

                                                            } else {
                                                                this.state.updated.between_contetnt = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.placement_story = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.placement_content = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.placement_spotlight = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>User Stories</div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.stories().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.stories().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.stories().value) {
                                                                this.state.updated.placement_story = { id: 2, name: "No", value: false };
                                                                if (!this.renders.content().value && !this.renders.spotlight().value) {
                                                                    this.state.updated.between_contetnt = { id: 2, name: "No", value: false };
                                                                }
                                                            } else {
                                                                this.state.updated.placement_story = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.between_contetnt = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Publisher & Creator Stories</div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.content().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.content().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.content().value) {
                                                                this.state.updated.placement_content = { id: 2, name: "No", value: false };
                                                                if (!this.renders.stories().value && !this.renders.spotlight().value) {
                                                                    this.state.updated.between_contetnt = { id: 2, name: "No", value: false };
                                                                }
                                                            } else {
                                                                this.state.updated.placement_content = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.between_contetnt = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Spotlight</div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.spotlight().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.spotlight().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.spotlight().value) {
                                                                this.state.updated.placement_spotlight = { id: 2, name: "No", value: false };
                                                                if (!this.renders.content().value && !this.renders.stories().value) {
                                                                    this.state.updated.between_contetnt = { id: 2, name: "No", value: false };
                                                                }
                                                            } else {
                                                                this.state.updated.placement_spotlight = { id: 1, name: "Yes", value: true };
                                                                this.state.updated.between_contetnt = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Within Content </div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.categories().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.categories().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.categories().value) {
                                                                this.state.updated.placement_categories = { id: 2, name: "No", value: false };
                                                                this.state.updated.selected_content_placements = [];
                                                            } else {
                                                                this.state.updated.placement_categories = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6">
                                            <DropdownTailwind
                                                //error={adgroupSnapchat.validate(this.state.updated).location}
                                                ref="searchDropdown"
                                                label={"Categories"}
                                                locked={this.renders.categories().value ? false : true}
                                                searchInput={true}
                                                search={this.state.search_category}
                                                selected={{ name: "Search ..." }}
                                                options={(this.renders.selected_content_placements().length > 0 ? this.state.content_placements_options.filter((item) => { return this.renders.selected_content_placements().filter((inner_item) => { return item.id === inner_item.id }).length < 1 }) : this.state.content_placements_options)}
                                                loadingSearch={this.state.locations.loading}
                                                rightLabel={true}
                                                rightLabelText={this.state.category_type.value ? "Included" : "Excluded"}
                                                onRightLabelClick={async () => {
                                                    await this.promisedSetState({ category_type: !this.state.category_type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                                }}
                                                onChange={async (value) => {
                                                    value.included = this.state.category_type.value;
                                                    if (!Array.isArray(this.state.updated.selected_content_placements)) {
                                                        this.state.updated.selected_content_placements = JSON.parse(JSON.stringify(this.renders.selected_content_placements()));
                                                    }
                                                    this.state.updated.selected_content_placements.unshift(value);
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                                onSearch={async (value) => {
                                                    this.state.search_category = value;
                                                    await this.promisedSetState({
                                                        search_category: this.state.search_category
                                                    });
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.selected_content_placements().length > 0 &&
                                            <div className="col-span-8 mt-3 grid grid-col-6 gap-4">
                                                {selected_targeting("selected_content_placements", false, true)}
                                            </div>
                                        }
                                        <div className="col-span-2">
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Discover Feed</div>
                                            <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5">
                                                    {this.renders.feed().value ? "Yes" : "No"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.renders.feed().value ? true : false}
                                                        onSwitch={async (value) => {
                                                            if (this.renders.feed().value) {
                                                                this.state.updated.placement_feed = { id: 2, name: "No", value: false };
                                                            } else {
                                                                this.state.updated.placement_feed = { id: 1, name: "Yes", value: true };
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {/*LOCATIONS*/}
                    {
                        this.state.tab.id === 4 &&
                        <>
                            <div className="col-span-2">
                                <DropdownTailwind
                                    label={"View type"}
                                    loader={this.state.loading_map}
                                    selected={this.state.map ? this.state.map : { id: 1, name: "List View", value: false }}
                                    options={[
                                        { id: 1, name: "List View", value: false },
                                        { id: 2, name: "Map view", value: true }
                                    ]}
                                    onChange={async (value) => {
                                        let buff = null;
                                        this.refs.searchDropdown.functions.resetSearch();
                                        // if (Object.keys(this.state.adgroup).length > 0 && Array.isArray(this.state.adgroup.locations) && this.state.adgroup.locations.length > 0) {
                                        if (this.renders.locations().length > 0) {
                                            buff = this.renders.locations().find((item) => item.custom);
                                            if (buff === undefined || buff === null) {
                                                this.state.place = this.state.place_default;
                                            } else {
                                                if (!buff.lat) {
                                                    buff.lat = buff.latitude;
                                                }
                                                if (!buff.lng) {
                                                    buff.lng = buff.longitude
                                                }
                                                this.state.place = buff;
                                            }
                                        }
                                        this.state.locations.search = "";
                                        await this.promisedSetState({
                                            map: value,
                                            google_maps_options: [],
                                            map_search: "",
                                            place: this.state.place,
                                            locations: this.state.locations
                                        })
                                        // await this.promisedSetState({
                                        //     location_type: this.state.map.value ? { id: 1, name: "Included", value: true } : this.state.location_type
                                        // });
                                    }}
                                />
                            </div>
                            <div className="col-span-4">
                                <DropdownTailwind
                                    //error={adgroupSnapchat.validate(this.state.updated).location}
                                    ref="searchDropdown"
                                    rightLabel={true}
                                    // FOR EXCLUDE
                                    // rightLabelText={this.state.locations.type.value ? "Included" : "Excluded"}
                                    // onRightLabelClick={async () => {
                                    //     this.state.locations.type = !this.state.locations.type.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false };
                                    //     await this.promisedSetState({ locations: this.state.locations })

                                    // }}
                                    label={"Locations"}
                                    loader={this.state.loading_map}
                                    searchInput={true}
                                    search={this.state.locations.search}
                                    selected={{ name: "Search ..." }}
                                    // options={this.state.map.value ?
                                    //     this.state.google_maps_options :
                                    //     (Array.isArray(this.state.locations.options) && this.state.locations.search !== "") ?
                                    //         this.state.locations.options.filter((item) => {
                                    //             if (this.renders.locations().length > 0) {
                                    //                 return this.renders.locations().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                    //             } else {
                                    //                 return true;
                                    //             }
                                    //         }) : []}
                                    options={this.functions.locationsOptions()}
                                    loadingSearch={this.state.locations.loading}
                                    onChange={async (value) => {
                                        let location = null;
                                        if (!this.state.map.value) {
                                            value.included = this.state.locations.type.value ? true : false;
                                            if (value.parent_id) {
                                                value.parent_code = snapchatLocations.filter((item) => { return item.id === value.parent_id })[0].code
                                            }
                                            if (!Array.isArray(this.state.updated.locations)) {
                                                this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations()));
                                            }
                                            this.state.updated.locations.unshift(value);
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                            await this.functions.listInterests()

                                        } else {
                                            let self = this;
                                            this.geocoder.geocode({ 'placeId': value.place_id }, async function (results, status) {
                                                if (status === 'OK' && results && results.length > 0) {
                                                    let location_name = "";
                                                    let code = "";
                                                    location = {
                                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                                        custom: true,
                                                        included: self.state.locations.type.value,
                                                        include: self.state.locations.type.value,
                                                        campaign_edit: self.state.locations.type.value,
                                                        new_location: true,
                                                        lat: parseFloat(results[0].geometry.location.lat()),
                                                        lng: parseFloat(results[0].geometry.location.lng()),
                                                        radius: 30000,
                                                        geo_point: {
                                                            lat: parseFloat(results[0].geometry.location.lat()),
                                                            lng: parseFloat(results[0].geometry.location.lng())
                                                        },
                                                        unit: "KILOMETERS"
                                                    };
                                                    if (results[0].address_components) {
                                                        results[0].address_components.find((item) => {
                                                            if (item.types.includes("country")) {
                                                                code = item.short_name.toLowerCase();
                                                            }
                                                        })
                                                    }
                                                    if (results[0].formatted_address) {
                                                        location_name = results[0].formatted_address;
                                                        if (code !== "") {
                                                            location_name = "(" + code.toUpperCase() + ") " + location_name;
                                                        }
                                                        location_name = location_name + ", (" + results[0].geometry.location.lat().toFixed(3) + " lat, " + results[0].geometry.location.lng().toFixed(3) + " lng, " + location.radius / 1000 + "km)";
                                                    } else {
                                                        location_name = "(" + results[0].geometry.location.lat().toFixed(3) + " lat, " + results[0].geometry.location.lng().toFixed(3) + " lng)";
                                                    }
                                                    location.name = location_name;
                                                    location.code = code;

                                                    if (!Array.isArray(self.state.updated.locations)) {
                                                        self.state.updated.locations = JSON.parse(JSON.stringify(self.renders.locations()));
                                                    }
                                                    if (self.renders.locations().filter((item) => { return item.lat === location.lat && item.lng === location.lng }).length === 0) {
                                                        self.state.updated.locations.push(location);
                                                    }

                                                    await self.promisedSetState({
                                                        place: location,
                                                        updated: self.state.updated,
                                                        updateMap: true
                                                    });
                                                    await self.promisedSetState({
                                                        updateMap: false
                                                    });
                                                }
                                            });

                                        }
                                    }}
                                    onSearch={async (value) => {
                                        this.state.locations.search = value;
                                        if (this.state.map.value) {
                                            await this.promisedSetState({
                                                map_search: value
                                            })
                                            this.functions.searchGoogleLocations()
                                        } else {
                                            await this.promisedSetState({
                                                locations: this.state.locations
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {
                                this.renders.locations().length > 0 && this.renders.locations().filter((item) => { return item.custom }).length > 0 &&
                                <div className="col-span-6">
                                    <div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Custom locations can only be combined with countries. Selected region/metros will default to country
                                    </div>
                                </div>

                            }
                            {
                                this.renders.locations().length > 0 &&
                                selected_targeting("locations", false, true)
                            }

                            {
                                this.state.place &&
                                this.state.map.value &&
                                <div className="col-span-6">
                                    <AdvertisingLocationMapRegions
                                        ref="googleMapsRef"
                                        update={this.state.updateMap}
                                        center={this.state.center}
                                        zoom={this.state.zoom}
                                        place={this.state.place}
                                        places={this.renders.locations().filter((item) => { return item.custom }).map(item => ({ ...item, lat: item.lat ? item.lat : item.latitude, lng: item.lng ? item.lng : item.longitude }))}
                                        selected={this.renders.locations().filter((item) => { return item.custom }).map(item => ({ ...item, lat: item.lat ? item.lat : item.latitude, lng: item.lng ? item.lng : item.longitude }))}
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMYIrq5pW5TA8K-pVwbYTjDn6R-TBcOow"
                                        loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
                                        containerElement={<div style={{
                                            height: "500px",
                                            width: '100%',
                                            borderRadius: '0px',
                                            overflow: 'hidden',
                                            border: 'none'
                                        }} />}
                                        mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                                        onUpdateLocations={(values) => {

                                        }}
                                        onUpdateSelected={(values, location) => {
                                            this.state.updated.locations = JSON.parse(JSON.stringify(this.renders.locations())).map((item) => {
                                                if (item.id == location.id) {
                                                    item = location;
                                                    item.updated = true;
                                                    item.name = parseFloat(item.lat).toFixed(3) + " lat, " + parseFloat(item.lng).toFixed(3) + " lng, (" + Math.floor(item.radius / 1000) + "km)";
                                                }
                                                return item;
                                            })
                                            this.setState({
                                                place: location,
                                                updated: this.state.updated
                                            })
                                        }}
                                        onUpdate={(value) => {

                                        }}
                                    />
                                </div>
                            }


                        </>
                    }

                    {/*DEMOGRAPHICS*/}
                    {
                        this.state.tab.id === 5 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-2">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Age restriction required</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.regulatedContent().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.regulatedContent().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.regulatedContent().value) {
                                                        this.state.updated.regulated_content = { id: 2, name: "No", value: false };

                                                    } else {
                                                        this.state.updated.regulated_content = { id: 1, name: "Yes", value: true };
                                                        if (this.renders.minAge().id < 18) {
                                                            this.state.updated.min_age = { id: 18, name: "18", value: "18" };
                                                        }

                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-span-2'>
                                    <DropdownTailwind
                                        label={"Minimum age"}
                                        selected={this.renders.minAge()}
                                        options={this.renders.regulatedContent().value ? this.state.all_ages.filter((item) => { return item.id < 36 && item.id > 17 }) : this.state.all_ages.filter((item) => { return item.id < 36 })}
                                        onChange={async (value) => {
                                            this.state.updated.min_age = value;
                                            this.renders.maxAge().id < value.id && (this.state.updated.max_age = value);
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                            //this.functions.estimateAudience();
                                        }}
                                    />
                                </div>
                                <div className='col-span-2'>
                                    <DropdownTailwind
                                        label={"Maximum age"}
                                        selected={this.renders.maxAge()}
                                        options={this.renders.regulatedContent().value ? this.state.all_ages.filter((item) => { return item.id > 17 }) : this.state.all_ages}
                                        onChange={async (value) => {
                                            this.state.updated.max_age = value;
                                            this.renders.minAge().id > value.id && (this.state.updated.min_age = value);
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            })
                                            //this.functions.estimateAudience();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-span-6 -mt-4">
                                <div className="text-xs font-medium text-gray-700 mb-1">
                                    Gender
                                </div>
                                <div className="flex flex-row flex-wrap">
                                    {
                                        this.state.genders.map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    item.included = true;
                                                    this.state.updated.gender = item;
                                                    this.state.genders = this.state.genders.map((inner_item) => {
                                                        if (inner_item.id !== item.id) {
                                                            inner_item.included = false;
                                                        } else {
                                                            inner_item.included = true;
                                                        }
                                                        return inner_item;
                                                    })
                                                    this.setState({
                                                        updated: this.state.updated,
                                                        genders: this.state.genders
                                                    })
                                                    //this.functions.estimateAudience();

                                                }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                    <div className="">{item.name}</div>
                                                    <div className="ml-3">
                                                        <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                            <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-span-6">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Languages"}
                                    searchInput={true}
                                    search={this.state.languages.search}
                                    selected={{ name: "Search ..." }}
                                    options={(Array.isArray(this.state.languages.options) && this.state.languages.search !== "") ? this.state.languages.options.filter((item) => {
                                        return this.renders.languages().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                    }) : []}
                                    loadingSearch={this.state.languages.loading}
                                    onChange={async (language) => {
                                        language.included = true;
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = JSON.parse(JSON.stringify(this.renders.languages()));
                                        }
                                        this.state.updated.languages.unshift(language);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        //this.functions.estimateAudience();
                                    }}
                                    onSearch={async (value) => {
                                        this.state.languages.search = value;
                                        await this.promisedSetState({
                                            languages: this.state.languages
                                        });
                                    }}
                                />
                            </div>
                            {
                                this.renders.languages().length > 0 &&
                                selected_targeting("languages")
                            }
                        </>
                    }
                    {/*AUDIENCES*/}
                    {
                        this.state.tab.id == 6 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-2">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Expand audience automatically</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.autoExpand().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.autoExpand().value ? true : false}
                                                onSwitch={async () => {
                                                    if (this.renders.autoExpand().value) {
                                                        this.state.updated.auto_expand = { id: 2, name: "No", value: false };
                                                        this.state.updated.auto_custom = { id: 2, name: "No", value: false };
                                                        this.state.updated.auto_interest = { id: 2, name: "No", value: false };
                                                    } else {
                                                        this.state.updated.auto_expand = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.auto_custom = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.auto_interest = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Expand Predefined Audiences</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.autoInterest().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.autoInterest().value ? true : false}
                                                onSwitch={async () => {
                                                    if (this.renders.autoInterest().value) {
                                                        this.state.updated.auto_interest = { id: 2, name: "No", value: false };
                                                        if (!this.renders.autoCustom().value) this.state.updated.auto_expand = { id: 2, name: "No", value: false };
                                                    } else {
                                                        this.state.updated.auto_interest = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.auto_expand = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Expand Custom Audiences</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.autoCustom().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.autoCustom().value ? true : false}
                                                onSwitch={async () => {
                                                    if (this.renders.autoCustom().value) {
                                                        this.state.updated.auto_custom = { id: 2, name: "No", value: false };
                                                        if (!this.renders.autoInterest().value) this.state.updated.auto_expand = { id: 2, name: "No", value: false };

                                                    } else {
                                                        this.state.updated.auto_custom = { id: 1, name: "Yes", value: true };
                                                        this.state.updated.auto_expand = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Use custom audience</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.customAudience().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.customAudience().value ? true : false}
                                                onSwitch={async () => {
                                                    if (this.renders.customAudience().value) {
                                                        this.state.updated.custom_audience = { id: 2, name: "No", value: false };
                                                        if (this.renders.selected_audiences().length > 0) this.state.updated.selected_audiences = [];
                                                    } else {
                                                        this.state.updated.custom_audience = { id: 1, name: "Yes", value: true };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <DropdownTailwind
                                        ref="searchDropdown"
                                        label={"Select custom audience"}
                                        searchInput={true}
                                        search={this.state.custom_audiences.search}
                                        locked={this.renders.customAudience().value ? false : true}
                                        rightLabel={true}
                                        rightLabelText={this.state.custom_audience_types.value ? "Included" : "Excluded"}
                                        onRightLabelClick={async () => {
                                            await this.promisedSetState({ custom_audience_types: !this.state.custom_audience_types.value ? { id: 1, name: "Included", value: true } : { id: 2, name: "Excluded", value: false } });
                                        }}
                                        // disabled={Array.isArray(this.state.custom_audiences) && this.state.custom_audiences.length === 0 ? true : false}
                                        selected={{ name: "Search ..." }}
                                        options={this.state.custom_audiences.options.filter((item) => {

                                            return this.renders.selected_audiences().filter((inner_item) => { return item.id === inner_item.id }).length < 1

                                        })}
                                        onChange={async (audience) => {
                                            audience.included = this.state.custom_audience_types.value;
                                            if (!Array.isArray(this.state.updated.selected_audiences)) {
                                                this.state.updated.selected_audiences = JSON.parse(JSON.stringify(this.renders.selected_audiences()));
                                            }
                                            this.state.updated.selected_audiences.unshift(audience);
                                            await this.promisedSetState({
                                                updated: this.state.updated,
                                            })
                                            //this.functions.mergeLifestyles()
                                            //this.functions.estimateAudience();

                                        }}
                                        onSearch={async (value) => {
                                            this.state.custom_audiences.search = value;
                                            await this.promisedSetState({
                                                custom_audiences: this.state.custom_audiences
                                            });
                                        }}
                                    />
                                </div>
                                {
                                    this.renders.selected_audiences().length > 0 &&
                                    selected_targeting("selected_audiences", false, true)

                                }

                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Select audience type"}
                                        selected={this.state.audience_type}
                                        locked={this.renders.locations().length > 0 ? false : true}
                                        disabled={this.renders.locations().length > 0 ? false : true}
                                        options={[
                                            { id: 1, name: "Lifestyles", value: "SLC" },
                                            { id: 2, name: "Visitors", value: "VAC" },
                                        ]}
                                        onChange={(value) => {
                                            this.setState({
                                                audience_type: value,
                                                lifestyle_category: { id: 0, name: "Click here", value: "" },
                                                lifestyle_category_1: { id: 0, name: "Click here", value: "" },
                                            })
                                        }}
                                    />
                                </div>

                                {/* LIFESTYLE MAIN CATEGORIES */}

                                {this.state.audience_type.id !== 0 &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='mx-4 self-center'>
                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={"Select main " + this.state.audience_type.name + " category"}
                                                    searchInput={true}
                                                    //search={this.state.main_categories.search}
                                                    selected={this.state.lifestyle_category}
                                                    options={Array.isArray(this.state.interests_lifestyles) && this.state.interests_lifestyles.length > 0 ? this.state.interests_lifestyles.filter(item => {
                                                        if (this.state.audience_type.id === 1) {
                                                            return item.parent === "SLC_0"
                                                        } else {
                                                            return item.parent === "VAC_0"
                                                        }
                                                    }).filter((item) => {
                                                        return this.renders.selected_lifestyles().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                                    }) : []}
                                                    onChange={async (value) => {
                                                        //this.state.updated.lifestyle_category = value;
                                                        //this.state.updated.audience_main_cat = value;
                                                        if (this.state.interests_lifestyles.filter((item) => { return item.parent === value.id }).length === 0) {
                                                            if (!Array.isArray(this.state.updated.selected_lifestyles)) {
                                                                this.state.updated.selected_lifestyles = JSON.parse(JSON.stringify(this.renders.selected_lifestyles()));
                                                            }
                                                            value.included = true;
                                                            this.state.updated.selected_lifestyles.unshift(value);
                                                            this.state.lifestyle_category = { id: 0, name: "Click here", value: "" };
                                                        } else {
                                                            this.state.lifestyle_category = value;
                                                            this.state.lifestyle_category_1 = { id: 0, name: "Click here", value: "" };
                                                        }
                                                        //this.functions.listInterestsVisitors(value)
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            lifestyle_category: this.state.lifestyle_category,
                                                            lifestyle_category_1: this.state.lifestyle_category_1
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className='flex justify-end ml-3 mt-5 items-center'>
                                                <button onClick={async () => {
                                                    if (this.state.lifestyle_category.id !== 0 && this.renders.selected_lifestyles().filter((item) => { return item.id === this.state.lifestyle_category.id }).length < 1) {
                                                        let value = JSON.parse(JSON.stringify(this.state.lifestyle_category));
                                                        if (!Array.isArray(this.state.updated.selected_lifestyles)) {
                                                            this.state.updated.selected_lifestyles = JSON.parse(JSON.stringify(this.renders.selected_lifestyles()));
                                                        }
                                                        value.included = true;
                                                        this.state.updated.selected_lifestyles.unshift(value);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }} className={(this.state.lifestyle_category.id !== 0 && this.renders.selected_lifestyles().filter((item) => { return item.id === this.state.lifestyle_category.id }).length < 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600" : "bg-gray-500") + " text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-24 py-3 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}>Add all</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* LIFESTYLE SUBCATEGORIES */}
                                {
                                    this.state.lifestyle_category.id !== 0 &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='mx-4 self-center'>
                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={"Select " + this.state.lifestyle_category.name + " subcategory"}
                                                    searchInput={true}
                                                    //search={this.state.main_categories.search}
                                                    selected={this.state.lifestyle_category_1}
                                                    options={Array.isArray(this.state.interests_lifestyles) && this.state.interests_lifestyles.length > 0 ? this.state.interests_lifestyles.filter((item) => {
                                                        return this.renders.selected_lifestyles().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                                    }).filter(item => item.parent === this.state.lifestyle_category.id) : []}
                                                    onChange={async (value) => {
                                                        if (this.state.interests_lifestyles.filter((item) => { return item.parent === value.id }).length === 0) {
                                                            if (!Array.isArray(this.state.updated.selected_lifestyles)) {
                                                                this.state.updated.selected_lifestyles = JSON.parse(JSON.stringify(this.renders.selected_lifestyles()));
                                                            }
                                                            value.included = true;
                                                            this.state.updated.selected_lifestyles.unshift(value);
                                                            this.state.lifestyle_category_1 = { id: 0, name: "Click here", value: "" };
                                                        } else {
                                                            this.state.lifestyle_category_1 = value;
                                                        }
                                                        //this.functions.listInterestsVisitors(value)
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            lifestyle_category_1: this.state.lifestyle_category_1
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className='flex justify-end ml-3 mt-5 items-center'>
                                                <button onClick={async () => {
                                                    if (this.state.lifestyle_category_1.id !== 0 && this.renders.selected_lifestyles().filter((item) => { return item.id === this.state.lifestyle_category_1.id }).length < 1) {
                                                        let value = JSON.parse(JSON.stringify(this.state.lifestyle_category_1));
                                                        if (!Array.isArray(this.state.updated.selected_lifestyles)) {
                                                            this.state.updated.selected_lifestyles = JSON.parse(JSON.stringify(this.renders.selected_lifestyles()));
                                                        }
                                                        value.included = true;
                                                        this.state.updated.selected_lifestyles.unshift(value);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }} className={(this.state.lifestyle_category_1.id !== 0 && this.renders.selected_lifestyles().filter((item) => { return item.id === this.state.lifestyle_category_1.id }).length < 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600" : "bg-gray-500") + " text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-24 py-3 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}>Add all</button>
                                            </div>


                                        </div>
                                    </div>
                                }
                                {/* LIFESTYLE SUBCATEGORIES 2 */}
                                {
                                    this.state.lifestyle_category_1.id !== 0 &&
                                    <div className="col-span-6">
                                        <div className='flex'>
                                            <div className='mx-4 self-center'>
                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <DropdownTailwind
                                                    ref="searchDropdown"
                                                    label={"Select " + this.state.lifestyle_category_1.name + " subcategory"}
                                                    searchInput={true}
                                                    //search={this.state.main_categories.search}
                                                    selected={{ id: 0, name: "Click here", value: "" }}
                                                    options={Array.isArray(this.state.interests_lifestyles) && this.state.interests_lifestyles.length > 0 ? this.state.interests_lifestyles.filter((item) => {
                                                        return this.renders.selected_lifestyles().filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                                    }).filter(item => item.parent === this.state.lifestyle_category_1.id) : []}
                                                    onChange={async (value) => {
                                                        if (this.state.interests_lifestyles.filter((item) => { return item.parent === value.id }).length === 0) {
                                                            if (!Array.isArray(this.state.updated.selected_lifestyles)) {
                                                                this.state.updated.selected_lifestyles = JSON.parse(JSON.stringify(this.renders.selected_lifestyles()));
                                                            }
                                                            value.included = true;
                                                            this.state.updated.selected_lifestyles.unshift(value);
                                                        }
                                                        //this.functions.listInterestsVisitors(value)
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                }

                                {
                                    this.renders.selected_lifestyles().length > 0 &&
                                    <div className='mt-5 col-span-6 grid grid-cols-6 gap-4'>
                                        {selected_targeting("selected_lifestyles", false, true)}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {/*DEVICES*/}
                    {
                        this.state.tab.id == 7 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-6">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Operating systems
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.operating_systems.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        item.included = true;
                                                        this.state.updated.operating_system = item;
                                                        this.state.operating_systems = this.state.operating_systems.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.included = false;
                                                            } else {
                                                                inner_item.included = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            operating_systems: this.state.operating_systems
                                                        })
                                                        //this.functions.estimateAudience();

                                                    }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.operating_systems.filter((item) => { return item.included })[0].value !== "ALL" &&
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"OS version min"}
                                                selected={this.renders.minOsVersion()}
                                                options={this.state.all_os_versions && this.state.all_os_versions.length > 0 ? this.state.all_os_versions : []}
                                                onChange={(value) => {
                                                    this.state.updated.min_os_version = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    //this.functions.estimateAudience();
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <DropdownTailwind
                                                label={"OS version max"}
                                                selected={this.renders.maxOsVersion()}
                                                options={this.state.all_os_versions && this.state.all_os_versions.length > 0 ? this.state.all_os_versions : []}
                                                onChange={(value) => {
                                                    this.state.updated.max_os_version = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    //this.functions.estimateAudience();
                                                }}
                                            />
                                        </div>
                                    </div>
                                }



                                <div className="col-span-6">
                                    <DropdownTailwind
                                        ref="searchDropdown"
                                        label={"Devices"}
                                        searchInput={true}
                                        selected={{ name: "Search ..." }}
                                        options={
                                            (this.state.operating_systems.filter((item) => { return item.included })[0].value === "iOS" ?
                                                this.state.all_devices.filter((device) => { return device.id.includes("Apple/") })
                                                :
                                                (this.state.operating_systems.filter((item) => { return item.included })[0].value === "ANDROID" ?
                                                    this.state.all_devices.filter((device) => { return !device.id.includes("Apple/") })
                                                    :
                                                    [])).filter((device) => {
                                                        return this.renders.selected_devices().filter((inner_item) => { return device.id === inner_item.id }).length < 1
                                                    })
                                        }
                                        onChange={async (carrier) => {
                                            carrier.included = true;
                                            if (!Array.isArray(this.state.updated.selected_devices)) {
                                                this.state.updated.selected_devices = JSON.parse(JSON.stringify(this.renders.selected_devices()));
                                            }
                                            this.state.updated.selected_devices.unshift(carrier);
                                            await this.promisedSetState({
                                                updated: this.state.updated,
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.renders.selected_devices().length > 0 &&
                                    <div className='col-span-6 mt-3'>
                                        {selected_targeting("selected_devices", false, true)}
                                    </div>
                                }


                                <div className="col-span-6">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Connection types
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.connection_types.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        item.included = true;
                                                        this.state.updated.connection_types = item;
                                                        this.state.connection_types = this.state.connection_types.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.included = false;
                                                            } else {
                                                                inner_item.included = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            connection_types: this.state.connection_types
                                                        })
                                                        //this.functions.estimateAudience();

                                                    }} className={(item.included ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        {
                                                            item.value == "CELL" &&
                                                            <ChartBarIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        {
                                                            item.value == "WIFI" &&
                                                            <WifiIcon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        }
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.included ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.included ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        ref="searchDropdown"
                                        label={"Carriers"}
                                        searchInput={true}
                                        selected={{ name: "Search ..." }}
                                        options={this.state.all_carriers}
                                        onChange={async (carrier) => {
                                            carrier.included = true;
                                            if (!Array.isArray(this.state.updated.selected_carriers)) {
                                                this.state.updated.selected_carriers = JSON.parse(JSON.stringify(this.renders.selected_carriers()));
                                            }
                                            this.state.updated.selected_carriers.unshift(carrier);
                                            await this.promisedSetState({
                                                updated: this.state.updated,
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    this.renders.selected_carriers().length > 0 &&
                                    <div className='col-span-6 mt-3'>
                                        {selected_targeting("selected_carriers", false, true)}
                                    </div>
                                }
                                {
                                    (this.renders.locations().length === 0) &&
                                    <div className="col-span-6 w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
                                        OBS! Add locations first to edit this section
                                    </div>
                                }


                            </div>
                        </>
                    }


                    {/*DEMOGRAPHICS*/}
                    {
                        this.state.tab.id === 8 &&
                        <>
                            <div className="col-span-6 grid grid-cols-6 gap-4">
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Third party tagging - MOAT</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.moat().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.moat().value ? true : false}
                                                onSwitch={async (value) => {
                                                    if (this.renders.moat().value) {
                                                        this.state.updated.moat = { id: 2, name: "No", value: false };

                                                    } else {
                                                        this.state.updated.moat = { id: 1, name: "Yes", value: "MOAT_SS" };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3">
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Third party tagging - DoubleVerifyd</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5">
                                            {this.renders.doubleVerify().value ? "Yes" : "No"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.renders.doubleVerify().value ? true : false}
                                                onSwitch={async () => {
                                                    if (this.renders.doubleVerify().value) {
                                                        this.state.updated.double_verify = { id: 2, name: "No", value: false };

                                                    } else {
                                                        this.state.updated.double_verify = { id: 1, name: "Yes", value: "DOUBLEVERIFY" };
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Optimization goal"}
                                        options={[]}
                                        locked={true}
                                        selected={this.renders.optimizationGoal()}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <DropdownTailwind
                                        label={"Bid Strategy"}
                                        selected={this.renders.bidStrategy()}
                                        options={this.state.bid_strategies}
                                        locked={false}
                                        onChange={(value) => {
                                            this.state.updated.bid_strategy = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    (this.renders.bidStrategy().id === 2 || this.renders.bidStrategy().id === 3) &&
                                    <div className="col-span-6">
                                        <InputTailwind
                                            // label={this.state.updated.goal.value === "SWIPES" ? "Per swipe" : "Per 1000 impressions"}
                                            label={"Per Swipe Up"}
                                            leftSection={(this.state.currency !== "" ? true : false)}
                                            leftSectionLabel={(this.state.currency)}
                                            value={this.renders.bidCap()}
                                            type={'number'}
                                            onChange={(value) => {
                                                this.state.updated.bid_cap = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                }
                                {
                                    /*
                                    this.state.updated.goal && this.state.updated.goal.value === "IMPRESSIONS" &&
                                    <div className="col-span-6 grid grid-cols-6 gap-4">
                                        <div className='col-span-2'>
                                            <DropdownTailwind
                                                label={"Frequency cap"}
                                                selected={this.renders.hasFrequencyCap()}
                                                options={[
                                                    { id: 1, name: "Yes", value: true },
                                                    { id: 2, name: "No", value: false },
                                                ]}
                                                locked={false}
                                                onChange={(value) => {
                                                    this.state.updated.has_frequency_cap = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-span-2'>
                                            <InputTailwind
                                                label={"Maximum impressions"}
                                                selected={this.renders.frequencyMax()}
                                                type={'number'}
                                                disabled={this.state.updated.has_frequency_cap && this.state.updated.has_frequency_cap.value === true ? !this.state.updated.has_frequency_cap.value : true}
                                                locked={this.state.updated.has_frequency_cap && this.state.updated.has_frequency_cap.value === true ? !this.state.updated.has_frequency_cap.value : true}
                                                onChange={(value) => {
                                                    this.state.updated.frequency_max = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-span-2'>
                                            <InputTailwind
                                                label={"Per number of days"}
                                                selected={this.renders.frequencyDays()}
                                                type={'number'}
                                                disabled={this.state.updated.has_frequency_cap && this.state.updated.has_frequency_cap.value === true ? !this.state.updated.has_frequency_cap.value : true}
                                                locked={this.state.updated.has_frequency_cap && this.state.updated.has_frequency_cap.value === true ? !this.state.updated.has_frequency_cap.value : true}
                                                onChange={(value) => {
                                                    this.state.updated.frequency_days = value;
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>*/
                                }
                                {
                                    this.renders.bidStrategy().id === 3 &&
                                    <div className="col-span-6">
                                        <DropdownTailwind
                                            label={"Pacing type"}
                                            selected={this.renders.pacing()}
                                            options={[
                                                { id: 1, name: "Standard", value: "STANDARD" },
                                                { id: 2, name: "Accelerated", value: "ACCELERATED" },
                                            ]}
                                            locked={false}
                                            onChange={(value) => {
                                                this.state.updated.pacing = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                        </>
                    }


                    {
                        this.state.error &&
                        <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                    }

                </div >
            </>
        )
    }
}

export default EditLiveSnapchatAdgroup;
