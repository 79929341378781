import React, { Component, Fragment } from 'react';
import SweetAlert from 'sweetalert-react'
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import cn from "classnames";
import CreatePowerPoint from "../moduleFiles/createPowerPoint";
import AdvancedReportGrid from '../moduleFiles/advancedReportGrid';
import AdvancedReportCellSlideIn from '../moduleFiles/advancedReportCellSlideIn';
import { userRegister } from "../services/userRegister";
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import AdvancedReportSlideInDate from '../moduleFiles/advancedReportSlideInDate';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { CogIcon, DuplicateIcon, DocumentReportIcon, ExternalLinkIcon, PresentationChartBarIcon, SaveIcon, PlusIcon, CalendarIcon, BookOpenIcon, PencilAltIcon, TrashIcon, ArrowsExpandIcon, ExclamationCircleIcon, XIcon, CheckIcon, ChevronDownIcon, UserAddIcon } from '@heroicons/react/outline';
import AdvancedReportSlideInDatasource from '../moduleFiles/advancedReportSlideInDatasource';
import SuccessModal from '../moduleFiles/successModal';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import ExternalReportSettings from '../moduleFiles/externalReportSettings';
import AdvancedReportSlideInUpdatePage from '../moduleFiles/advancedReportSlideInUpdatePage';
import ReportTabEdit from '../moduleFiles/reportTabEdit';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { Listbox, Menu, Transition } from '@headlessui/react';
import ToolTip from '../moduleFiles/toolTip';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { io } from 'socket.io-client';
import ReactTooltip from "react-tooltip";
import ReportHtml from "../hooks/report/ReportHtml";
import exportPdf from "../hooks/report/exportPdf";
var moment = require('moment');

//WEBSOCKET
const socket = io((window.location.hostname === 'localhost' ? "http://localhost:3000" : (window.location.hostname === 'app.adcredo.io' ? "https://api.adcredo.io" : "https://apidev.adcredo.io")), {
    autoConnect: false,
    //reconnection: true,
    //reconnectionDelay: 500,
    //reconnectionAttempts: 3,
    //transports: ['websocket', 'polling'],
    secure: true,
    auth: {
        token: tokenRegister.get()
    }
});
//WEBSOCKET END

class AdvancedReportInternal extends Component {

    onBackButton = (event) => {
        alert('Dont use browser back.');
        window.history.pushState(null, document.title, window.location.href);
    };

    constructor(props) {
        super(props);
        this.state = {
            premium: true,
            loading: true,
            tab: 1,
            working_users: [],
            data: {},
            metrics: {},
            channels: [],
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels: {},
            selected_accounts: {},
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            name: "",
            public: false,
            slideinclosed: true,
            slideinDateclosed: true,
            slideinDatasourceclosed: true,
            slideinclosedMetrics: true,
            slideinclosedBreakdowns: true,
            slideinPageclosed: true,
            tabs: [],
            client: {},
            all_clients: [],
            selected_tab: {},
            selected_cell: {},
            init_metrics: [],
            report_logo: { id: 3, name: "No Logo", value: "none" },
            activeTab: 0,
            tabCounter: null,
            navToggleActive: "overview",
            navToggle: [],
            default_metrics: [],
            settings_open: false,
            report_type: "",
            updated_settings: {},
            report_title: "",
            report_description: "",
            report_public: false,
            report_background_color: "#fefefe",
            report_shadows: false,
            report_datelock: false,
            report_pagesections: false,
            report_pdfdownload: false,
            report_presync: false,
            report_transparent_cells: false,
            report_navbar_color: "#ffffff",
            report_accent_color: "#453FF1",
            report_font_color: "#333333",
            report_logo_size: { name: "Medium", value: "h-5" },
            report_public_password: false,
            report_disable_comparison: false,
            report_password: "",
            report_footerSettings: {},
            report_emailSettings: {},
            report_inherit_styles: true,
            confirm_delete: false,
            user: {},
            websocket_init: false,
            report_init: false
        };
    }

    componentWillMount() {

    }

    componentDidMount() {

        window.scrollTo(0, 0);
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', this.onBackButton);

        //this.functions.getReport(this.props.match.params.report);

        window.location.pathname.indexOf("advanced") !== -1 ? this.setState({ report_type: "professional" }) : this.setState({ report_type: "standard" });
        this.functions.user();

        //WEBSOCKET
        this.socket.init();
        window.addEventListener('beforeunload', this.stopWorking);

    }

    componentWillUnmount() {
        //WEBSOCKET
        window.removeEventListener('popstate', this.onBackButton);
        window.removeEventListener('beforeunload', this.stopWorking);
        this.stopWorking();
        this.socket.close();
    }

    socket = {
        init: () => {
            let self = this;
            socket.on("connect_error", (err) => {
                console.log(`connect_error due to: ${err.message}`);
                console.log("err.description: ", err.description);
                console.log("err.context: ", err.context);
                if (!self.state.error) {
                    self.setState({
                        error: err
                    });
                }
                self.functions.stopCellLoaders();
                if (socket.active) {
                    console.log(`socket.active`);
                } else {
                    console.log(`run socket.connect()`);
                    console.log(err.message);
                    socket.connect();
                }
            });
            socket.on("error", (err) => {
                console.log(`error due to ${err}`);
                self.setState({
                    websocket_init: false,
                    error: err
                });
            });
            socket.on('connect', () => {
                console.log(`connect`);
                if (!self.state.report_init) {
                    self.functions.getReport(self.props.match.params.report);
                }
                self.setState({
                    report_init: true,
                    websocket_init: true,
                    error: !self.state.report_init && self.state.error ? false : self.state.error
                });
                socket.emit("subscribeToReport", this.props.match.params.report);
                socket.emit('startWorkingOnReport', {
                    entityId: this.props.match.params.report,
                    userEmail: this.state.user.email,
                });
            });
            socket.on("disconnect", (reason) => {
                console.log(`disconnect due to ${reason}`);
            });
            socket.on('workingUsersUpdate', (data) => {
                if (data.entityId === this.props.match.params.report) {
                    this.promisedSetState({
                        working_users: data.users
                    });
                }
            });
            socket.on(self.props.match.params.report, async function (response, callback) {
                if (response.id) {
                    console.log("response", response);
                    if (Array.isArray(self.state.selected_tab.grid_data)) {
                        self.state.selected_tab.grid_data = self.state.selected_tab.grid_data.map((item) => {
                            if (item.i == response.id) {
                                if (response.load_status) {
                                    item.load_status = response.load_status;
                                } else {
                                    item.loading = false;
                                    item.selected = false;
                                    item.data = response.data;
                                    try {
                                        if (response.all_metrics) {
                                            for (let key in response.all_metrics) {
                                                for (let inner_key in item.metrics) {
                                                    if (response.all_metrics[key].id == item.metrics[inner_key].id) {
                                                        item.metrics[inner_key] = response.all_metrics[key];
                                                    }
                                                }
                                            }
                                        }
                                    } catch (error) { }
                                    item.errors = response.errors;
                                    item.load_status = null;
                                }
                            }
                            return item;
                        });
                    }
                    if (Array.isArray(self.state.tabs)) {
                        self.state.tabs = self.state.tabs.map((tab) => {
                            if (tab && Array.isArray(tab.grid_data)) {
                                tab.grid_data = tab.grid_data.map((cell) => {
                                    if (cell.i == response.id) {
                                        if (response.load_status) {
                                            cell.load_status = response.load_status;
                                        } else {
                                            cell.loading = false;
                                            cell.selected = false;
                                            cell.data = response.data;
                                            try {
                                                if (response.all_metrics) {
                                                    for (let key in response.all_metrics) {
                                                        for (let inner_key in cell.metrics) {
                                                            if (response.all_metrics[key].id == cell.metrics[inner_key].id) {
                                                                cell.metrics[inner_key] = response.all_metrics[key];
                                                            }
                                                        }
                                                    }
                                                }
                                            } catch (error) { }
                                            cell.errors = response.errors;
                                            cell.load_status = null;
                                        }
                                    }
                                    return cell;
                                });
                            }
                            return tab;
                        });
                    }
                    await self.promisedSetState({
                        tabs: self.state.tabs,
                        selected_tab: self.state.selected_tab
                    });
                }
                if (callback) {
                    callback("got it");
                }
            });
            socket.open();
        },
        close: () => {
            socket.disconnect();
            socket.off('connect_error');
            socket.off('error');
            socket.off('connect');
            socket.off('disconnect');
            socket.off('workingUsersUpdate');
            socket.off(this.props.match.params.report);
        }
    };

    stopWorking = () => {
        socket.emit('stopWorkingOnReport', {
            entityId: this.props.match.params.report,
            userEmail: this.state.user.email,
        });
    };

    functions = {
        loadFullReport: async () => {
            return Promise.all(await this.state.tabs.map(async (tab) => {
                if (tab.data_fetched !== true) {
                    let cells = tab.grid_data;
                    //REMOVE ALL ABSTRACT CELLS
                    tab.grid_data = tab.grid_data.filter((item) => {
                        let keep = true;
                        if (item.abstract && Array.isArray(cells)) {
                            cells.map((cell) => {
                                if (item.parent_cell == cell.i) {
                                    keep = false;
                                }
                            });
                        }
                        return keep;
                    });

                    tab.grid_data = tab.grid_data.map((item) => {
                        cells.map((cell) => {
                            if (item.i == cell.i) {
                                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                                    item.errors = [];
                                    item.loading = true;
                                }
                            }
                        });
                        return item;
                    });

                    let { cell_report, parsed_cells, failed_parse } = await this.cells.calculate(cells, false);

                    if (Array.isArray(tab.grid_data)) {
                        tab.grid_data = tab.grid_data.map((item) => {
                            item.loading = false;
                            item.selected = false;
                            if (cell_report && Array.isArray(cell_report.data)) {
                                cell_report.data.map((inner_item) => {
                                    if (item.i == inner_item.id) {
                                        item.all_metrics = inner_item.all_metrics;
                                        item.data = inner_item.data;
                                        item.errors = inner_item.errors;
                                    }
                                });
                            }
                            return item;
                        });
                    }

                    tab.data_fetched = true;
                    tab.loading = false;
                    tab.part_loading = false;

                    return tab;
                } else {
                    return tab;
                }
            })).then(async response => {
                return await this.promisedSetState({
                    tabs: response,
                });
            })
        },
        getReportHtml: () => {
            return <ReportHtml
                tabs={this.state.tabs}
                renders={this.renders}
                transparent_cells={this.state.report_transparent_cells}
                background_color={this.state.report_background_color}
            />
        },
        createPdf: async () => {
            try {
                this.setState({ loading_pdf: true });
                await this.functions.loadFullReport();
                await exportPdf(this.functions.getReportHtml);
                this.setState({
                    pdf_success: true,
                    loading_pdf: false
                });
            } catch (error) {
                this.setState({
                    pdf_success: false,
                    loading_pdf: false
                });
            }
        },
        createPptx: async () => {
            if (!this.state.loading_pptx) {
                let logo = "";
                if (this.state.report_logo.value === "client") {
                    logo = this.state.client.logo;
                } else if (this.state.report_logo.value === "agency") {
                    let user = userRegister.get();
                    if (user) {
                        logo = user.logo;
                    }
                }
                await this.promisedSetState({ loading_ppt: true });
                await this.functions.loadFullReport();

                let warning_text = "";
                let warnings = [];
                this.state.tabs.filter(tab => !tab.disabled).forEach(tab => {
                    tab.groups.forEach(group => {
                        if (group.rows > 6) {
                            warnings.push(`Tab "${tab.title}" page ${group.name ? group.name : group.index} has more than 6 rows, page excluded from presentation`)
                        }
                    });
                });

                if (warnings.length) {
                    warning_text = warnings.length + " Pages with more than 6 rows are excluded from presentation";
                }

                try {
                    await this.refs.pptx.functions.create(
                        this.state.tabs.filter(tab => !tab.disabled),
                        logo,
                        this.state.report_title,
                        this.state.report_description,
                        this.state.report_background_color,
                        this.state.report_font_color,
                        this.state.report_shadows,
                        this.state.report_transparent_cells
                    );
                    await this.promisedSetState({
                        ppxt_text_success: warning_text !== "" ? warning_text : null
                    });
                    await this.promisedSetState({
                        loading_ppt: false,
                        ppxt_success: true
                    });
                } catch (error) {
                    await this.promisedSetState({
                        loading_ppt: false,
                        ppxt_success: false
                    })
                }
            }
        },
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
            if (this.state.user && this.state.user.userRole) {

            }
        },
        getMetrics: async (data) => {
            await this.promisedSetState({
                loading_metrics: true
            });
            try {
                let response = await this.calls.getMetrics(data);
                await this.promisedSetState({
                    metrics: response.data
                });
            } catch (error) {
                console.error(error)
            }
            this.promisedSetState({
                loading_metrics: false
            });
        },
        getReport: async (id) => {
            await this.promisedSetState({
                loading: true
            });
            try {
                let response = await this.calls.getReport(id);
                await this.promisedSetState({
                    client: response.data.client,
                    all_clients: Array.isArray(response.data.clients) ? response.data.clients : [],
                    loading: false,
                    template: response.data.template,
                    master_template: response.data.master_template,
                    report_name: response.data.name,
                    report_title: typeof response.data.title == "string" ? response.data.title : "",
                    report_description: typeof response.data.description == "string" ? response.data.description : "",
                    report_public: typeof response.data.public == "boolean" ? response.data.public : false,
                    report_shadows: typeof response.data.shadows == "boolean" ? response.data.shadows : false,
                    report_datelock: typeof response.data.datelock == "boolean" ? response.data.datelock : false,
                    report_pdfdownload: typeof response.data.pdfDownload == "boolean" ? response.data.pdfDownload : false,
                    report_presync: typeof response.data.preSync == "boolean" ? response.data.preSync : false,
                    report_pagesections: typeof response.data.pageSections == "boolean" ? response.data.pageSections : false,
                    report_transparent_cells: typeof response.data.transparent_cells == "boolean" ? response.data.transparent_cells : false,
                    report_background_color: typeof response.data.background_color == "string" ? response.data.background_color : "f5f5f5",
                    report_navbar_color: typeof response.data.navbar_color == "string" ? response.data.navbar_color : "ffffff",
                    report_accent_color: typeof response.data.accent_color == "string" ? response.data.accent_color : "453FF1",
                    report_font_color: typeof response.data.font_color == "string" ? response.data.font_color : "333333",
                    report_logo_size: typeof response.data.report_logo_size == "string" ? response.data.report_logo_size : "h-5",
                    report_public_password: typeof response.data.public_password == "boolean" ? response.data.public_password : false,
                    report_disable_comparison: typeof response.data.disable_comparison == "boolean" ? response.data.disable_comparison : false,
                    report_password: typeof response.data.password == "string" ? response.data.password : "",
                    report_inherit_styles: typeof response.data.inherit_styles == "boolean" ? response.data.inherit_styles : false,
                    report_footerSettings: typeof response.data.footerSettings == "object" ? response.data.footerSettings : {},
                    report_emailSettings: typeof response.data.emailSettings == "object" ? response.data.emailSettings : {},
                    tabs: response.data.internal_data.tabs,
                    report_logo: response.data.logo === "agency" ? {
                        id: 1,
                        name: "Agency logo",
                        value: "agency"
                    } : (response.data.logo === "client" ? {
                        id: 2,
                        name: "Client logo",
                        value: "client"
                    } : { id: 3, name: "No Logo", value: "none" })
                });
                const channel_filter = response.data.client.channels.map(channel => channel.channel)
                const client_filter = response.data.clients.map(client => client.id)
                this.functions.getMetrics({ channels: channel_filter, clients: client_filter });
                this.tabs.init();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading: false
                })
            }
        },
        updateReport: async () => {
            if (!this.state.loading_save) {

                await this.promisedSetState({
                    loading_save: true
                });

                let data = {
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    background_color: this.state.report_background_color,
                    shadows: this.state.report_shadows,
                    datelock: this.state.report_datelock,
                    pdfDownload: this.state.report_pdfdownload,
                    preSync: this.state.report_presync,
                    pageSections: this.state.report_pagesections,
                    transparent_cells: this.state.report_transparent_cells,
                    navbar_color: this.state.report_navbar_color,
                    accent_color: this.state.report_accent_color,
                    font_color: this.state.report_font_color,
                    logo_size: this.state.report_logo_size.value,
                    public_password: this.state.report_public_password,
                    disable_comparison: this.state.report_disable_comparison,
                    password: this.state.report_password,
                    inherit_styles: this.state.report_inherit_styles,
                    footerSettings: this.state.report_footerSettings,
                    emailSettings: this.state.report_emailSettings,
                    internal_data: {
                        tabs: []
                    }
                };

                this.state.tabs.map((tab) => {
                    let new_tab = {
                        id: tab.id,
                        grid_data: [],
                        title: tab.title,
                        channels: tab.channels,
                        tags: tab.tags,
                        groups: tab.groups,
                        init: tab.init,
                        daterange: tab.daterange,
                        loading: false,
                        disabled: tab.disabled,
                        only_active_data: tab.only_active_data,
                        date_comparison: tab.date_comparison,
                        mastertemplate_overwrite: tab.mastertemplate_overwrite
                    };
                    if (Array.isArray(tab.grid_data)) {
                        tab.grid_data.map((cell) => {
                            let new_cell = {};
                            for (let key in cell) {
                                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                                    if (key !== "data") {
                                        new_cell[key] = cell[key];
                                    }
                                } else {
                                    new_cell[key] = cell[key];
                                }
                            }
                            if (cell.data_sources && cell.data_sources.campaigns) {
                                for (let campaign in cell.data_sources.campaigns) {
                                    new_cell.data_sources.campaigns[campaign] = {
                                        channel: cell.data_sources.campaigns[campaign].channel,
                                        name: cell.data_sources.campaigns[campaign].name,
                                        id: campaign,
                                        client: cell.data_sources.campaigns[campaign].client,
                                        client_name: cell.data_sources.campaigns[campaign].client_name,
                                    };
                                }
                            }
                            if (cell.data_sources && cell.data_sources.adgroups) {
                                for (let adgroup in cell.data_sources.adgroups) {
                                    new_cell.data_sources.adgroups[adgroup] = {
                                        channel: cell.data_sources.adgroups[adgroup].channel,
                                        id: adgroup,
                                        name: cell.data_sources.adgroups[adgroup].name,
                                        campaign: cell.data_sources.adgroups[adgroup].campaign,
                                        client: cell.data_sources.adgroups[adgroup].client,
                                        client_name: cell.data_sources.adgroups[adgroup].client_name,
                                    };
                                }
                            }
                            if (cell.data_sources && cell.data_sources.ads) {
                                for (let ad in cell.data_sources.ads) {
                                    new_cell.data_sources.ads[ad].insights = {
                                        channel: cell.data_sources.ads[ad].channel,
                                        id: ad,
                                        name: cell.data_sources.ads[ad].name,
                                        adgroup: cell.data_sources.ads[ad].adgroup,
                                        campaign: cell.data_sources.ads[ad].campaign,
                                        client: cell.data_sources.ads[ad].client,
                                        client_name: cell.data_sources.ads[ad].client_name,
                                    };
                                }
                            }
                            new_tab.grid_data.push(new_cell);
                        });
                        data.internal_data.tabs.push(new_tab);
                    }
                });
                try {
                    await this.calls.updateReport(data);
                    await this.promisedSetState({
                        saved_success: true,
                        loading_save: false
                    });
                } catch (error) {
                    await this.promisedSetState({
                        loading_save: false
                    });
                }
            }
        },
        createReport: async () => {
            if (!this.state.loading_create) {

                this.setState({
                    loading_create: true,
                    create_report: false
                });

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    freemium: false,
                    premium: true,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    background_color: this.state.report_background_color,
                    shadows: this.state.report_shadows,
                    datelock: this.state.report_datelock,
                    pdfDownload: this.state.report_pdfdownload,
                    preSync: this.state.report_presync,
                    pageSections: this.state.report_pagesections,
                    transparent_cells: this.state.report_transparent_cells,
                    navbar_color: this.state.report_navbar_color,
                    accent_color: this.state.report_accent_color,
                    font_color: this.state.report_font_color,
                    logo_size: this.state.report_logo_size.value,
                    public_password: this.state.report_public_password,
                    password: this.state.report_password,
                    disable_comparison: this.state.report_disable_comparison,
                    inherit_styles: this.state.report_inherit_styles,
                    footerSettings: this.state.report_footerSettings,
                    emailSettings: this.state.report_emailSettings,
                    internal_data: {
                        tabs: []
                    }
                };

                //MERGE ALL CAMPAIGNS
                if (Array.isArray(this.state.campaigns)) {
                    this.state.campaigns.map((campaign) => {
                        if (!data[campaign.channel]) {
                            data[campaign.channel] = [];
                        }
                        data[campaign.channel].push(campaign.id);
                    });
                }

                this.state.tabs.map((tab) => {
                    let new_tab = {
                        id: tab.id,
                        grid_data: [],
                        title: tab.title,
                        groups: tab.groups,
                        init: tab.init,
                        daterange: tab.daterange,
                        loading: false
                    };
                    if (Array.isArray(tab.grid_data)) {
                        tab.grid_data.map((cell) => {
                            let new_cell = {};
                            for (let key in cell) {
                                if (cell.celltype && (cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                                    if (key !== "data") {
                                        new_cell[key] = cell[key];
                                    }
                                } else {
                                    new_cell[key] = cell[key];
                                }
                            }
                            if (cell.data_sources && cell.data_sources.campaigns) {
                                for (let campaign in cell.data_sources.campaigns) {
                                    new_cell.data_sources.campaigns[campaign] = {
                                        channel: cell.data_sources.campaigns[campaign].channel,
                                        id: campaign,
                                        name: cell.data_sources.campaigns[campaign].name,
                                        client: cell.data_sources.campaigns[campaign].client,
                                        client_name: cell.data_sources.campaigns[campaign].client_name
                                    };
                                }
                            }
                            if (cell.data_sources && cell.data_sources.adgroups) {
                                for (let adgroup in cell.data_sources.adgroups) {
                                    new_cell.data_sources.adgroups[adgroup] = {
                                        channel: cell.data_sources.adgroups[adgroup].channel,
                                        id: adgroup,
                                        name: cell.data_sources.adgroups[adgroup].name,
                                        campaign: cell.data_sources.adgroups[adgroup].campaign,
                                        client: cell.data_sources.adgroups[adgroup].client,
                                        client_name: cell.data_sources.adgroups[adgroup].client_name
                                    };
                                }
                            }
                            if (cell.data_sources && cell.data_sources.ads) {
                                for (let ad in cell.data_sources.ads) {
                                    new_cell.data_sources.ads[ad].insights = {
                                        channel: cell.data_sources.ads[ad].channel,
                                        id: ad,
                                        name: cell.data_sources.ads[ad].name,
                                        adgroup: cell.data_sources.ads[ad].adgroup,
                                        campaign: cell.data_sources.ads[ad].campaign,
                                        client: cell.data_sources.ads[ad].client,
                                        client_name: cell.data_sources.ads[ad].client_name
                                    };
                                }
                            }
                            new_tab.grid_data.push(new_cell);
                        });
                        data.internal_data.tabs.push(new_tab);
                    }
                });
                try {
                    await this.calls.createReport(data, this.state.client.id);
                    this.props.history.push("/v2/reports/advanced");
                } catch (error) {
                    console.log(error);
                }
                this.setState({
                    loading_create: false
                });
            }
        },
        createTemplate: async () => {
            if (!this.state.loading_create) {

                this.setState({
                    loading_create: true,
                    create_template: false
                });

                let data = {
                    name: this.state.report_name,
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    freemium: false,
                    premium: true,
                    new_template: true,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    background_color: this.state.report_background_color,
                    shadows: this.state.report_shadows,
                    datelock: this.state.report_datelock,
                    pdfDownload: this.state.report_pdfdownload,
                    preSync: this.state.report_presync,
                    pageSections: this.state.report_pagesections,
                    transparent_cells: this.state.report_transparent_cells,
                    navbar_color: this.state.report_navbar_color,
                    accent_color: this.state.report_accent_color,
                    font_color: this.state.report_font_color,
                    logo_size: this.state.report_logo_size.value,
                    public_password: this.state.report_public_password,
                    password: this.state.report_password,
                    inherit_styles: this.state.report_inherit_styles,
                    footerSettings: this.state.report_footerSettings,
                    emailSettings: this.state.report_emailSettings,
                    disable_comparison: this.state.report_disable_comparison,
                    internal_data: {
                        tabs: []
                    }
                };

                //MERGE ALL CAMPAIGNS
                if (Array.isArray(this.state.campaigns)) {
                    this.state.campaigns.map((campaign) => {
                        if (!data[campaign.channel]) {
                            data[campaign.channel] = [];
                        }
                        data[campaign.channel].push(campaign.id);
                    });
                }

                this.state.tabs.map((tab) => {
                    let new_tab = {
                        id: tab.id,
                        grid_data: [],
                        title: tab.title,
                        groups: tab.groups,
                        init: tab.init,
                        daterange: tab.daterange,
                        loading: false
                    };
                    if (Array.isArray(tab.grid_data)) {
                        tab.grid_data.map((cell) => {
                            let new_cell = {};
                            for (let key in cell) {
                                if (cell.celltype && (cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                                    if (key !== "data") {
                                        new_cell[key] = cell[key];
                                    }
                                } else {
                                    new_cell[key] = cell[key];
                                }
                                new_cell.data_sources = {
                                    campaigns: {},
                                    adgroups: {},
                                    ads: {}
                                };
                            }
                            new_tab.grid_data.push(new_cell);
                        });
                        data.internal_data.tabs.push(new_tab);
                    }
                });
                try {
                    await this.calls.createReport(data, this.state.client.id);
                    this.props.history.push("/v2/reports/template");
                } catch (error) {
                    console.log(error);
                }
                this.setState({
                    loading_create: false
                });
            }
        },
        updateTemplate: async () => {
            if (!this.state.loading_save) {
                await this.promisedSetState({
                    loading_save: true
                });
                let data = {
                    title: this.state.report_title,
                    description: this.state.report_description,
                    public: this.state.report_public,
                    id: this.props.match.params.report,
                    campaign: this.props.match.params.id,
                    logo: this.state.report_logo.value,
                    background_color: this.state.report_background_color,
                    shadows: this.state.report_shadows,
                    datelock: this.state.report_datelock,
                    pdfDownload: this.state.report_pdfdownload,
                    preSync: this.state.report_presync,
                    pageSections: this.state.report_pagesections,
                    transparent_cells: this.state.report_transparent_cells,
                    navbar_color: this.state.report_navbar_color,
                    accent_color: this.state.report_accent_color,
                    font_color: this.state.report_font_color,
                    logo_size: this.state.report_logo_size.value,
                    public_password: this.state.report_public_password,
                    password: this.state.report_password,
                    inherit_styles: this.state.report_inherit_styles,
                    footerSettings: this.state.report_footerSettings,
                    emailSettings: this.state.report_emailSettings,
                    disable_comparison: this.state.report_disable_comparison,
                    internal_data: {
                        tabs: []
                    }
                };
                this.state.tabs.map((tab) => {
                    let new_tab = {
                        id: tab.id,
                        grid_data: [],
                        title: tab.title,
                        groups: tab.groups,
                        init: tab.init,
                        daterange: tab.daterange,
                        loading: false,
                        disabled: tab.disabled,
                        channels: tab.channels,
                        tags: tab.tags,
                        only_active_data: tab.only_active_data,
                        date_comparison: tab.date_comparison,
                        mastertemplate_overwrite: tab.mastertemplate_overwrite
                    };
                    if (Array.isArray(tab.grid_data)) {
                        tab.grid_data.map((cell) => {
                            let new_cell = {};
                            for (let key in cell) {
                                if (cell.celltype && (cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                                    if (key !== "data") {
                                        new_cell[key] = cell[key];
                                    }
                                } else {
                                    new_cell[key] = cell[key];
                                }
                            }
                            if (!this.state.master_template && !this.state.template) {
                                new_cell.data_sources = {
                                    channels: {},
                                    campaigns: {},
                                    adgroups: {},
                                    ads: {}
                                };
                            }
                            new_tab.grid_data.push(new_cell);
                        });
                        data.internal_data.tabs.push(new_tab);
                    }
                });
                try {
                    await this.calls.updateReport(data);
                    await this.promisedSetState({
                        saved_success: true,
                        loading_save: false
                    });
                } catch (error) {
                    await this.promisedSetState({
                        loading_save: false
                    });
                }
            }
        },
        stopCellLoaders: async () => {
            if (Array.isArray(this.state.selected_tab.grid_data)) {
                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                    item.loading = false;

                    return item;
                });
            }
            if (Array.isArray(this.state.tabs)) {
                this.state.tabs = this.state.tabs.map((tab) => {
                    if (tab && Array.isArray(tab.grid_data)) {
                        tab.grid_data = tab.grid_data.map((cell) => {
                            cell.loading = false;

                            return cell;
                        });
                    }

                    return tab;
                });
            }
            this.setState({
                tabs: this.state.tabs,
                selected_tab: this.state.selected_tab
            });
        }
    };

    tabs = {
        init: async () => {
            let new_tab = {};
            if (this.state.tabs.length < 1) {
                new_tab = {
                    loading: true,
                    init: true,
                    id: Math.floor((Math.random() * 9999999999) + 1),
                    title: !this.state.premium ? 'Campaigns' : 'Tab 1',
                    editMode: false,
                    premium: true,
                    grid_data: [],
                    backup: '',
                    groups: [{
                        id: 1,
                        name: "",
                        rows: 6,
                        layout: [],
                        index: 0,
                        items: [],
                        coordinates: {}
                    }],
                    data_sources: { channels: {}, accounts: {}, campaigns: {}, adgroups: {}, ads: {} },
                    channel_breakdowns: {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "twitter": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google_search_console": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    },
                    metrics: {},
                    piechart: this.state.default_charts,
                    daterange: {},
                    disabled: false
                };
                this.state.tabs.push(new_tab);
            } else {

                //UPDATE DATERANGE
                this.state.tabs = this.state.tabs.map((tab) => {

                    //UPDATE DATES
                    if (tab.daterange && (tab.daterange.value == "last_month" || tab.daterange.value == "last_week" || tab.daterange.value == "this_month")) {
                        if (tab.daterange.value == "last_week") {
                            tab.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                            tab.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                        }
                        if (tab.daterange.value == "last_month") {
                            tab.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                            tab.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                        }
                        if (tab.daterange.value == "this_month") {
                            tab.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                            if (moment().startOf('month').format("DD") == "01") {
                                tab.daterange.end_date = moment().format("YYYY-MM-DD");
                            } else {
                                tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                            }
                        }
                    } else if (tab.daterange && tab.daterange.value && tab.daterange.value !== "lifetime" && tab.daterange.value !== "custom_dates" && tab.daterange.value !== "x_days") {
                        let end_date = tab.daterange.value.replace("_days", "");
                        tab.daterange.start_date = moment().subtract(+end_date, 'days').format("YYYY-MM-DD");
                        tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                    } else if (tab.daterange && tab.daterange.value == "x_days") {
                        if (tab.daterange.days && tab.daterange.days.value) {
                            tab.daterange.start_date = moment().subtract(tab.daterange.days.value, 'days').format("YYYY-MM-DD");
                            tab.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                        }
                    }

                    if (Array.isArray(tab.grid_data)) {

                        tab.groups = tab.groups.map((group) => {
                            group.mobile = false;
                            return group;
                        });

                        tab.grid_data = tab.grid_data.map((cell) => {

                            //SET DESKTOP AS DEFAULT
                            if ('x_desktop' in cell) {
                                cell.x = cell.x_desktop;
                            }
                            if ('y_desktop' in cell) {
                                cell.y = cell.y_desktop;
                            }
                            if ('w_desktop' in cell) {
                                cell.w = cell.w_desktop;
                            }
                            if ('h_desktop' in cell) {
                                cell.h = cell.h_desktop;
                            }

                            if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {

                                //REMOVE CELL DATA
                                cell.data = null;
                                cell.errors = [];

                                //SET LOADER
                                cell.loading = true;

                            }

                            //UPDATE OLD TABLES
                            if (cell.celltype && cell.celltype.value === "table") {

                                //PARSE COLUMNS
                                cell.default_columns = [];
                                cell.default_columns = !cell.remove_preview ? cell.default_columns.concat([{ value: "preview", title: cell.settings && cell.settings.remove_preview_title && cell.settings.remove_preview_title !== "" ? cell.settings.remove_preview_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_channel ? cell.default_columns.concat([{ value: "channel", title: cell.settings && cell.settings.remove_channel_title && cell.settings.remove_channel_title !== "" ? cell.settings.remove_channel_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_level ? cell.default_columns.concat([{ value: "level", title: cell.settings && cell.settings.remove_level_title && cell.settings.remove_level_title !== "" ? cell.settings.remove_level_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_breakdown ? cell.default_columns.concat([{ value: "breakdown", title: cell.settings && cell.settings.remove_breakdown_title && cell.settings.remove_breakdown_title !== "" ? cell.settings.remove_breakdown_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_breakdown_value ? cell.default_columns.concat([{ value: "breakdown_value", title: cell.settings && cell.settings.remove_breakdown_value_title && cell.settings.remove_breakdown_value_title !== "" ? cell.settings.remove_breakdown_value_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_name ? cell.default_columns.concat([{ value: "name", title: cell.settings && cell.settings.remove_name_title && cell.settings.remove_name_title !== "" ? cell.settings.remove_name_title : null }]) : cell.default_columns;
                                cell.default_columns = !cell.remove_id ? cell.default_columns.concat([{ value: "id", title: cell.settings && cell.settings.remove_id_title && cell.settings.remove_id_title !== "" ? cell.settings.remove_id_title : null }]) : cell.default_columns;
                                cell.columns = [];
                                if (Array.isArray(cell.table_metrics_index)) {
                                    cell.table_metrics_index.map((item) => {
                                        cell.columns.push(item.name);
                                    });
                                }

                            }

                            //UPDATE DATES
                            if (cell.daterange && (cell.daterange.value == "last_month" || cell.daterange.value == "last_week" || cell.daterange.value == "this_month")) {
                                if (cell.daterange.value == "last_week") {
                                    cell.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                                    cell.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                                }
                                if (cell.daterange.value == "last_month") {
                                    cell.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                                    cell.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                                }
                                if (cell.daterange.value == "this_month") {
                                    cell.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                                    if (moment().startOf('month').format("DD") == "01") {
                                        cell.daterange.end_date = moment().format("YYYY-MM-DD");
                                    } else {
                                        cell.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                    }
                                }
                            } else if (cell.daterange && cell.daterange.value && cell.daterange.value !== "lifetime" && cell.daterange.value !== "custom_dates" && cell.daterange.value !== "x_days") {
                                let end_date = cell.daterange.value.replace("_days", "");
                                cell.daterange.start_date = moment().subtract(+end_date, 'days').format("YYYY-MM-DD");
                                cell.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                            } else if (cell.daterange && cell.daterange.value == "x_days") {
                                if (cell.daterange.days && cell.daterange.days.value) {
                                    cell.daterange.start_date = moment().subtract(cell.daterange.days.value, 'days').format("YYYY-MM-DD");
                                    cell.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                }
                            }

                            //IF OLD VERSION (CHECK METRIC)
                            if (cell.metric) {
                                cell.metrics = {};
                                cell.metrics[cell.metric.name] = cell.metric;
                            }

                            return cell;

                        });
                    }

                    return tab;
                });

                new_tab = this.state.tabs[0];
                new_tab.loading = true;
            }

            await this.promisedSetState({
                loading: false,
                selected_tab: new_tab,
                tabs: this.state.tabs,
            });

            this.tabs.update(true);
        },
        update: async (force_update) => {

            this.state.selected_tab.loading = true;
            await this.promisedSetState({ selected_tab: this.state.selected_tab });

            if (this.state.master_template || this.state.template) {
                await this.promisedSetState({
                    channels: [
                        { id: "adform", name: "adform", value: "adform" },
                        { id: "google", name: "google", value: "google" },
                        { id: "facebook", name: "facebook", value: "facebook" },
                        { id: "bing", name: "bing", value: "bing" },
                        { id: "linkedin", name: "linkedin", value: "linkedin" },
                        { id: "snapchat", name: "snapchat", value: "snapchat" },
                        { id: "tiktok", name: "tiktok", value: "tiktok" },
                        { id: "dv360", name: "dv360", value: "dv360" },
                        { id: "cm360", name: "cm360", value: "cm360" },
                        { id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4" },
                        { id: "bidtheatre", name: "bidtheatre", value: "bidtheatre" },
                        { id: "twitter", name: "twitter", value: "twitter" },
                    ]
                });
            }

            if (!this.state.template) {
                if (!this.state.master_template || (this.state.master_template && !this.state.template)) {

                    let all_datasources = {};
                    this.state.selected_tab.grid_data.map((cell) => {
                        if (cell.data_sources) {
                            for (let level in cell.data_sources) {
                                if (!all_datasources[level]) {
                                    all_datasources[level] = {};
                                }
                                for (let item in cell.data_sources[level]) {
                                    all_datasources[level][item] = cell.data_sources[level][item];
                                }
                            }
                        }
                    });

                    //GET ALL ACCOUNTS INFORMATION IF NONE
                    if (this.state.accounts.length < 1 && this.state.client && this.state.client.id) {
                        let accounts = [];
                        if (Array.isArray(this.state.client.channels)) {
                            accounts = this.state.client.channels;
                        }
                        await this.promisedSetState({ accounts: accounts });
                    }

                    //GET ALL CAMPAIGNS INFORMATION IF NONE
                    if (this.state.campaigns.length < 1) {
                        await this.information.clientsCampaigns();
                    }

                    let adgroup_data = {};
                    this.state.campaigns.map((campaign) => {
                        if (!adgroup_data[campaign.client + "_" + campaign.channel]) {
                            adgroup_data[campaign.client + "_" + campaign.channel] = [];
                        }
                        for (let adgroup in all_datasources.adgroups) {
                            if (all_datasources.adgroups[adgroup].campaign == campaign.id) {
                                adgroup_data[campaign.client + "_" + campaign.channel].push(campaign.id);
                            }
                        }
                        for (let ad in all_datasources.ads) {
                            if (all_datasources.ads[ad].campaign == campaign.id) {
                                adgroup_data[campaign.client + "_" + campaign.channel].push(campaign.id);
                            }
                        }
                    });
                    let adgroup_calls = Object.keys(adgroup_data).filter((client_channel) => {
                        return adgroup_data[client_channel].length > 0
                    }).map((client_channel) => {
                        let call = {
                            client: client_channel.split("_")[0],
                            channel: client_channel.split("_")[1],
                            campaigns: adgroup_data[client_channel].filter((item, pos) => {
                                return adgroup_data[client_channel].indexOf(item) == pos;
                            })
                        };
                        return call;
                    });
                    if (adgroup_calls.length > 0) {
                        //await this.information.adgroups(adgroup_calls)
                    }

                    //GET ALL ADS
                    let ad_data = {};
                    this.state.adgroups.map(async (adgroup) => {
                        adgroup = JSON.parse(JSON.stringify(adgroup));
                        for (let ad in all_datasources.ads) {
                            if (all_datasources.ads[ad].adgroup == adgroup.id) {
                                if (!ad_data[adgroup.client + "_" + adgroup.channel]) {
                                    ad_data[adgroup.client + "_" + adgroup.channel] = [];
                                }
                                ad_data[adgroup.client + "_" + adgroup.channel].push(adgroup.id);
                            }
                        }
                    });
                    let ad_calls = Object.keys(ad_data).filter((client_channel) => {
                        return ad_data[client_channel].length > 0
                    }).map((client_channel) => {
                        let call = {
                            client: client_channel.split("_")[0],
                            channel: client_channel.split("_")[1],
                            adgroups: ad_data[client_channel].filter((item, pos) => {
                                return ad_data[client_channel].indexOf(item) == pos;
                            })
                        };
                        return call;
                    })
                    if (ad_calls.length > 0) {
                        //await this.information.ads(ad_calls)
                    }

                }
            }

            this.state.tabs = this.state.tabs.map((tab) => {
                if (tab.id == this.state.selected_tab.id) {
                    tab = this.state.selected_tab;
                }
                return tab;
            });

            await this.promisedSetState({ selected_tab: this.state.selected_tab });
            await this.promisedSetState({ tabs: this.state.tabs });

            if (Array.isArray(this.state.selected_tab.grid_data)) {
                if (!this.state.selected_tab.data_fetched || force_update) {
                    this.cells.update(this.state.selected_tab.grid_data);
                } else {
                    this.state.selected_tab.loading = false;
                    this.state.selected_tab.part_loading = false;
                    await this.promisedSetState({ selected_tab: this.state.selected_tab });
                }
            } else {
                this.state.selected_tab.loading = false;
                this.state.selected_tab.part_loading = false;
                await this.promisedSetState({ selected_tab: this.state.selected_tab });
            }

        }
    };

    cells = {
        create: async (cell, option) => {

            let self = this;
            let abstract_comparison_cell = false;

            //INIT CELL
            let cell_information = {};
            if (cell.i) {

                let new_id = null;
                if (option == "clone") {
                    new_id = 'n' + Date.now() + Math.floor(Math.random() * 1000);
                } else {
                    new_id = cell.i;
                }

                cell_information.group = cell.group;
                //cell_information.i = cell.i;

                cell_information.x = cell.x;
                cell_information.y = cell.y;
                cell_information.w = cell.w;
                cell_information.h = cell.h;

                cell_information.x_desktop = cell.x_desktop;
                cell_information.y_desktop = cell.y_desktop;
                cell_information.w_desktop = cell.w_desktop;
                cell_information.h_desktop = cell.h_desktop;

                cell_information.x_mobile = cell.x_mobile;
                cell_information.y_mobile = cell.y_mobile;
                cell_information.w_mobile = cell.w_mobile;
                cell_information.h_mobile = cell.h_mobile;

                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {

                    if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {
                        abstract_comparison_cell = { i: null };
                    }

                    cell.loading = true;
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (item.i === new_id) {
                            item = cell;
                            if (option !== "clone") {
                                item.errors = [];
                            }
                            item.loading = true;
                        }
                        return item;
                    });

                    //REMOVE OLD ABSTRACT CELL (COMPARE WITH OLD CELL ID IF CLONE)
                    if (option !== "clone") {
                        this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.filter((item) => {
                            return !item.abstract || (item.abstract && item.parent_cell !== cell.i)
                        });
                    } else {
                        this.state.selected_tab.grid_data.map((item) => {
                            if (item.abstract && item.parent_cell === cell.i) {
                                abstract_comparison_cell = item;
                            }
                        });
                    }

                    await this.promisedSetState({ selected_tab: this.state.selected_tab });

                }

                //SET NEW ID
                cell.i = new_id;
                cell_information.i = new_id;

            } else {
                cell_information = {
                    group: cell.group,
                    i: 'n' + Date.now() + Math.floor(Math.random() * 1000),
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 3
                };
                if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {
                    abstract_comparison_cell = { i: null };
                }
            }

            cell_information.settings = cell.settings;

            //MAKE SURE TO RESET BREAKDOWNS IF PERFORMANCE TRACKER
            if (cell.celltype && cell.celltype.id === "performance") {
                cell.channel_breakdowns = {
                    "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                    "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } }
                };
            }

            //TEXT CELL
            if (cell.celltype && cell.celltype.value === "text") {
                cell_information.header = 'Text field';
                cell_information.data = (option == 'clone' || cell.i) ? cell.data : '';
                cell_information.celltype = cell.celltype;
                cell_information.typeOfBlock = 'input';
            }

            //IMAGE CELL
            else if (cell.celltype && cell.celltype.value === "image") {
                cell_information.header = 'screenshot';
                cell_information.data = (option == 'clone' || cell.i) ? cell.data : null;
                cell_information.celltype = cell.celltype;
                cell_information.format = cell.format;
                cell_information.typeOfBlock = 'img';
            }

            //PREVIEW CELL
            else if (cell.celltype && cell.celltype.value === "preview") {
                cell_information.header = 'screenshot';
                cell_information.data = (option == 'clone' || cell.i) ? cell.data : null;
                cell_information.celltype = cell.celltype;
                cell_information.loading = true;
                cell_information.format = cell.format;
                cell_information.data_sources = cell.data_sources;
                cell_information.typeOfBlock = 'preview';
                if (cell && cell.data_sources && cell.data_sources.accounts) {
                    for (let key in cell.data_sources.accounts) {
                        if (cell.data_sources.accounts[key].channel === "snapchat") {
                            cell_information.preview_placement = cell.settings && cell.settings.selected_placement && cell.settings.selected_placement.value ? cell.settings.selected_placement : { id: 1, name: "Story", value: "story" };
                            break;
                        } else {
                            cell_information.preview_placement = cell.settings && cell.settings.selected_placement && cell.settings.selected_placement.value ? cell.settings.selected_placement : { id: 0, name: "Feed", value: "feed" };
                        }
                    }
                }
                if (cell && cell.data_sources && cell.data_sources.ads) {
                    for (let key in cell.data_sources.ads) {
                        if (cell.data_sources.ads[key].channel === "snapchat") {
                            cell_information.preview_placement = cell.settings && cell.settings.selected_placement && cell.settings.selected_placement.value ? cell.settings.selected_placement : { id: 1, name: "Story", value: "story" };
                            break;
                        } else if (cell.data_sources.ads[key].channel === "google") {
                            cell_information.preview_placement = cell.settings && cell.settings.selected_placement && cell.settings.selected_placement.value ? cell.settings.selected_placement : { id: 5, name: "Square", value: "square" };
                            break;
                        } else {
                            cell_information.preview_placement = cell.settings && cell.settings.selected_placement && cell.settings.selected_placement.value ? cell.settings.selected_placement : { id: 0, name: "Feed", value: "feed" };
                        }
                    }
                }
            }

            //NUMBER CELL
            else if (cell.celltype && cell.celltype.value === "number") {
                cell_information.header = cell.settings ? cell.settings.title : "";
                cell_information.data = (option == 'clone' ? cell.data : null);
                cell_information.loading = true;
                cell_information.typeOfBlock = 'number';
                cell_information.channel_breakdowns = cell.channel_breakdowns;
                cell_information.celltype = cell.celltype;
                cell_information.data_sources = cell.data_sources;
                cell_information.daterange = cell.daterange;
                cell_information.metrics = cell.metrics;

                if (cell.celltype && cell.celltype.id === "performance") {
                    cell_information.subType = "performance";
                }

            }

            //CHART CELL
            else if (cell.celltype && cell.celltype.value === "chart") {
                cell_information.data = 0;
                cell_information.loading = true;
                cell_information.typeOfBlock = 'chart';
                cell_information.channel_breakdowns = cell.channel_breakdowns;
                cell_information.celltype = cell.celltype;
                cell_information.chart = cell.chart;
                cell_information.data_sources = cell.data_sources;
                cell_information.daterange = cell.daterange;
                cell_information.metrics = cell.metrics;
            }

            //TABLE CELL
            else if (cell.celltype && cell.celltype.value === "table") {
                cell_information.loading = true;
                cell_information.typeOfBlock = 'table';
                cell_information.channel_breakdowns = cell.channel_breakdowns;
                cell_information.celltype = cell.celltype;
                cell_information.data_sources = cell.data_sources;
                cell_information.table_metrics_index = cell.table_metrics_index;
                cell_information.daterange = cell.daterange;
                cell_information.metrics = cell.metrics;
                cell_information.remove_name = cell.remove_name;
                cell_information.remove_id = cell.remove_id;
                cell_information.remove_level = cell.remove_level;
                cell_information.remove_breakdown_value = cell.remove_breakdown_value;
                cell_information.remove_breakdown = cell.remove_breakdown;
                cell_information.remove_channel = cell.remove_channel;
                cell_information.remove_preview = cell.remove_preview;

                //PARSE COLUMNS
                cell_information.default_columns = [];
                cell_information.default_columns = !cell_information.remove_preview ? cell_information.default_columns.concat([{ value: "preview", title: cell_information.settings && cell_information.settings.remove_preview_title && cell_information.settings.remove_preview_title !== "" ? cell_information.settings.remove_preview_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_channel ? cell_information.default_columns.concat([{ value: "channel", title: cell_information.settings && cell_information.settings.remove_channel_title && cell_information.settings.remove_channel_title !== "" ? cell_information.settings.remove_channel_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_level ? cell_information.default_columns.concat([{ value: "level", title: cell_information.settings && cell_information.settings.remove_level_title && cell_information.settings.remove_level_title !== "" ? cell_information.settings.remove_level_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_breakdown ? cell_information.default_columns.concat([{ value: "breakdown", title: cell_information.settings && cell_information.settings.remove_breakdown_title && cell_information.settings.remove_breakdown_title !== "" ? cell_information.settings.remove_breakdown_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_breakdown_value ? cell_information.default_columns.concat([{ value: "breakdown_value", title: cell_information.settings && cell_information.settings.remove_breakdown_value_title && cell_information.settings.remove_breakdown_value_title !== "" ? cell_information.settings.remove_breakdown_value_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_name ? cell_information.default_columns.concat([{ value: "name", title: cell_information.settings && cell_information.settings.remove_name_title && cell_information.settings.remove_name_title !== "" ? cell_information.settings.remove_name_title : null }]) : cell_information.default_columns;
                cell_information.default_columns = !cell_information.remove_id ? cell_information.default_columns.concat([{ value: "id", title: cell_information.settings && cell_information.settings.remove_id_title && cell_information.settings.remove_id_title !== "" ? cell_information.settings.remove_id_title : null }]) : cell_information.default_columns;

                cell_information.columns = [];
                if (Array.isArray(cell_information.table_metrics_index)) {
                    cell_information.table_metrics_index.map((item) => {
                        cell_information.columns.push(item.name);
                    })
                }
            }

            let abstract_cell = null;

            //COPY CELL AND MAKE INVISIBLE (ABSTRACT) CELL
            if (abstract_comparison_cell) {

                if (abstract_comparison_cell.i) {
                    abstract_cell = JSON.parse(JSON.stringify(abstract_comparison_cell));
                } else {
                    abstract_cell = JSON.parse(JSON.stringify(cell_information));
                }
                abstract_cell.abstract = true;
                abstract_cell.parent_cell = cell_information.i;
                abstract_cell.i = cell_information.i + "_abstract";

                //COMPARE WITH A YEAR AGO
                if (cell.daterange && cell.daterange.compare_last_year && cell.daterange.compare_last_year.value === "enabled") {
                    abstract_cell.compare_last_year = true;
                }
                if (cell.daterange && cell.daterange.compare_custom_date && cell.daterange.compare_custom_date.value === "enabled") {
                    abstract_cell.compare_custom_date = true;
                }

            }

            //ADD CELL TO GRID HERE
            let free_space = true;
            if (cell_information && cell_information.celltype && (!cell.i || option == "clone")) {
                cell_information = addCellToGrid(cell_information);
                if (cell_information) {
                    this.state.selected_tab.grid_data.push(cell_information);
                } else {
                    free_space = false;
                    await this.promisedSetState({ cell_modal: true });
                }
            }

            if (free_space) {

                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                    //ABSTRACT CELL
                    if (abstract_cell && cell.celltype.value !== "preview") {
                        this.state.selected_tab.grid_data.push(abstract_cell);
                    }

                    await this.promisedSetState({ selected_tab: this.state.selected_tab });

                    //UPDATE CELL WITH DATA
                    if (option !== "clone" || cell.celltype.value === "chart") {

                        try {

                            //REGULAR CELL
                            let cells = [];
                            let cell_info = this.cells.report(cell_information);
                            cells.push(cell_info);

                            //ABSTRACT CELL
                            if (abstract_cell && cell.celltype.value !== "preview") {
                                let abstract_cell_info = this.cells.report(abstract_cell);
                                cells.push(abstract_cell_info);
                            }

                            //SKIP IF NO DATASOURCE SELECTED
                            if (!(Array.isArray(cell_info.channels) && cell_info.channels.length < 1 && Array.isArray(cell_info.accounts) && cell_info.accounts.length < 1 && Array.isArray(cell_info.campaigns) && cell_info.campaigns.length < 1 && Array.isArray(cell_info.adgroups) && cell_info.adgroups.length < 1 && Array.isArray(cell_info.ads) && cell_info.ads.length < 1)) {
                                let cell_report = await this.calls.createInternalCellReport(this.props.match.params.report, cells);
                                if (!this.state.websocket_init) {
                                    if (Array.isArray(cell_report.data)) {
                                        cell_report.data.map((item) => {
                                            if (item.id == cell_information.i) {
                                                cell_information.all_metrics = item.all_metrics;
                                                cell_information.data = item.data;
                                                cell_information.errors = item.errors;
                                            }
                                            if (abstract_cell && item.id == abstract_cell.i) {
                                                abstract_cell.data = item.data;
                                                abstract_cell.errors = item.errors;
                                            }
                                        })
                                    }
                                }
                            }

                        } catch (error) {
                            console.log(error);
                        }
                    }

                }

                //IF REGULAR
                if (!this.state.websocket_init || (option == "clone" && cell.celltype.value !== "chart")) {
                    if (abstract_cell) {
                        abstract_cell.loading = false;
                    }
                    cell_information.loading = false;
                }

                if (cell_information.i) {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (item.i === cell_information.i) {
                            item = cell_information;
                        }
                        return item;
                    })
                }

                if (abstract_cell && abstract_cell.i) {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (item.i === abstract_cell.i) {
                            item = abstract_cell;
                        }
                        return item;
                    })
                }

                //UPDATE TAB AND TABS
                this.state.tabs = this.state.tabs.map((item) => {
                    if (item.id == this.state.selected_tab.id) {
                        item = this.state.selected_tab;
                    }
                    return item;
                });

                this.setState({
                    tabs: this.state.tabs,
                    selected_tab: this.state.selected_tab
                });

            }

            function addCellToGrid(cell) {

                let cell_item = cell;
                let group = null;
                let group_coordinates = {};

                self.state.selected_tab.groups.map((item) => {
                    if (item.id === cell_item.group) {
                        group = item;
                    }
                })

                //UPDATE GROUP COORDINATES
                self.state.selected_tab.grid_data.filter((item) => {
                    return !item.abstract
                }).map((item) => {
                    if (group && item.group === group.id) {
                        let init_w = JSON.parse(JSON.stringify(item.w));
                        let init_h = JSON.parse(JSON.stringify(item.h));
                        for (let y = 0; y < init_h; y++) {
                            let init_y = JSON.parse(JSON.stringify(item.y));
                            init_y = init_y + y;
                            for (let x = 0; x < init_w; x++) {
                                let init_x = JSON.parse(JSON.stringify(item.x));
                                init_x = init_x + x;
                                group_coordinates[init_x + ":" + init_y] = true;
                            }
                        }
                    }
                })

                let start_x = 0;
                let start_y = 0;
                let columns = 7;
                let rows = (group.rows - 1);
                let free_spot = false;
                let checked_positions = [];

                for (let y = 0; y < rows; y++) {
                    let init_y = start_y + y;
                    for (let x = 0; x < columns; x++) {
                        let init_x = start_x + x;
                        checked_positions = [];
                        for (let h = 0; h < cell_item.h; h++) {
                            for (let w = 0; w < cell_item.w; w++) {
                                if (init_y + h < group.rows) {
                                    checked_positions.push(group_coordinates[(init_x + w) + ":" + (init_y + h)]);
                                }
                            }
                        }
                        if (checked_positions.filter((item) => { return !item }).length === checked_positions.length && checked_positions.length === (cell_item.w * cell_item.h)) {
                            free_spot = true;
                            cell_item.x = init_x;
                            cell_item.y = init_y;
                            break;
                        }
                    }
                    if (free_spot) {
                        break;
                    }
                }

                if (free_spot) {
                    return cell_item;
                } else {
                    return false;
                }
            }

        },
        update: async (cells) => {

            //REMOVE ALL ABSTRACT CELLS
            this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.filter((item) => {
                let keep = true;
                if (item.abstract && Array.isArray(cells)) {
                    cells.map((cell) => {
                        if (item.parent_cell == cell.i) {
                            keep = false;
                        }
                    });
                }
                return keep;
            });

            await this.promisedSetState({ selected_tab: this.state.selected_tab });

            this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                cells.map((cell) => {
                    if (item.i == cell.i) {
                        if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                            item.errors = [];
                            item.loading = true;
                        }
                    }
                });
                return item;
            });

            await this.promisedSetState({ selected_tab: this.state.selected_tab });

            let { cell_report, parsed_cells, failed_parse } = await this.cells.calculate(cells);

            if (!this.state.websocket_init) {
                if (Array.isArray(this.state.selected_tab.grid_data)) {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        item.loading = false;
                        item.selected = false;
                        if (cell_report && Array.isArray(cell_report.data)) {
                            cell_report.data.map((inner_item) => {
                                if (item.i == inner_item.id) {
                                    item.all_metrics = inner_item.all_metrics;
                                    item.data = inner_item.data;
                                    item.errors = inner_item.errors;
                                }
                            });
                        }
                        return item;
                    });
                }
            } else {
                if (Array.isArray(this.state.selected_tab.grid_data)) {
                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                        if (failed_parse && Array.isArray(failed_parse)) {
                            failed_parse.map((inner_item) => {
                                if (item.i == inner_item.id) {
                                    item.loading = false;
                                    item.selected = false;
                                }
                            });
                        }
                        return item;
                    });
                }
            }

            this.state.selected_tab.data_fetched = true;
            this.state.selected_tab.loading = false;
            this.state.selected_tab.part_loading = false;

            //UPDATE TAB AND TABS
            this.state.tabs = this.state.tabs.map((item) => {
                if (item.id == this.state.selected_tab.id) {
                    item = this.state.selected_tab;
                }
                return item;
            });

            this.setState({
                tabs: this.state.tabs,
                selected_tab: this.state.selected_tab
            });

        },
        report: (cell_information) => {

            //console.log(cell_information);

            let cell_info = {}

            cell_information.settings = cell_information.settings ? cell_information.settings : {};

            //PREVIEW
            if (cell_information.celltype && cell_information.celltype.value === "preview") {

                //PREPARE DATA TO FETCH REPORT FOR CELL
                cell_info = {
                    tags: Array.isArray(this.state.selected_tab.tags) ? this.state.selected_tab.tags : [],
                    only_active_data: this.state.selected_tab.only_active_data,
                    id: cell_information.i,
                    type: cell_information.celltype ? (cell_information.celltype.value == "chart" ? (cell_information.celltype.value + "_" + cell_information.chart.value) : cell_information.celltype.value) : null,
                    sub_type: cell_information.subType,
                    start_date: false,
                    end_date: false,
                    dimensions: {},
                    channels: cell_information.data_sources.channels ? this.state.channels.filter((item) => { return cell_information.data_sources.channels[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    accounts: cell_information.data_sources.accounts ? this.state.accounts.filter((item) => { return cell_information.data_sources.accounts[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    campaigns: [],
                    adgroups: [],
                    /*
                    ads: cell_information.data_sources.ads ? this.state.ads.filter((item) => { return cell_information.data_sources.ads[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, adgroup: item.adgroup, client: item.client }
                    }) : [],
                    */
                    ads: cell_information.data_sources.ads ? Object.keys(cell_information.data_sources.ads).map((key) => {
                        let item = cell_information.data_sources.ads[key];
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, adgroup: item.adgroup, client: item.client }
                    }) : [],
                    metrics: [],
                    information: {}
                }

                if (Array.isArray(cell_info.accounts) && cell_info.accounts.length > 0) {
                    let dimensions = { google: "ads", bing: "ad", facebook: "ads", tiktok: "ads", snapchat: "ads", linkedin: "ads" };
                    for (let channel in cell_information.channel_breakdowns) {
                        dimensions[channel] = null;
                        for (let dimension in cell_information.channel_breakdowns[channel]) {
                            dimensions[channel] = dimension;
                        }
                    }
                    cell_info.dimensions = dimensions;
                }

            }

            //CHECK IF NOT IMAGE OR TEXT
            if (cell_information.celltype && (cell_information.celltype.value === "table" || cell_information.celltype.value === "number" || cell_information.celltype.value === "chart")) {

                let dimensions = {};
                for (let channel in cell_information.channel_breakdowns) {
                    dimensions[channel] = null;
                    for (let dimension in cell_information.channel_breakdowns[channel]) {
                        dimensions[channel] = dimension;
                    }
                }

                let dimensions_filter = {};
                for (let channel in cell_information.channel_breakdowns) {
                    dimensions_filter[channel] = null;
                    for (let dimension in cell_information.channel_breakdowns[channel]) {
                        if (cell_information.channel_breakdowns[channel][dimension].filter) {
                            dimensions_filter[channel] = cell_information.channel_breakdowns[channel][dimension].filter;
                        }
                    }
                }

                let dimensions_filters = {};
                for (let channel in cell_information.channel_breakdowns) {
                    dimensions_filters[channel] = null;
                    for (let dimension in cell_information.channel_breakdowns[channel]) {
                        if (Array.isArray(cell_information.channel_breakdowns[channel][dimension].filters)) {
                            if (cell_information.channel_breakdowns[channel][dimension].filters.length > 0) {
                                dimensions_filters[channel] = cell_information.channel_breakdowns[channel][dimension].filters;
                            }
                        }
                    }
                }

                //PREPARE DATA TO FETCH REPORT FOR CELL
                cell_info = {
                    tags: Array.isArray(this.state.selected_tab.tags) ? this.state.selected_tab.tags : [],
                    only_active_data: this.state.selected_tab.only_active_data,
                    id: cell_information.i,
                    type: cell_information.celltype ? (cell_information.celltype.value == "chart" ? (cell_information.celltype.value + "_" + cell_information.chart.value) : cell_information.celltype.value) : null,
                    sub_type: cell_information.subType,
                    start_date: cell_information.daterange ? cell_information.daterange.start_date : false,
                    end_date: cell_information.daterange ? cell_information.daterange.end_date : false,
                    dimensions: dimensions,
                    dimensions_filter: dimensions_filter,
                    dimensions_filters: dimensions_filters,
                    channels: cell_information.data_sources.channels ? this.state.channels.filter((item) => { return cell_information.data_sources.channels[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    accounts: cell_information.data_sources.accounts ? this.state.accounts.filter((item) => { return cell_information.data_sources.accounts[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    /*
                    campaigns: cell_information.data_sources.campaigns ? this.state.campaigns.filter((item) => { return cell_information.data_sources.campaigns[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    */
                    campaigns: cell_information.data_sources.campaigns ? Object.keys(cell_information.data_sources.campaigns).map((key) => {
                        let item = cell_information.data_sources.campaigns[key];
                        return { id: item.id.toString(), name: item.name, channel: item.channel, client: item.client }
                    }) : [],
                    /*
                    adgroups: cell_information.data_sources.adgroups ? this.state.adgroups.filter((item) => { return cell_information.data_sources.adgroups[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, client: item.client }
                    }) : [],
                    */
                    adgroups: cell_information.data_sources.adgroups ? Object.keys(cell_information.data_sources.adgroups).map((key) => {
                        let item = cell_information.data_sources.adgroups[key];
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, client: item.client }
                    }) : [],
                    /*
                    ads: cell_information.data_sources.ads ? this.state.ads.filter((item) => { return cell_information.data_sources.ads[item.id] }).map((item) => {
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, adgroup: item.adgroup, client: item.client }
                    }) : [],
                    */
                    ads: cell_information.data_sources.ads ? Object.keys(cell_information.data_sources.ads).map((key) => {
                        let item = cell_information.data_sources.ads[key];
                        return { id: item.id.toString(), name: item.name, channel: item.channel, campaign: item.campaign, adgroup: item.adgroup, client: item.client }
                    }) : [],
                    metrics: cell_information.metrics ? Object.keys(cell_information.metrics).map((item) => {
                        if (cell_information.metrics[item].id) {
                            return { id: cell_information.metrics[item].id, name: cell_information.metrics[item].name }
                        } else {
                            return {
                                name: cell_information.metrics[item].name,
                                metrics: cell_information.metrics[item].metrics,
                                calculated: cell_information.metrics[item].calculated,
                                calculation: cell_information.metrics[item].calculation,
                                channels: cell_information.metrics[item].channels
                            };
                        }
                    }) : [],
                    information: {}
                };

                cell_info.information.overwrite_tags = cell_information.settings.overwrite_tags;
                cell_info.information.combine_matching_breakdowns = cell_information.settings.combine_matching_breakdowns;

                if (cell_information.settings && cell_information.settings.source_name) {
                    cell_info.information.source_name = cell_information.settings.source_name;
                }

                cell_info.information.groupBreakdowns = cell_information.settings.groupBreakdowns;
                if (cell_info.information.groupBreakdowns) {
                    cell_info.information.groupBreakdownsGroups = cell_information.settings.groupBreakdownsGroups;
                    cell_info.information.groupBreakdownsType = cell_information.settings.groupBreakdownsType;
                }

                if (cell_information.celltype && cell_information.celltype.value == "number") {
                    if (cell_information.settings) {
                        cell_info.information.customNumber = cell_information.settings.customNumber;
                        cell_info.information.customNumberValue = cell_information.settings.customNumberValue;
                    }
                    if (cell_information.settings && cell_information.settings.performance_target) {
                        cell_info.information.performance_target = cell_information.settings.performance_target;
                    }
                }

                if (cell_information.celltype && cell_information.celltype.value == "table") {
                    if (cell_information.settings && cell_information.settings.sortTable) {
                        cell_info.information.sortTable = cell_information.settings.sortTable;
                    }
                    cell_info.information.maxRowsEnabled = cell_information.settings.maxRowsEnabled;
                    if (cell_info.information.maxRowsEnabled) {
                        cell_info.information.maxRows = +cell_information.settings.maxRows;
                    }
                }

                if (cell_information.celltype && cell_information.celltype.value == "chart") {
                    if (cell_information.settings) {
                        cell_info.information.chart_numbers = cell_information.settings.chart_numbers
                        cell_info.information.chart_colors = cell_information.settings.chart_colors
                        cell_info.information.show_datasources = cell_information.settings.show_datasources
                        cell_info.information.chart_sort = cell_information.settings.chart_sort
                        cell_info.information.label_limit = cell_information.settings.label_limit
                        cell_info.information.limit_characters = cell_information.settings.limit_characters
                        cell_info.information.use_additional_color_scale = cell_information.settings.use_additional_color_scale
                        cell_info.information.use_default_color = cell_information.settings.use_default_color;
                        cell_info.information.default_color = cell_information.settings.default_color;
                    }

                    if (cell_information.settings.chart_setting) {
                        cell_info.information.type = cell_information.settings.chart_setting.value;
                    }
                    cell_info.information.color = {};
                    cell_info.information.metric_color = {};

                    //OLD
                    for (let level in cell_information.data_sources) {
                        for (let id in cell_information.data_sources[level]) {
                            if (cell_information.data_sources[level][id].metric_color) {
                                cell_info.information.metric_color[id] = cell_information.data_sources[level][id].metric_color;
                            }
                            if (cell_information.settings.color && cell_information.settings.color[id]) {
                                cell_info.information.color[id] = cell_information.settings.color[id];
                            } else {
                                cell_info.information.color[id] = cell_information.data_sources[level][id].hex ? cell_information.data_sources[level][id].hex : channelColor(cell_information.data_sources[level][id].channel);
                            }
                        }
                    }

                    //NEW
                    if (cell_information.settings && cell_information.settings.metric_color) {
                        cell_info.information.metric_color = cell_information.settings.metric_color;
                    }

                }

            }

            //ABSTRACT
            cell_info.abstract = cell_information.abstract;

            //COMPARE
            cell_info.compare_last_year = cell_information.compare_last_year;
            cell_info.compare_custom_date = cell_information.compare_custom_date;
            if (cell_info.compare_custom_date && cell_information.daterange && cell_information.daterange.compare_custom_date) {
                cell_info.compare_start_date = cell_information.daterange.compare_custom_date.start_date;
                cell_info.compare_end_date = cell_information.daterange.compare_custom_date.end_date;
            }

            function channelColor(channel) {
                let colors = {
                    facebook: "#3A5998",
                    google: "#4384F4",
                    adform: "#60C1A4",
                    bidtheatre: "#f48e22",
                    google_analytics: "#e8710a",
                    snapchat: "#FFFC00",
                    bing: "#0C8484",
                    tiktok: "#000000",
                    linkedin: "#0D65A1"
                }
                return colors[channel]
            }

            return cell_info;

        },
        calculate: async (cells, by_websocket = true) => {

            let parsed_cells = [];
            let failed_parse = [];

            for (let i = 0; i < cells.length; i++) {

                let abstract_comparison_cell = false;
                let cell = cells[i];

                let original_daterange = cell && cell.daterange ? JSON.parse(JSON.stringify(cell.daterange)) : null;

                //REMOVE ALL ABSTRACT CELLS
                if (cell.abstract) {
                    continue;
                }

                //PREP
                let cell_information = {};
                cell_information.group = cell.group;
                cell_information.x = cell.x;
                cell_information.y = cell.y;
                cell_information.w = cell.w;
                cell_information.h = cell.h;
                cell_information.i = cell.i;

                cell_information.x_desktop = cell.x_desktop;
                cell_information.y_desktop = cell.y_desktop;
                cell_information.w_desktop = cell.w_desktop;
                cell_information.h_desktop = cell.h_desktop;

                cell_information.x_mobile = cell.x_mobile;
                cell_information.y_mobile = cell.y_mobile;
                cell_information.w_mobile = cell.w_mobile;
                cell_information.h_mobile = cell.h_mobile;

                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {
                    if (cell.daterange && cell.daterange.compare_dates && cell.daterange.compare_dates.value === "enabled") {
                        abstract_comparison_cell = { i: null };
                    }
                }

                cell_information.settings = cell.settings;

                //MAKE SURE TO RESET BREAKDOWNS IF PERFORMANCE TRACKER
                if (cell.celltype && cell.celltype.id === "performance") {
                    cell.channel_breakdowns = {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bidtheatre": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } }
                    };
                }

                //TEXT CELL
                if (cell.celltype && cell.celltype.value === "text") {
                    cell_information.header = 'Text field';
                    cell_information.data = cell.data;
                    cell_information.celltype = cell.celltype;
                    cell_information.typeOfBlock = 'input';
                }

                //IMAGE CELL
                else if (cell.celltype && cell.celltype.value === "image") {
                    cell_information.header = 'screenshot';
                    cell_information.data = cell.data;
                    cell_information.celltype = cell.celltype;
                    cell_information.format = cell.format;
                    cell_information.typeOfBlock = 'img';
                }

                //PREVIEW CELL
                else if (cell.celltype && cell.celltype.value === "preview") {
                    cell_information.header = 'screenshot';
                    cell_information.data = cell.data;
                    cell_information.celltype = cell.celltype;
                    cell_information.loading = true;
                    cell_information.format = cell.format;
                    cell_information.data_sources = cell.data_sources;
                    cell_information.typeOfBlock = 'preview';
                }

                //NUMBER CELL
                else if (cell.celltype && cell.celltype.value === "number") {
                    cell_information.header = cell.settings ? cell.settings.title : "";
                    cell_information.data = cell.data;
                    cell_information.loading = true;
                    cell_information.typeOfBlock = 'number';
                    cell_information.channel_breakdowns = cell.channel_breakdowns;
                    cell_information.celltype = cell.celltype;
                    cell_information.data_sources = cell.data_sources;
                    cell_information.daterange = cell.daterange;
                    cell_information.metrics = cell.metrics;

                    if (cell.celltype && cell.celltype.id === "performance") {
                        cell_information.subType = "performance";
                    }

                }

                //CHART CELL
                else if (cell.celltype && cell.celltype.value === "chart") {
                    cell_information.data = 0;
                    cell_information.loading = true;
                    cell_information.typeOfBlock = 'chart';
                    cell_information.channel_breakdowns = cell.channel_breakdowns;
                    cell_information.celltype = cell.celltype;
                    cell_information.chart = cell.chart;
                    cell_information.data_sources = cell.data_sources;
                    cell_information.daterange = cell.daterange;
                    cell_information.metrics = cell.metrics;
                }

                //TABLE CELL
                else if (cell.celltype && cell.celltype.value === "table") {
                    cell_information.loading = true;
                    cell_information.typeOfBlock = 'table';
                    cell_information.channel_breakdowns = cell.channel_breakdowns;
                    cell_information.celltype = cell.celltype;
                    cell_information.data_sources = cell.data_sources;
                    cell_information.table_metrics_index = cell.table_metrics_index;
                    cell_information.daterange = cell.daterange;
                    cell_information.metrics = cell.metrics;
                    //cell_information.settings = cell.settings;
                    cell_information.remove_name = cell.remove_name;
                    cell_information.remove_id = cell.remove_id;
                    cell_information.remove_level = cell.remove_level;
                    cell_information.remove_breakdown_value = cell.remove_breakdown_value;
                    cell_information.remove_breakdown = cell.remove_breakdown;
                    cell_information.remove_channel = cell.remove_channel;
                    cell_information.remove_preview = cell.remove_preview;

                    //PARSE COLUMNS
                    cell_information.default_columns = [];
                    cell_information.default_columns = !cell_information.remove_preview ? cell_information.default_columns.concat([{ value: "preview", title: cell_information.settings && cell_information.settings.remove_preview_title && cell_information.settings.remove_preview_title !== "" ? cell_information.settings.remove_preview_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_channel ? cell_information.default_columns.concat([{ value: "channel", title: cell_information.settings && cell_information.settings.remove_channel_title && cell_information.settings.remove_channel_title !== "" ? cell_information.settings.remove_channel_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_level ? cell_information.default_columns.concat([{ value: "level", title: cell_information.settings && cell_information.settings.remove_level_title && cell_information.settings.remove_level_title !== "" ? cell_information.settings.remove_level_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_breakdown ? cell_information.default_columns.concat([{ value: "breakdown", title: cell_information.settings && cell_information.settings.remove_breakdown_title && cell_information.settings.remove_breakdown_title !== "" ? cell_information.settings.remove_breakdown_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_breakdown_value ? cell_information.default_columns.concat([{ value: "breakdown_value", title: cell_information.settings && cell_information.settings.remove_breakdown_value_title && cell_information.settings.remove_breakdown_value_title !== "" ? cell_information.settings.remove_breakdown_value_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_name ? cell_information.default_columns.concat([{ value: "name", title: cell_information.settings && cell_information.settings.remove_name_title && cell_information.settings.remove_name_title !== "" ? cell_information.settings.remove_name_title : null }]) : cell_information.default_columns;
                    cell_information.default_columns = !cell_information.remove_id ? cell_information.default_columns.concat([{ value: "id", title: cell_information.settings && cell_information.settings.remove_id_title && cell_information.settings.remove_id_title !== "" ? cell_information.settings.remove_id_title : null }]) : cell_information.default_columns;

                    cell_information.columns = [];
                    if (Array.isArray(cell_information.table_metrics_index)) {
                        cell_information.table_metrics_index.map((item) => {
                            cell_information.columns.push(item.name);
                        })
                    }
                }

                let abstract_cell = null;

                //COPY CELL AND MAKE INVISIBLE (ABSTRACT) CELL
                if (abstract_comparison_cell) {
                    if (abstract_comparison_cell.i) {
                        abstract_cell = JSON.parse(JSON.stringify(abstract_comparison_cell));
                    } else {
                        abstract_cell = JSON.parse(JSON.stringify(cell_information));
                    }
                    abstract_cell.abstract = true;
                    abstract_cell.parent_cell = cell_information.i;
                    abstract_cell.i = cell_information.i + "_abstract";

                    //COMPARE WITH A YEAR AGO
                    if (cell_information.daterange && cell_information.daterange.compare_last_year && cell_information.daterange.compare_last_year.value === "enabled") {
                        abstract_cell.compare_last_year = true;
                    }
                    if (cell_information.daterange && cell_information.daterange.compare_custom_date && cell_information.daterange.compare_custom_date.value === "enabled") {
                        abstract_cell.compare_custom_date = true;
                    }

                }

                if (cell.celltype && (cell.celltype.value === "preview" || cell.celltype.value === "table" || cell.celltype.value === "number" || cell.celltype.value === "chart")) {

                    //ABSTRACT CELL
                    if (abstract_cell && cell.celltype.value !== "preview") {
                        this.state.selected_tab.grid_data.push(abstract_cell);
                    }

                    //REGULAR CELL
                    let cell_info = this.cells.report(cell_information);

                    //SKIP IF NO DATASOURCE SELECTED
                    if (!(Array.isArray(cell_info.channels) && cell_info.channels.length < 1 && Array.isArray(cell_info.accounts) && cell_info.accounts.length < 1 && Array.isArray(cell_info.campaigns) && cell_info.campaigns.length < 1 && Array.isArray(cell_info.adgroups) && cell_info.adgroups.length < 1 && Array.isArray(cell_info.ads) && cell_info.ads.length < 1)) {

                        parsed_cells.push(cell_info);

                        //ABSTRACT CELL
                        if (abstract_cell && cell.celltype.value !== "preview") {
                            let abstract_cell_info = this.cells.report(abstract_cell);
                            parsed_cells.push(abstract_cell_info);
                        }

                    } else {
                        failed_parse.push(cell_info);
                    }
                }

            }

            //FETCH
            let cell_report = {};
            try {
                if (parsed_cells.length > 0) {
                    cell_report = await this.calls.createInternalCellReport(this.props.match.params.report, parsed_cells, by_websocket);
                }
            } catch (error) { }

            return {
                cell_report: cell_report,
                parsed_cells: parsed_cells,
                failed_parse: failed_parse,
            };

        }
    };

    information = {
        clientsCampaigns: () => {
            let self = this;
            return new Promise(function (resolve, reject) {
                console.log(self.state.all_clients);
                console.log(self.state.client);
                let data = self.state.all_clients.map((item) => {
                    let channels = self.state.client.channels.filter(channel => channel.client == item.id).map(item => {
                        return item.channel
                    });
                    return {
                        client: item.id,
                        channels: channels
                    }
                })
                self.calls.listClientsCampaigns(
                    encodeURIComponent(JSON.stringify(data))
                ).then((response) => {
                    let campaigns = [];
                    if (Array.isArray(response.data)) {
                        response.data.map((item) => {
                            if (item.data.status == 200) {
                                for (let channel in item.data.data) {
                                    if (channel == "custom_platforms") {
                                        for (let custom_channel in item.data.data[channel]) {
                                            if (Array.isArray(item.data.data[channel][custom_channel].campaigns)) {
                                                item.data.data[channel][custom_channel].campaigns.map((object) => {
                                                    object.channel = custom_channel;
                                                    object.client_id = item.client;
                                                    object.client = item.client;
                                                    object.type = "campaign";
                                                    campaigns.push(object);
                                                });
                                            }
                                        }
                                    } else {
                                        if (Array.isArray(item.data.data[channel])) {
                                            item.data.data[channel].map((object) => {
                                                object.channel = channel;
                                                object.client_id = item.client;
                                                object.client = item.client;
                                                object.type = "campaign";
                                                campaigns.push(object);
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                    self.setState({
                        campaigns: campaigns
                    }, () => {
                        resolve();
                    });
                    resolve();
                }, (error) => {
                    resolve();
                });
            });
        },
        campaigns: () => {
            let self = this;
            return new Promise(function (resolve, reject) {
                self.calls.getCampaignsInformation(
                    self.props.match.params.report
                ).then((response) => {
                    let campaigns = [];
                    for (let channel in response.data) {
                        if (Array.isArray(response.data[channel])) {
                            response.data[channel].map((object) => {
                                object.channel = channel;
                                object.type = "campaign";
                                campaigns.push(object);
                            });
                        }
                    }
                    self.setState({
                        campaigns: campaigns
                    }, () => {
                        resolve();
                    });
                }, (error) => {
                    resolve();
                });
            });
        },
        adgroups: (adgroup_calls) => {
            let self = this;
            return new Promise(function (resolve, reject) {

                //CHECK IF ADGROUPS ALREADY COLLECTED
                adgroup_calls = adgroup_calls.filter((item) => {
                    item.campaigns = item.campaigns.filter((campaign) => {
                        return self.state.adgroups.filter((adgroup) => { return campaign == adgroup.campaign }).length < 1;
                    });
                    return item.campaigns.length > 0
                });

                if (adgroup_calls.length > 0) {
                    self.setState({
                        campaigns: self.state.campaigns.map((item) => {
                            adgroup_calls.map((call) => {
                                if (call.campaigns.filter((id) => { return item.id == id }).length > 0) {
                                    item.loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {

                        let call_data = {};
                        adgroup_calls.forEach((item) => {
                            if (item.channel == "cm360") {
                                call_data[item.channel + "_placements"] = item.campaigns.map((campaign) => { return { campaign: campaign, client: item.client } });
                            } else {
                                call_data[item.channel + "_campaigns"] = item.campaigns.map((campaign) => { return { campaign: campaign, client: item.client } });
                            }
                        });

                        var query_str = "";
                        query_str = Object.keys(call_data).map(key => {
                            if (call_data[key].constructor === Array) {
                                var theArrSerialized = ''
                                for (let singleArrIndex of call_data[key]) {
                                    theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex.campaign + (singleArrIndex.client ? ("," + singleArrIndex.client) : "") + '&'
                                }
                                return theArrSerialized
                            }
                            else {
                                return key + '=' + call_data[key] + '&'
                            }
                        }).join('');

                        self.calls.getAdgroupsInformation(
                            self.props.match.params.report,
                            {},
                            query_str
                        ).then((response) => {
                            let data = { channels: {} };
                            for (let channel in response.data) {
                                data.channels[channel] = {
                                    adgroups: response.data[channel]
                                };
                            }
                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                adgroups: self.state.adgroups.concat(Object.keys(data.channels).map((channel) => {
                                    return data.channels[channel].adgroups.filter((adgroup) => {
                                        return adgroup.status !== "REMOVED";
                                    }).map((item) => {
                                        item.campaign = item.campaign_id;
                                        return item;
                                    });
                                }).flat()),
                                campaigns: self.state.campaigns.map((campaign) => {
                                    campaign.loading = false;
                                    if (data.channels[campaign.channel]) {
                                        campaign.adgroups = data.channels[campaign.channel].adgroups.filter((adgroup) => {
                                            return adgroup.status !== "REMOVED";
                                        }).filter((adgroup) => {
                                            return adgroup.campaign == campaign.id;
                                        }).map((adgroup) => {
                                            adgroup.campaign_name = campaign.name;
                                            adgroup.type = "adgroup";
                                            return adgroup;
                                        });
                                    }
                                    return campaign;
                                })
                            }, () => {
                                resolve();
                            });
                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            })
        },
        ads: (ad_calls) => {
            let self = this;
            return new Promise(function (resolve, reject) {

                //CHECK IF ADGROUPS ALREADY COLLECTED
                ad_calls = ad_calls.filter((item) => {
                    item.adgroups = item.adgroups.filter((adgroup) => {
                        return self.state.ads.filter((ad) => { return adgroup == ad.adgroup }).length < 1;
                    });
                    return item.adgroups.length > 0
                });

                if (ad_calls.length > 0) {

                    self.setState({
                        adgroups: self.state.adgroups.map((item) => {
                            ad_calls.map((call) => {
                                if (call.adgroups.filter((id) => { return item.id == id }).length > 0) {
                                    item.loading = true;
                                }
                            });
                            return item;
                        })
                    }, () => {

                        let call_data = {};
                        ad_calls.map((item) => {
                            if (item.channel == "cm360") {
                                call_data[item.channel + "_ads"] = item.adgroups.map((adgroup) => { return { adgroup: adgroup, client: item.client } });
                            } else {
                                call_data[item.channel + "_adgroups"] = item.adgroups.map((adgroup) => { return { adgroup: adgroup, client: item.client } });
                            }
                        });

                        var query_str = "";
                        query_str = Object.keys(call_data).map(key => {
                            if (call_data[key].constructor === Array) {
                                var theArrSerialized = ''
                                for (let singleArrIndex of call_data[key]) {
                                    theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex.adgroup + (singleArrIndex.client ? ("," + singleArrIndex.client) : "") + '&'
                                }
                                return theArrSerialized
                            }
                            else {
                                return key + '=' + call_data[key] + '&'
                            }
                        }).join('');

                        self.calls.getAdsInformation(
                            self.props.match.params.report,
                            {},
                            query_str
                        ).then((response) => {

                            let data = { channels: {} };
                            for (let channel in response.data) {
                                data.channels[channel] = {
                                    ads: response.data[channel]
                                };
                            }

                            Object.keys(data.channels).map((channel) => {
                                data.channels[channel].ads = data.channels[channel].ads.map((ad) => {
                                    self.state.campaigns.map((campaign) => {
                                        self.state.adgroups.map((adgroup) => {
                                            if ((adgroup.campaign == campaign.id && ad.adgroup_id == adgroup.id) || (channel === "cm360" && adgroup.id === ad.adgroup_id)) {
                                                ad.campaign_id = adgroup.campaign;
                                                ad.campaign = adgroup.campaign;
                                                ad.adgroup_id = adgroup.id;
                                                ad.adgroup = adgroup.id;
                                            }
                                        });
                                    });
                                    return ad;
                                })
                            });

                            self.setState({
                                tabs: self.state.tabs,
                                selected_tab: self.state.selected_tab,
                                ads: self.state.ads.concat(Object.keys(data.channels).map((channel) => {
                                    return data.channels[channel].ads.filter((ad) => {
                                        return ad.status !== "REMOVED";
                                    });
                                }).flat()),
                                adgroups: self.state.adgroups.map((adgroup) => {
                                    adgroup.loading = false;
                                    if (data.channels[adgroup.channel]) {
                                        adgroup.ads = data.channels[adgroup.channel].ads.filter((ad) => {
                                            return ad.status !== "REMOVED";
                                        }).map((item) => {
                                            item.adgroup = item.adgroup_id;
                                            return item;
                                        }).filter((ad) => {
                                            return ad.adgroup == adgroup.id;
                                        }).map((ad) => {
                                            ad.adgroup_name = adgroup.name;
                                            ad.type = "ad";
                                            return ad;
                                        });
                                    }
                                    return adgroup;
                                })
                            }, () => {
                                resolve();
                            });

                        }, (error) => {
                            resolve();
                        })
                    })
                } else {
                    resolve();
                }
            })
        }
    };

    calls = {
        createReport: (data, client) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + '/v3/adcredo/createReport?client=' + client;
            return apiRegister.call(options, url);
        },
        getReport: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getReport?report=" + id;
            return apiRegister.call(options, url);
        },
        updateReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateReport?report=" + data.id;
            return apiRegister.call(options, url);
        },
        getMetrics: (data = null) => {
            let queryString = data ? new URLSearchParams(data).toString() : null
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + `/v2/metrics?custom=true${queryString ? `&${queryString}` : ''}`;
            return apiRegister.call(options, url);
        },
        listClientsCampaigns: (queryString) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientsCampaigns?data=" + queryString;
            return apiRegister.call(options, url);
        },
        getCampaignsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?report=" + report + "&information=true&information_basic=true&" + (query_str || '');
            return apiRegister.call(options, url);
        },
        getAdgroupsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?report=" + report + "&information=true&information_basic=true&" + query_str;
            return apiRegister.call(options, url);
        },
        getAdsInformation: (report, data, query_str) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdsData?report=" + report + "&information=true&information_basic=true&" + query_str;
            return apiRegister.call(options, url);
        },
        createInternalCellReport: (report, data, by_websocket = true) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportHandler?report=" + report + (this.state.websocket_init && by_websocket ? "&websocket=true" : "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        tabLoading: () => {
            let loading = false;
            try {
                this.state.campaigns.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.campaigns && item.is_loading && this.state.selected_tab.data_sources.campaigns[item.id]) {
                        loading = true;
                    }
                });
                this.state.adgroups.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.adgroups && item.is_loading && this.state.selected_tab.data_sources.adgroups[item.id]) {
                        loading = true;
                    }
                });
                this.state.ads.map((item) => {
                    if (this.state.selected_tab.data_sources && this.state.selected_tab.data_sources.ads && item.is_loading && this.state.selected_tab.data_sources.ads[item.id]) {
                        loading = true;
                    }
                });
            } catch (e) {

            }
            return loading || (!this.state.selected_tab || (this.state.selected_tab && this.state.selected_tab.loading));
        },
        rowHeight: (page) => {
            let style_string = "";
            if (page.rows) {
                for (let i = 1; i < page.rows; i++) {
                    style_string = style_string + "100px ";
                }
            }
            return style_string;
        },
        translateCellCoordinatesToGrid: (cell) => {
            let x = 0;
            let y = 0;
            let w = 0;
            let h = 0;
            if ('x_desktop' in cell) {
                x = cell.x_desktop;
            } else {
                x = cell.x;
            }
            if ('y_desktop' in cell) {
                y = cell.y_desktop;
            } else {
                y = cell.y;
            }
            if ('w_desktop' in cell) {
                w = cell.w_desktop;
            } else {
                w = cell.w;
            }
            if ('h_desktop' in cell) {
                h = cell.h_desktop;
            } else {
                h = cell.h;
            }
            let stylingObject = {
                gridArea: (y + 1) + " / " + (x + 1) + " / span " + h + " / span " + w
            }
            return stylingObject;
        },
        translateCellPadding: (direction, cell, page) => {
            let x = 0;
            let y = 0;
            let h = 0;
            let w = 0;
            if ('x_desktop' in cell) {
                x = cell.x_desktop;
            } else {
                x = cell.x;
            }
            if ('y_desktop' in cell) {
                y = cell.y_desktop;
            } else {
                y = cell.y;
            }
            if ('h_desktop' in cell) {
                h = cell.h_desktop;
            } else {
                h = cell.h;
            }
            if ('h_desktop' in cell) {
                w = cell.w_desktop;
            } else {
                w = cell.w;
            }
            let rows = typeof page.rows == 'number' ? +page.rows : 0;
            if (y + 1 === 1 && direction == "top") {
                return " pt-4 ";
            } else if (x + 1 === 1 && direction == "left") {
                return " pl-4 ";
            } else if (((x + w >= 7) || (w == 8)) && direction == "right") {
                return " pr-4 ";
            } else if (y + h >= rows && direction == "bottom") {
                return " pb-4 ";
            } else {
                if (direction == "top") {
                    return " pt-2 ";
                } else if (direction == "bottom") {
                    return " pb-2 ";
                } else if (direction == "right") {
                    return " pr-2 ";
                } else if (direction == "left") {
                    return " pl-2 ";
                } else {
                    return "";
                }
            }
        },
        sameCellDatesInTab: (tab) => {
            let dates = [];
            if (Array.isArray(tab.grid_data)) {
                tab.grid_data.map((cell) => {
                    if (cell.daterange && !cell.abstract) {
                        let date_string = "";
                        date_string = cell.daterange.start_date + " -> " + cell.daterange.end_date;
                        if (!dates.includes(date_string)) {
                            dates.push(date_string);
                        }
                    }
                });
            }
            return dates.length == 1 ? dates[0] : null;
        }
    };

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ tabs }) => ({
            tabs: arrayMove(tabs, oldIndex, newIndex),
        }));
    };

    SortableList = SortableContainer(({ items }) => (
        <div className="flex flex-1 flex-row justify-start">
            {items.map((item, index) => {
                item.index = index;
                if (item) {
                    return (
                        <this.SortableItem
                            key={`item-${item.id}`}
                            index={index}
                            item={item}
                        />
                    )
                }
            })}
        </div>
    ));

    SortableItem = SortableElement(({ item }) => (
        <div
            style={item.id === this.state.selected_tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
            className={(item.id === this.state.selected_tab.id ? "bg-white" : "text-gray-700 bg-gray-50 shadow") + " mr-2 flex max-w-1/2 flex-1 h-12 items-center flex-row truncate px-2 text-sm font-medium rounded-t-lg cursor-pointer"}
        >
            <div className='transform scale-75'>
                <SwitchTailwind
                    value={!item.disabled ? true : false}
                    disabled={((this.state.user && this.state.user.userRole === "sales") || (this.state.master_template && !this.state.template)) ? true : false}
                    onSwitch={async () => {
                        item.disabled = !item.disabled;
                        await this.promisedSetState({
                            tabs: this.state.tabs
                        })
                    }}
                />
            </div>
            {
                //false &&
                <div onClick={async (e) => {
                    if (!this.state.loading_remove && this.state.selected_tab.id !== item.id) {
                        item.part_loading = true;
                        try {
                            this.refs.grid.disableGrid();
                        } catch (error) {
                        }
                        await this.promisedSetState({
                            tabs: this.state.tabs
                        });
                        this.state.tabs = this.state.tabs.map((tab) => {
                            if (tab.id == item.id) {
                                if (Array.isArray(tab.grid_data)) {
                                    tab.grid_data = tab.grid_data.map((cell) => {
                                        delete cell.selected;
                                        return cell;
                                    });
                                }
                            }
                            return tab;
                        });
                        await this.promisedSetState({
                            selected_tab: item,
                            tabs: this.state.tabs
                        });
                        try {
                            this.refs.grid.enableGrid();
                        } catch (error) {
                        }
                        this.tabs.update();
                    }
                }}
                    data-tip='' data-for={item.id + "_tab_hover"}
                    className={"relative font-medium h-full py-3 pl-1 flex flex-1 text-sm truncate " + (item.index !== 0 ? 'max-w-11/12' : '')}
                >
                    <div className="absolute w-full inline-flex z-20 truncate h-full pt-0.5">
                        {(item.title && item.title !== "") ? item.title : "..."}
                        {
                            this.renders.sameCellDatesInTab(item) &&
                            <div>
                                <div className="rounded-md ml-2 bg-gray-100 px-2 py-1 text-xxs">
                                    {this.renders.sameCellDatesInTab(item)}
                                </div>
                            </div>
                        }
                    </div>
                    <ReactTooltip effect='solid' id={item.id + "_tab_hover"}>
                        <div className="flex items-center justify-center">
                            {item.title}
                            {this.renders.sameCellDatesInTab(item) && <span style={{ paddingTop: "3px", paddingBottom: "2px" }} className="text-xxs ml-2 border px-2 rounded-md">{this.renders.sameCellDatesInTab(item)}</span>}
                        </div>
                    </ReactTooltip>
                </div>
            }
            {
                //false &&
                item.id === this.state.selected_tab.id &&
                <span className="-mb-1 absolute bg-white bottom-0 h-2 left-0 right-0"></span>
            }
            {
                //false &&
                this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                <Menu as="div" className="inline-block text-left">
                    <Menu.Button className={"flex justify-center cursor-pointer items-center h-8 w-8 rounded-md hover:text-purple-500 hover:bg-gray-50 focus:outline-none"}>
                        <CogIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="border absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            style={{ zIndex: 9999 }}
                        >
                            <div className="py-1">
                                {
                                    [
                                        { id: 1, name: "Edit tab", icon: PencilAltIcon },
                                        { id: 4, name: "Clone tab", icon: DuplicateIcon },
                                        { id: 2, name: "Update dates", icon: CalendarIcon },
                                        { id: 3, name: "Delete tab", icon: TrashIcon }
                                    ].filter((option) => {
                                        if (this.state.master_template && !this.state.template) {
                                            return option.id == 1;
                                        } else {
                                            if (item.id === 4) {
                                                return item.id === this.state.selected_tab.id && !(this.state.master_template && !this.state.template);
                                            } else if (item.id !== this.state.selected_tab.id) {
                                                return option.id == 3;
                                            } else {
                                                return true;
                                            }
                                        }
                                    }).map((option) => {
                                        return (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={async (e) => {
                                                            if (option.id === 1) {
                                                                this.setState({
                                                                    tab_open: item
                                                                });
                                                                /*
                                                                if (item.id === this.state.selected_tab.id) {
                                                                    item.edit_name = true;
                                                                    this.setState({
                                                                        tabs: this.state.tabs
                                                                    }, () => {
                                                                        setTimeout(() => {
                                                                            document.getElementById("tabName" + item.id).focus();
                                                                        }, 100);
                                                                    });
                                                                }
                                                                */
                                                            } else if (option.id === 2) {
                                                                if (item.id === this.state.selected_tab.id) {
                                                                    this.setState({
                                                                        slideinDateclosed: false
                                                                    })
                                                                }
                                                            } else if (option.id === 3) {
                                                                await this.promisedSetState({
                                                                    show_delete_warning: true,
                                                                    tab_to_delete: item
                                                                });
                                                            } else if (option.id === 4) {
                                                                if (item.id === this.state.selected_tab.id) {
                                                                    if (!this.renders.tabLoading() && !(this.state.master_template && !this.state.template)) {
                                                                        let cloned_tab = {};
                                                                        if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                                            if (!this.renders.tabLoading()) {

                                                                                let new_tab = {};
                                                                                let new_grid = [];

                                                                                this.state.selected_tab.grid_data.map((cell) => {
                                                                                    if (!cell.abstract) {

                                                                                        let new_item = {};
                                                                                        if (cell.typeOfBlock == "input" || cell.typeOfBlock == "img") {
                                                                                            new_item = JSON.parse(JSON.stringify(cell));
                                                                                        } else {
                                                                                            for (let key in cell) {
                                                                                                if (key !== "data") {
                                                                                                    new_item[key] = cell[key];
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        new_item = JSON.parse(JSON.stringify(new_item));
                                                                                        new_item.data = cell.data;
                                                                                        new_item.i = 'n' + Date.now() + Math.floor(Math.random() * 1000);
                                                                                        delete new_item.selected;

                                                                                        //CLONE ALL ABSTRACT AND CONNECT TO NEW PARENT CORRECT CELL
                                                                                        let abstract_cell = null;
                                                                                        this.state.selected_tab.grid_data.filter((inner_cell) => {
                                                                                            return inner_cell.abstract;
                                                                                        }).map((inner_cell) => {
                                                                                            if (inner_cell.parent_cell && inner_cell.parent_cell === cell.i && !abstract_cell) {
                                                                                                abstract_cell = {};
                                                                                                if (inner_cell.typeOfBlock == "input" || inner_cell.typeOfBlock == "img") {
                                                                                                    abstract_cell = JSON.parse(JSON.stringify(inner_cell));
                                                                                                } else {
                                                                                                    for (let key in inner_cell) {
                                                                                                        if (key !== "data") {
                                                                                                            abstract_cell[key] = inner_cell[key];
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                abstract_cell = JSON.parse(JSON.stringify(abstract_cell));
                                                                                                abstract_cell.i = new_item.i + "_abstract";
                                                                                                abstract_cell.data = inner_cell.data;
                                                                                                abstract_cell.parent_cell = new_item.i;
                                                                                            }
                                                                                        });
                                                                                        if (abstract_cell) {
                                                                                            new_grid.push(abstract_cell);
                                                                                        }
                                                                                        new_item.loading = false;
                                                                                        new_grid.push(new_item);

                                                                                    }
                                                                                });

                                                                                let temp_grid = this.state.selected_tab.grid_data;
                                                                                delete this.state.selected_tab.grid_data;

                                                                                new_tab = JSON.parse(JSON.stringify(this.state.selected_tab));
                                                                                new_tab.grid_data = new_grid;
                                                                                new_tab.id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                new_tab.title += " clone";

                                                                                this.state.selected_tab.grid_data = temp_grid;
                                                                                this.state.tabs.push(new_tab);
                                                                                cloned_tab = new_tab;

                                                                                this.setState({
                                                                                    tabs: this.state.tabs,
                                                                                    selected_tab: cloned_tab
                                                                                });

                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className={cn(
                                                            active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer justify-between items-center hover:text-purple-500'
                                                        )}
                                                    >
                                                        <div>
                                                            {option.name}
                                                        </div>
                                                        <div>
                                                            <option.icon className="h-5 w-5" />
                                                        </div>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            }
        </div>
    ));

    render() {
        return (
            <div className="relative">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative truncate">
                            <div className="text-2xl font-bold pr-6 truncate">
                                {this.state.report_name}
                            </div>
                        </div>
                        <div className="flex">
                            {
                                this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? [] : [
                                    {
                                        text: "Save " + (this.state.template ? "template" : "report"),
                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                        icon: SaveIcon,
                                        value: "save",
                                        loading: this.state.loading_save,
                                        show: !this.state.loading && this.props.match.params.report
                                    }
                                ].map((item) => {
                                    return (
                                        <button
                                            onClick={() => {
                                                if (item.value === "save") {
                                                    if (this.state.template) {
                                                        this.functions.updateTemplate();
                                                    } else {
                                                        this.functions.updateReport();
                                                    }
                                                }
                                            }}
                                            className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            <span>{item.text}</span>
                                            {
                                                item.icon &&
                                                <item.icon className="ml-2 h-5 w-5" />
                                            }
                                            {
                                                item.loading &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    )
                                })
                            }
                            <Menu as="div" className="ml-2">
                                <div>
                                    <Menu.Button
                                        onClick={() => {

                                        }}
                                        className={((this.state.loading_save || this.state.loading || this.renders.tabLoading()) ? "cursor-not-allowed bg-gray-100 text-gray-400" : "bg-purple-500 hover:bg-purple-600") + " focus:ring-purple-500 text-white inline-flex py-0 items-center justify-center shadow relative rounded-md h-10 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                    >
                                        <div className="flex h-full px-4 justify-center items-center flex-1 border-r">
                                            <span>Options</span>
                                        </div>
                                        <div className="px-4">
                                            <ChevronDownIcon className="w-4" aria-hidden="true" />
                                        </div>
                                        {
                                            (this.state.loading_save || this.state.loading_ppt || this.state.loading_pdf || this.state.loading_create || this.state.loading_create) &&
                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items style={{ right: "15px", zIndex: 999999 }} className={`border absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                        <div className="py-1">
                                            {
                                                (this.state.user && this.state.user.userRole === "sales" ? [
                                                    {
                                                        text: "Open external",
                                                        icon: ExternalLinkIcon,
                                                        value: "open_external",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: false,
                                                        show: this.props.match.params.report && this.state.report_public && !this.state.loading
                                                    },
                                                    {
                                                        text: "Export .pptx",
                                                        icon: PresentationChartBarIcon,
                                                        value: "export_pptx",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: this.state.loading_ppt,
                                                        show: !this.state.loading && !this.state.template
                                                    },
                                                    {
                                                        text: "Export .pdf",
                                                        icon: PresentationChartBarIcon,
                                                        value: "export_pdf",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: this.state.loading_pdf,
                                                        show: !this.state.loading && !this.state.template
                                                    },
                                                ] : [
                                                    {
                                                        text: "Settings",
                                                        icon: CogIcon,
                                                        value: "settings",
                                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                                        loading: false,
                                                        show: !this.state.loading && this.props.match.params.report
                                                    },
                                                    {
                                                        text: "Open external",
                                                        icon: ExternalLinkIcon,
                                                        value: "open_external",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: false,
                                                        show: this.props.match.params.report && this.state.report_public && !this.state.loading
                                                    },
                                                    /*
                                                    {
                                                        text: "Save " + (this.state.template ? "template" : "report"),
                                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                                        icon: SaveIcon,
                                                        value: "save",
                                                        loading: this.state.loading_save,
                                                        show: !this.state.loading && this.props.match.params.report
                                                    },
                                                    */
                                                    {
                                                        text: "Create report",
                                                        icon: DocumentReportIcon,
                                                        value: "create_report",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: this.state.loading_create,
                                                        show: !this.state.loading && (this.state.template || !this.props.match.params.report)
                                                    },
                                                    {
                                                        text: "Create template",
                                                        icon: BookOpenIcon,
                                                        value: "create_template",
                                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                                        loading: this.state.loading_create,
                                                        show: !this.state.loading && !this.state.template
                                                    },
                                                    {
                                                        text: "Export .pptx",
                                                        icon: PresentationChartBarIcon,
                                                        value: "export_pptx",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: this.state.loading_ppt,
                                                        show: !this.state.loading && !this.state.template
                                                    },
                                                    {
                                                        text: "Export .pdf",
                                                        icon: PresentationChartBarIcon,
                                                        value: "export_pdf",
                                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                                        loading: this.state.loading_pdf,
                                                        show: !this.state.loading && !this.state.template
                                                    },
                                                ].filter((item) => {
                                                    if (this.state.master_template && this.state.template) {
                                                        return item.value !== "create_report"
                                                    } else {
                                                        return true;
                                                    }
                                                })).map((option) => {
                                                    return (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <div
                                                                    onClick={async () => {
                                                                        let type = option.value;
                                                                        if (type === "open_external") {
                                                                            if (window.location.hostname === 'localhost') {
                                                                                window.open("http://localhost:5001/" + "advanced" + "/report/" + this.props.match.params.report);
                                                                            } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                window.open("https://app.adcredo.io/" + "advanced" + "/report/" + this.props.match.params.report);
                                                                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                window.open("https://dev.adcredo.io/" + "advanced" + "/report/" + this.props.match.params.report);
                                                                            }
                                                                        } else if (type === "settings") {
                                                                            await this.promisedSetState({
                                                                                settings_open: true
                                                                            });
                                                                        } else if (type === "save") {
                                                                            if (this.state.template) {
                                                                                this.functions.updateTemplate();
                                                                            } else {
                                                                                this.functions.updateReport();
                                                                            }
                                                                        } else if (type === "create_report") {
                                                                            await this.promisedSetState({
                                                                                create_report: true
                                                                            })
                                                                        } else if (type === "create_template") {
                                                                            await this.promisedSetState({
                                                                                create_template: true
                                                                            })
                                                                        } else if (type === "export_pptx") {
                                                                            await this.functions.createPptx();
                                                                        } else if (type === "export_pdf") {
                                                                            await this.functions.createPdf();
                                                                        }
                                                                    }}
                                                                    className={cn(
                                                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                        'px-4 py-2 text-xms relative flex flex-row cursor-pointer font-medium',
                                                                        //(option == "Claim order" || option == "Assign to order") ? 'bg-purple-100 text-purple-500' : '',
                                                                        //(option == "Delete") ? 'bg-red-100 text-red-500' : ''
                                                                    )}
                                                                >
                                                                    {option.text}
                                                                    <div className="flex flex-1"></div>
                                                                    {
                                                                        option.icon &&
                                                                        <option.icon className="w-5" />
                                                                    }
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                            {
                                false &&
                                (this.state.user && this.state.user.userRole === "sales" ? [
                                    {
                                        text: "Open external",
                                        icon: ExternalLinkIcon,
                                        value: "open_external",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: false,
                                        show: this.props.match.params.report && this.state.report_public && !this.state.loading
                                    },
                                    {
                                        text: "Export .pptx",
                                        icon: PresentationChartBarIcon,
                                        value: "export_pptx",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: this.state.loading_ppt,
                                        show: !this.state.loading && !this.state.template
                                    },
                                    {
                                        text: "Export .pdf",
                                        icon: PresentationChartBarIcon,
                                        value: "export_pdf",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: this.state.loading_pdf,
                                        show: !this.state.loading && !this.state.template
                                    },
                                ] : [
                                    {
                                        text: "Settings",
                                        icon: CogIcon,
                                        value: "settings",
                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                        loading: false,
                                        show: !this.state.loading && this.props.match.params.report
                                    },
                                    {
                                        text: "Open external",
                                        icon: ExternalLinkIcon,
                                        value: "open_external",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: false,
                                        show: this.props.match.params.report && this.state.report_public && !this.state.loading
                                    },
                                    {
                                        text: "Save " + (this.state.template ? "template" : "report"),
                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                        icon: SaveIcon,
                                        value: "save",
                                        loading: this.state.loading_save,
                                        show: !this.state.loading && this.props.match.params.report
                                    },
                                    {
                                        text: "Create report",
                                        icon: DocumentReportIcon,
                                        value: "create_report",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: this.state.loading_create,
                                        show: !this.state.loading && (this.state.template || !this.props.match.params.report)
                                    },
                                    {
                                        text: "Create template",
                                        icon: BookOpenIcon,
                                        value: "create_template",
                                        disabled: (this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)),
                                        loading: this.state.loading_create,
                                        show: !this.state.loading && !this.state.template
                                    },
                                    {
                                        text: "Export .pptx",
                                        icon: PresentationChartBarIcon,
                                        value: "export_pptx",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: this.state.loading_ppt,
                                        show: !this.state.loading && !this.state.template
                                    },
                                    {
                                        text: "Export .pdf",
                                        icon: PresentationChartBarIcon,
                                        value: "export_pdf",
                                        disabled: (this.state.loading || this.renders.tabLoading()),
                                        loading: this.state.loading_pdf,
                                        show: !this.state.loading && !this.state.template
                                    },
                                ].filter((item) => {
                                    if (this.state.master_template && this.state.template) {
                                        return item.value !== "create_report"
                                    } else {
                                        return true;
                                    }
                                })).map((item) => {
                                    return (
                                        <button
                                            onClick={async () => {
                                                let type = item.value;
                                                if (type === "open_external") {
                                                    if (window.location.hostname === 'localhost') {
                                                        window.open("http://localhost:5001/" + "advanced" + "/report/" + this.props.match.params.report);
                                                    } else if (window.location.hostname === 'app.adcredo.io') {
                                                        window.open("https://app.adcredo.io/" + "advanced" + "/report/" + this.props.match.params.report);
                                                    } else if (window.location.hostname === 'dev.adcredo.io') {
                                                        window.open("https://dev.adcredo.io/" + "advanced" + "/report/" + this.props.match.params.report);
                                                    }
                                                } else if (type === "settings") {
                                                    await this.promisedSetState({
                                                        settings_open: true
                                                    });
                                                } else if (type === "save") {
                                                    if (this.state.template) {
                                                        this.functions.updateTemplate();
                                                    } else {
                                                        this.functions.updateReport();
                                                    }
                                                } else if (type === "create_report") {
                                                    await this.promisedSetState({
                                                        create_report: true
                                                    })
                                                } else if (type === "create_template") {
                                                    await this.promisedSetState({
                                                        create_template: true
                                                    })
                                                } else if (type === "export_pptx") {
                                                    await this.functions.createPptx();
                                                } else if (type === "export_pdf") {
                                                    await this.functions.createPdf();
                                                }
                                            }}
                                            className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            <span>{item.text}</span>
                                            {
                                                item.icon &&
                                                <item.icon className="ml-2 h-5 w-5" />
                                            }
                                            {
                                                item.loading &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                <WizardModal
                    open={this.state.create_template ? true : false}
                    icon={BookOpenIcon}
                    title={"Create template"}
                    text={"Add title for new template"}
                    button={"Create"}
                    input={this.state.report_name}
                    onInput={(value) => {
                        this.setState({
                            report_name: value
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            create_template: false
                        })
                    }}
                    onCreate={() => {
                        this.functions.createTemplate();
                    }}
                />

                <WizardModal
                    open={this.state.create_report ? true : false}
                    icon={DocumentReportIcon}
                    title={"Create report"}
                    text={"Add title for new report"}
                    button={"Create"}
                    input={this.state.report_name}
                    onInput={(value) => {
                        this.setState({
                            report_name: value
                        });
                    }}
                    onClose={() => {
                        this.setState({
                            create_report: false
                        })
                    }}
                    onCreate={() => {
                        this.functions.createReport();
                    }}
                />

                <SuccessModal
                    open={this.state.ppxt_success ? true : false}
                    title={"Success"}
                    text={"Check your download folder"}
                    warningText={this.state.ppxt_text_success ? this.state.ppxt_text_success : null}
                    button={"Ok"}
                    onClose={async () => {
                        await this.promisedSetState({
                            ppxt_success: false,
                        })
                        await this.promisedSetState({
                            ppxt_text_success: null
                        })
                    }}
                />

                <SuccessModal
                    open={this.state.pdf_success ? true : false}
                    title={"Success"}
                    text={"Check your download folder"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            pdf_success: false
                        })
                    }}
                />

                <SuccessModal
                    open={this.state.created_success ? true : false}
                    title={"Success"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            created_success: false
                        })
                    }}
                />

                <SuccessModal
                    open={this.state.saved_success ? true : false}
                    title={"Saved"}
                    button={"Ok"}
                    onClose={() => {
                        this.setState({
                            saved_success: false
                        })
                    }}
                />

                <WarningModalTailwind
                    open={this.state.show_delete_warning ? true : false}
                    title={"Delete tab"}
                    description={'Are you sure you want to delete this tab? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Delete"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({
                            show_delete_warning: false
                        });
                    }}
                    onSubmit={() => {
                        if (this.state.show_delete_warning && this.state.tab_to_delete) {
                            let tab_index = 0;
                            let new_selected_tab = {};
                            this.state.tabs = this.state.tabs.filter((tab, index) => {
                                if (tab.id == this.state.tab_to_delete.id) {
                                    tab_index = index;
                                }
                                return tab.id != this.state.tab_to_delete.id;
                            })
                            if (this.state.selected_tab.id == this.state.tab_to_delete.id) {
                                if (this.state.tabs.length > 1 && tab_index !== 0) {
                                    new_selected_tab = this.state.tabs[tab_index - 1];
                                } else {
                                    new_selected_tab = this.state.tabs[0];
                                }
                            } else {
                                new_selected_tab = this.state.selected_tab
                            }
                            this.promisedSetState({
                                selected_tab: new_selected_tab,
                                tabs: this.state.tabs,
                                show_delete_warning: false,
                                tab_to_delete: null
                            }).then(() => {
                                this.tabs.update();
                            });
                        }
                    }}
                />

                <SweetAlert
                    show={this.state.cell_modal}
                    title="Not enough space"
                    type="warning"
                    text={"Remove cells or add more rows to fit this cell"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            cell_modal: false
                        })
                    }}
                />

                <SlideoutTailwind
                    open={this.state.tab_open ? true : false}
                    // open={true}
                    title={"Edit tab"}
                    submitButtonText={"Save"}
                    //mediumPlus={true}
                    noPadding={true}
                    secondaryButton={false}
                    onCancel={() => {
                        this.promisedSetState({ tab_open: false });
                    }}
                    onSubmit={async () => {
                        await this.refs.ReportTabEdit.functions.update();
                        this.promisedSetState({ tab_open: false });
                    }}
                >
                    {
                        this.state.tab_open &&
                        <ReportTabEdit
                            ref="ReportTabEdit"
                            report={{ id: this.props.match.params.report }}
                            tab={this.state.tab_open}
                            masterTemplate={this.state.master_template}
                            template={this.state.template}
                            onSave={async (tab) => {
                                this.state.tabs = this.state.tabs.map((item) => {
                                    if (item.id == tab.id) {
                                        item = tab;
                                        this.state.selected_tab = tab;
                                    }
                                    return item;
                                });
                                await this.promisedSetState({
                                    tabs: this.state.tabs,
                                    selected_tab: this.state.selected_tab
                                });
                                this.functions.updateReport();
                            }}
                        />
                    }
                </SlideoutTailwind>

                <SlideoutTailwind
                    open={this.state.settings_open}
                    // open={true}
                    title={"External report settings"}
                    submitButtonText={"Save"}
                    mediumPlus={true}
                    noPadding={true}
                    secondaryButton={false}
                    onCancel={() => {
                        this.promisedSetState({ settings_open: false, updated_settings: {} });
                    }}
                    onSubmit={async () => {
                        await this.refs.ExternalReportSettings.functions.update();
                        this.promisedSetState({ settings_open: false });
                    }}
                >
                    <ExternalReportSettings
                        ref="ExternalReportSettings"
                        reportId={this.props.match.params.report}
                        masterTemplate={this.state.master_template}
                        template={this.state.template}
                        title={this.state.report_title}
                        description={JSON.parse(JSON.stringify(this.state.report_description))}
                        logo={JSON.parse(JSON.stringify(this.state.report_logo))}
                        public={JSON.parse(JSON.stringify(this.state.report_public))}
                        background_color={JSON.parse(JSON.stringify(this.state.report_background_color))}
                        shadows={JSON.parse(JSON.stringify(this.state.report_shadows))}
                        datelock={JSON.parse(JSON.stringify(this.state.report_datelock))}
                        pdfDownload={JSON.parse(JSON.stringify(this.state.report_pdfdownload))}
                        preSync={JSON.parse(JSON.stringify(this.state.report_presync))}
                        pageSections={JSON.parse(JSON.stringify(this.state.report_pagesections))}
                        transparent_cells={JSON.parse(JSON.stringify(this.state.report_transparent_cells))}
                        navbar_color={JSON.parse(JSON.stringify(this.state.report_navbar_color))}
                        accent_color={JSON.parse(JSON.stringify(this.state.report_accent_color))}
                        font_color={JSON.parse(JSON.stringify(this.state.report_font_color))}
                        logo_size={JSON.parse(JSON.stringify(this.state.report_logo_size))}
                        public_password={JSON.parse(JSON.stringify(this.state.report_public_password))}
                        password={JSON.parse(JSON.stringify(this.state.report_password))}
                        inherit_styles={JSON.parse(JSON.stringify(this.state.report_inherit_styles))}
                        footerSettings={JSON.parse(JSON.stringify(this.state.report_footerSettings))}
                        emailSettings={JSON.parse(JSON.stringify(this.state.report_emailSettings))}
                        disable_comparison={JSON.parse(JSON.stringify(this.state.report_disable_comparison))}
                        professional={true}
                        onSave={async (value) => {
                            await this.promisedSetState({
                                report_title: value.title,
                                report_description: value.description,
                                report_logo: value.logo,
                                report_public: value.public,
                                report_background_color: value.background_color,
                                report_shadows: value.shadows,
                                report_datelock: value.datelock,
                                report_pdfdownload: value.pdfDownload,
                                report_presync: value.preSync,
                                report_pagesections: value.pageSections,
                                report_transparent_cells: value.transparent_cells,
                                report_navbar_color: value.navbar_color,
                                report_accent_color: value.accent_color,
                                report_font_color: value.font_color,
                                report_logo_size: value.logo_size,
                                report_public_password: value.public_password,
                                report_password: value.password,
                                report_disable_comparison: value.disable_comparison,
                                report_inherit_styles: value.inherit_styles,
                                report_footerSettings: value.footerSettings,
                                report_emailSettings: value.emailSettings
                            })
                            this.functions.updateReport()
                        }}
                    />
                </SlideoutTailwind>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading report ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 relative w-full">
                        <Fragment>
                            {
                                this.state.working_users && this.state.working_users.length > 1 && !this.state.hide_working_users &&
                                <div className="relative mb-2" style={{ height: '100%' }}>
                                    <div
                                        className="relative text-sm font-medium border-1.5 py-3 items-center px-4 border-red-500 text-red-500 bg-red-100 rounded-md">
                                        <div className="flex  justify-center">
                                            <ExclamationCircleIcon
                                                style={{ minWidth: '20px' }}
                                                className="w-5" />
                                            <span className="ml-2">
                                                Several people are working on the report. Possible data conflicts:
                                                {
                                                    this.state.working_users.map((email, index) => {
                                                        return (
                                                            <span className="font-bold">{index > 0 ? ", " : " "}{email}</span>
                                                        )
                                                    })
                                                }
                                            </span>
                                            <XIcon
                                                style={{ minWidth: '20px', right: '15px' }}
                                                className="w-5 cursor-pointer absolute"
                                                onClick={() => {
                                                    this.setState({
                                                        hide_working_users: true
                                                    })
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.error &&
                                <div className="relative mb-2" style={{ height: '100%' }}>
                                    <div
                                        className="relative text-sm font-medium border-1.5 py-3 items-center px-4 border-red-500 text-red-500 bg-red-100 rounded-md">
                                        <div className="flex  justify-center">
                                            <ExclamationCircleIcon
                                                style={{ minWidth: '20px' }}
                                                className="w-5" />
                                            <span className="ml-2">
                                                Something went wrong, please try again later.
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            }
                            <div className="flex w-full">
                                <this.SortableList
                                    items={this.state.tabs}
                                    onSortEnd={this.onSortEnd}
                                    axis="x"
                                    distance={1}
                                />
                                {
                                    this.state.user && (this.state.user.userRole !== "sales" || !this.state.user.userRole) &&
                                    <div className="relative w-32 h-full" onClick={() => {
                                        if (!this.renders.tabLoading() && !(this.state.master_template && !this.state.template)) {
                                            if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                if (!this.renders.tabLoading()) {
                                                    let new_tab = {
                                                        loading: !this.state.premium,
                                                        init: true,
                                                        id: Math.floor((Math.random() * 9999999999) + 1),
                                                        title: 'Tab ' + (this.state.tabs.length + 1),
                                                        editMode: false,
                                                        premium: true,
                                                        grid_data: [],
                                                        backup: '',
                                                        groups: [{
                                                            id: 1,
                                                            name: "",
                                                            rows: 6,
                                                            layout: [],
                                                            index: 0,
                                                            items: [],
                                                            coordinates: {}
                                                        }],
                                                        data_sources: { campaigns: {}, adgroups: {}, ads: {} },
                                                        channel_breakdowns: {
                                                            "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                            "google_search_console": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                                                        },
                                                        metrics: {},
                                                        daterange: {}
                                                    }
                                                    this.state.tabs.push(new_tab);
                                                    this.setState({
                                                        tabs: this.state.tabs,
                                                        selected_tab: new_tab,
                                                        selected_cell: null,
                                                        slideinclosed: false
                                                    }, () => {
                                                        if (!this.state.slideinclosed && this.state.selected_tab.id) {
                                                            this.setState({
                                                                campaigns: this.state.campaigns.map((item) => {
                                                                    item.open = false;
                                                                    return item;
                                                                }),
                                                                adgroups: this.state.adgroups.map((item) => {
                                                                    item.open = false;
                                                                    return item;
                                                                })
                                                            }, () => {
                                                                this.refs.child.functions.initiate(
                                                                    this.state.selected_tab.data_sources.channels,
                                                                    this.state.selected_tab.data_sources.accounts,
                                                                    this.state.selected_tab.data_sources.campaigns,
                                                                    this.state.selected_tab.data_sources.adgroups,
                                                                    this.state.selected_tab.data_sources.ads
                                                                );
                                                            });
                                                        }
                                                    })

                                                }
                                            }
                                        }
                                    }}>
                                        <div
                                            className={(this.renders.tabLoading() ? "cursor-not-allowed " : "cursor-pointer ") + " close truncate h-12 px-4 hover:text-purple-800 flex items-center bg-white font-medium justify-center rounded-b-none text-sm rounded-md transition-all duration-200 text-purple-500"}>
                                            <span className="mr-2">New tab</span>
                                            <PlusIcon className="h-5" />
                                            {
                                                this.state.master_template && !this.state.template &&
                                                <div className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="bg-white shadow-xl rounded-t-none rounded-md relative">
                                {
                                    this.state.selected_tab &&
                                    this.state.selected_tab.id &&
                                    this.state.premium &&
                                    Array.isArray(this.state.selected_tab.grid_data) &&
                                    this.state.selected_tab.id !== "settings" &&
                                    <AdvancedReportGrid
                                        enableSaveButton={!((this.state.loading || this.renders.tabLoading() || (this.state.master_template && !this.state.template)))}
                                        loadingSave={this.state.loading_save}
                                        ref="grid"
                                        masterTemplate={this.state.master_template && !this.state.template}
                                        scale={this.state.master_template}
                                        template={this.state.template}
                                        reportBackgroundEnabled={this.state.report_public}
                                        reportBackgroundColor={this.state.report_background_color}
                                        campaigns={this.state.campaigns}
                                        adgroups={this.state.adgroups}
                                        ads={this.state.ads}
                                        data={this.state.selected_tab.grid_data}
                                        groups={this.state.selected_tab.groups ? this.state.selected_tab.groups : []}
                                        tabLoading={this.renders.tabLoading()}
                                        transparent_cells={this.state.report_transparent_cells}
                                        updateDates={async (page) => {
                                            if (page) {
                                                await this.promisedSetState({
                                                    updateDatesPage: page
                                                });
                                            }
                                            await this.promisedSetState({
                                                slideinDateclosed: false
                                            });
                                        }}
                                        selectAllCells={(page, value) => {
                                            if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                                                    if (item.group == page.id) {
                                                        item.selected = value;
                                                        try {
                                                            var select = document.getElementById("select_" + item.i);
                                                            select.style.opacity = value ? 100 : 0;
                                                        } catch (error) {
                                                        }
                                                    }
                                                    return item;
                                                });
                                                this.setState({
                                                    selected_tab: this.state.selected_tab
                                                });
                                            }
                                        }}
                                        editSelected={() => {
                                            this.setState({
                                                slideinDatasourceclosed: false
                                            })
                                        }}
                                        saveReport={() => {
                                            if (!(this.state.master_template && !this.state.template)) {
                                                if (this.state.template) {
                                                    this.functions.updateTemplate();
                                                } else {
                                                    this.functions.updateReport();
                                                }
                                            }
                                        }}
                                        addCell={(group) => {
                                            if (!this.renders.tabLoading()) {
                                                this.setState({
                                                    slideinclosed: false,
                                                    selected_cell: false
                                                }, () => {
                                                    if (!this.state.slideinclosed && this.state.selected_tab.id) {
                                                        let data_sources = {};
                                                        if (this.state.premium) {
                                                            data_sources = this.state.selected_cell ? this.state.selected_cell.data_sources : {};
                                                        } else {
                                                            data_sources = this.state.selected_tab.data_sources;
                                                        }
                                                        this.setState({
                                                            campaigns: this.state.campaigns.map((item) => {
                                                                item.open = false;
                                                                return item;
                                                            }),
                                                            adgroups: this.state.adgroups.map((item) => {
                                                                item.open = false;
                                                                return item;
                                                            })
                                                        }, () => {
                                                            this.refs.child.functions.initiate(
                                                                this.state.premium ? {} : this.state.selected_tab.data_sources.channels,
                                                                this.state.premium ? {} : this.state.selected_tab.data_sources.accounts,
                                                                this.state.premium ? {} : this.state.selected_tab.data_sources.campaigns,
                                                                this.state.premium ? {} : this.state.selected_tab.data_sources.adgroups,
                                                                this.state.premium ? {} : this.state.selected_tab.data_sources.ads,
                                                                group
                                                            );
                                                        });
                                                    }
                                                })
                                            }
                                        }}
                                        cloneCell={(cell, option) => {
                                            let cell_item = null;
                                            if (cell.typeOfBlock === "chart") {
                                                let temp_data = cell.data;
                                                delete cell.data;
                                                cell_item = JSON.parse(JSON.stringify(cell));
                                                cell.data = temp_data;
                                            } else {
                                                cell_item = JSON.parse(JSON.stringify(cell));
                                            }
                                            this.cells.create(cell_item, 'clone');
                                        }}
                                        addRows={(group) => {
                                            this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                                if (item.id === group.id) {
                                                    if (item.mobile) {
                                                        if (!("mobile_rows" in item)) {
                                                            item.mobile_rows = item.rows;
                                                        }
                                                        item.mobile_rows = item.mobile_rows + 1;
                                                    } else {
                                                        item.rows = item.rows + 1;
                                                    }
                                                }
                                                return item;
                                            })
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }}
                                        removeRows={(group) => {
                                            if (group.mobile) {
                                                let last_row_contain_cells = false;
                                                this.state.selected_tab.grid_data.filter((item) => { return !item.abstract }).map((item) => {
                                                    if (item.group === group.id) {
                                                        if ((item.y + item.h) >= group.mobile_rows) {
                                                            last_row_contain_cells = true;
                                                        }
                                                    }
                                                })
                                                if (!last_row_contain_cells) {
                                                    this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                                        if (item.id === group.id) {
                                                            item.mobile_rows = item.mobile_rows - 1;
                                                        }
                                                        return item;
                                                    })
                                                }
                                            } else {
                                                let last_row_contain_cells = false;
                                                this.state.selected_tab.grid_data.filter((item) => { return !item.abstract }).map((item) => {
                                                    if (item.group === group.id) {
                                                        if ((item.y + item.h) >= group.rows) {
                                                            last_row_contain_cells = true;
                                                        }
                                                    }
                                                })
                                                if (!last_row_contain_cells) {
                                                    this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                                        if (item.id === group.id) {
                                                            item.rows = item.rows - 1;
                                                        }
                                                        return item;
                                                    })
                                                }
                                            }
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }}
                                        updatePage={(group) => {
                                            this.setState({
                                                slideinPageclosed: false,
                                                selected_page: group
                                            });
                                        }}
                                        removePage={(group) => {
                                            if (Array.isArray(this.state.selected_tab.groups)) {
                                                this.state.selected_tab.groups = this.state.selected_tab.groups.filter((item) => {
                                                    return item.id != group.id;
                                                });
                                            }
                                            if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.filter((item) => {
                                                    return item.group != group.id;
                                                });
                                            }
                                            this.setState({
                                                selected_tab: this.state.selected_tab
                                            }, () => {

                                                //GET DATA FOR SELECTED TAB OR CELLS
                                                if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                    this.state.selected_tab.grid_data.map((cell) => {
                                                        try {
                                                            this.refs.grid.updateCell(cell.i);
                                                        } catch (error) {

                                                        }
                                                    });
                                                }

                                            })
                                        }}
                                        clonePage={(group) => {
                                            if (Array.isArray(this.state.selected_tab.grid_data)) {
                                                if (!this.renders.tabLoading()) {

                                                    let group_id = 'group_' + Date.now() + Math.floor(Math.random() * 1000);
                                                    this.state.selected_tab.groups.push({
                                                        id: group_id,
                                                        name: group.name + " - CLONE",
                                                        rows: group.rows,
                                                        layout: [],
                                                        index: this.state.selected_tab.groups.length,
                                                        items: [],
                                                        coordinates: {},
                                                        pageImage: group.pageImage,
                                                        background_color: group.background_color,
                                                        enable_background: group.enable_background,
                                                        enable_background_full: group.enable_background_full,
                                                        enable_image: group.enable_image,
                                                        enable_margin: group.enable_margin,
                                                        enable_round_corners: group.enable_round_corners,
                                                        mobile: group.mobile,
                                                    });

                                                    let new_cells = [];
                                                    this.state.selected_tab.grid_data.map((cell) => {
                                                        if (cell.group === group.id && !cell.abstract) {

                                                            let new_item = {};
                                                            if (cell.typeOfBlock == "input" || cell.typeOfBlock == "img") {
                                                                new_item = JSON.parse(JSON.stringify(cell));
                                                            } else {
                                                                for (let key in cell) {
                                                                    if (key !== "data" && cell[key] !== undefined) {
                                                                        new_item[key] = JSON.parse(JSON.stringify(cell[key]));
                                                                    }
                                                                }
                                                            }
                                                            new_item.i = 'n' + Date.now() + Math.floor(Math.random() * 1000);
                                                            new_item.group = group_id;
                                                            new_item.data = cell.data;
                                                            delete new_item.selected;

                                                            //CLONE ALL ABSTRACT AND CONNECT TO NEW PARENT CORRECT CELL
                                                            let abstract_cell = null;
                                                            this.state.selected_tab.grid_data.filter((inner_cell) => {
                                                                return inner_cell.abstract;
                                                            }).map((inner_cell) => {
                                                                if (inner_cell.parent_cell && inner_cell.parent_cell === cell.i && !abstract_cell) {
                                                                    abstract_cell = {};
                                                                    if (inner_cell.typeOfBlock == "input" || inner_cell.typeOfBlock == "img") {
                                                                        abstract_cell = JSON.parse(JSON.stringify(inner_cell));
                                                                    } else {
                                                                        for (let key in inner_cell) {
                                                                            if (key !== "data" && inner_cell[key] !== undefined) {
                                                                                abstract_cell[key] = JSON.parse(JSON.stringify(inner_cell[key]));
                                                                            }
                                                                        }
                                                                    }
                                                                    abstract_cell.i = new_item.i + "_abstract";
                                                                    abstract_cell.group = group_id;
                                                                    abstract_cell.data = inner_cell.data;
                                                                    abstract_cell.parent_cell = new_item.i;
                                                                }
                                                            });
                                                            if (abstract_cell) {
                                                                new_cells.push(abstract_cell);
                                                            }

                                                            new_item.loading = false;
                                                            new_cells.push(new_item);

                                                        }
                                                    });

                                                    this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.concat(new_cells);

                                                    this.state.tabs = this.state.tabs.map((item) => {
                                                        if (item.id === this.state.selected_tab.id) {
                                                            item = this.state.selected_tab;
                                                        }
                                                        return item;
                                                    });

                                                    this.setState({
                                                        tabs: this.state.tabs,
                                                        selected_tab: this.state.selected_tab
                                                    }, () => {
                                                        this.tabs.update();
                                                    });

                                                }
                                            }
                                        }}
                                        addPage={async (index) => {
                                            if (Array.isArray(this.state.selected_tab.groups)) {
                                                let bottom = this.state.selected_tab.groups.slice(0, index);
                                                let group_id = 'group_' + Date.now() + Math.floor(Math.random() * 1000);
                                                bottom.push({
                                                    id: group_id,
                                                    name: "",
                                                    rows: 6,
                                                    layout: [],
                                                    index: index,
                                                    items: [],
                                                    coordinates: {}
                                                });
                                                let top = this.state.selected_tab.groups.slice(index);
                                                top = top.map((item) => {
                                                    item.index = +item.index + 1;
                                                    return item;
                                                });
                                                this.state.selected_tab.groups = bottom.concat(top);
                                                await this.promisedSetState({
                                                    selected_tab: this.state.selected_tab
                                                });
                                            }
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            });
                                        }}
                                        updateGroups={(groups) => {
                                            this.state.selected_tab.groups = groups;
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }}
                                        onGridChange={(data) => {
                                            this.state.selected_tab.grid_data = data;
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }}
                                        onRefreshItem={(cell) => {
                                            if (this.state.selected_tab.id) {
                                                cell.data = null;
                                                this.cells.create(cell);
                                            }
                                        }}
                                        onUpdateItem={async (cell) => {
                                            this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                                                if (item.i == cell.i) {
                                                    item = cell;
                                                }
                                                return item;
                                            });
                                            await this.promisedSetState({
                                                selected_tab: this.state.selected_tab
                                            });
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id == this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            await this.promisedSetState({
                                                tabs: this.state.tabs
                                            });
                                        }}
                                        onSelectItem={(cell) => {
                                            let other_group_selected = false;
                                            /*
                                            this.state.selected_tab.grid_data.map((item) => {
                                                if (item.selected && item.group !== cell.group) {
                                                    other_group_selected = true;
                                                }
                                            });
                                            */
                                            if (!other_group_selected) {
                                                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                                                    if (item.i === cell.i) {
                                                        item.selected = !item.selected;
                                                    }
                                                    return item;
                                                });
                                                this.state.selected_tab.grid_data = this.state.selected_tab.grid_data.map((item) => {
                                                    if (item.parent_cell === cell.i) {
                                                        item.selected = !item.selected;
                                                    }
                                                    return item;
                                                });
                                                this.state.tabs = this.state.tabs.map((item) => {
                                                    if (item.id === this.state.selected_tab.id) {
                                                        item = this.state.selected_tab;
                                                    }
                                                    return item;
                                                });
                                                this.setState({
                                                    tabs: this.state.tabs,
                                                    selected_tab: this.state.selected_tab
                                                })
                                            }
                                        }}
                                        onEditItem={(item) => {
                                            //REMOVE CHART DATA
                                            let temp_item = {};
                                            if (item.typeOfBlock == "chart") {
                                                let temp_data = item.data;
                                                delete item.data;
                                                temp_item = JSON.parse(JSON.stringify(item));
                                                item.data = temp_data;
                                            } else {
                                                temp_item = JSON.parse(JSON.stringify(item));
                                            }

                                            this.setState({
                                                selected_cell: temp_item,
                                                slideinclosed: false
                                            }, () => {
                                                let data_sources = this.state.selected_cell ? (this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources : {}) : {};
                                                this.setState({
                                                    campaigns: this.state.campaigns.map((item) => {
                                                        item.open = false;
                                                        return item;
                                                    }),
                                                    adgroups: this.state.adgroups.map((item) => {
                                                        item.open = false;
                                                        return item;
                                                    })
                                                }, () => {
                                                    this.refs.child.functions.initiate(
                                                        this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.channels : {},
                                                        this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.accounts : {},
                                                        this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.campaigns : {},
                                                        this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.adgroups : {},
                                                        this.state.selected_cell.data_sources ? this.state.selected_cell.data_sources.ads : {}
                                                    );
                                                });
                                            })
                                        }}
                                        updateGrid={(grid_data) => {
                                            this.state.selected_tab.grid_data = grid_data;
                                            this.state.tabs = this.state.tabs.map((item) => {
                                                if (item.id === this.state.selected_tab.id) {
                                                    item = this.state.selected_tab;
                                                }
                                                return item;
                                            });
                                            this.setState({
                                                tabs: this.state.tabs,
                                                selected_tab: this.state.selected_tab
                                            })
                                        }}
                                    />
                                }
                            </div>
                        </Fragment >
                    </div >
                }

                {/*UPDATE TAB DATE*/}
                <AdvancedReportSlideInDate
                    ref="child_date"
                    tab={this.state.selected_tab}
                    updateDatesPage={this.state.updateDatesPage}
                    masterTemplate={this.state.master_template && !this.state.template}
                    closed={this.state.slideinDateclosed}
                    onTab={async (tab) => {

                        //SET DATERANGE TO ALL EXISTING CELLS
                        if (Array.isArray(tab.grid_data)) {
                            tab.grid_data = tab.grid_data.map((item) => {
                                if (!this.state.updateDatesPage || (this.state.updateDatesPage && this.state.updateDatesPage.id == item.group)) {
                                    if (item.celltype && (item.celltype.value === "table" || item.celltype.value === "number" || item.celltype.value === "chart")) {
                                        item.loading = true;
                                        delete item.data;
                                        delete item.errors;
                                        if (item.daterange) {
                                            item.daterange.days = tab.daterange.days;
                                            item.daterange.start_date = tab.daterange.start_date;
                                            item.daterange.end_date = tab.daterange.end_date;
                                            item.daterange.value = tab.daterange.value;
                                            item.daterange.title = tab.daterange.title;
                                            item.daterange.subtitle = tab.daterange.subtitle;
                                            item.daterange.compare_dates = tab.daterange.compare_dates;
                                            item.daterange.compare_last_year = tab.daterange.compare_last_year;
                                            item.daterange.compare_custom_date = tab.daterange.compare_custom_date;
                                        }
                                    }
                                }
                                return item;
                            })
                        }

                        this.state.selected_tab = tab;
                        this.state.tabs = this.state.tabs.map((item) => {
                            if (item.id === this.state.selected_tab.id) {
                                item = this.state.selected_tab;
                            }
                            return item;
                        });

                        await this.promisedSetState({
                            tabs: this.state.tabs,
                            selected_tab: this.state.selected_tab
                        });

                        if (this.state.updateDatesPage) {
                            this.cells.update(this.state.selected_tab.grid_data.filter((item) => { return item.group == this.state.updateDatesPage.id }));
                        } else {
                            this.tabs.update(true);
                        }

                        await this.promisedSetState({
                            updateDatesPage: null
                        });

                    }}
                    toggle={() => {
                        this.setState({
                            slideinDateclosed: !this.state.slideinDateclosed
                        })
                    }}
                />

                {/*UPDATE PAGE*/}
                <AdvancedReportSlideInUpdatePage
                    ref="child_page"
                    closed={this.state.slideinPageclosed}
                    client={this.state.client}
                    page={this.state.selected_page}
                    onUpdate={async (page) => {
                        if (Array.isArray(this.state.selected_tab.groups)) {
                            this.state.selected_tab.groups = this.state.selected_tab.groups.map((item) => {
                                if (item.id == page.id) {
                                    item = page;
                                }
                                return item;
                            });
                            this.state.tabs = this.state.tabs.map((item) => {
                                if (item.id === this.state.selected_tab.id) {
                                    item = this.state.selected_tab;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                tabs: this.state.tabs,
                                selected_tab: this.state.selected_tab
                            });
                        }
                    }}
                    toggle={() => {
                        this.setState({
                            slideinPageclosed: !this.state.slideinPageclosed
                        })
                    }}
                />

                {/*UPDATE MULTI CELLS*/}
                <AdvancedReportSlideInDatasource
                    ref="child_datasource"
                    onlyDates={this.state.master_template && !this.state.template}
                    masterTemplate={this.state.master_template}
                    template={this.state.template}
                    closed={this.state.slideinDatasourceclosed}
                    previewSelected={this.state.selected_tab && Array.isArray(this.state.selected_tab.grid_data) && this.state.selected_tab.grid_data.filter((item) => { return item.selected && item.celltype.value === "preview" }).length > 0}
                    creativeSelected={this.state.selected_tab && Array.isArray(this.state.selected_tab.grid_data) && this.state.selected_tab.grid_data.filter((item) => { return item.selected && item.typeOfBlock === "img" }).length > 0}
                    inputSelected={this.state.selected_tab && Array.isArray(this.state.selected_tab.grid_data) && this.state.selected_tab.grid_data.filter((item) => { return item.selected && item.typeOfBlock === "input" }).length > 0}
                    selectedCells={this.state.selected_tab && Array.isArray(this.state.selected_tab.grid_data) && this.state.selected_tab.grid_data.filter((item) => { return item.selected })}
                    tab={this.state.selected_tab}
                    channels={this.state.channels}
                    accounts={this.state.accounts}
                    campaigns={this.state.campaigns}
                    adgroups={this.state.adgroups}
                    ads={this.state.ads}
                    getAdgroups={(campaign) => {
                        this.information.adgroups([{ client: campaign.client, channel: campaign.channel, campaigns: [campaign.id] }]);
                    }}
                    getAds={(adgroup) => {
                        this.information.ads([{ client: adgroup.client, channel: adgroup.channel, adgroups: [adgroup.id] }]);
                    }}
                    onCells={async (datasources) => {

                        //SET DATERANGE TO ALL EXISTING CELLS
                        if (Array.isArray(this.state.selected_tab.grid_data)) {
                            this.state.selected_tab.grid_data.map((item) => {
                                if (item.selected) {

                                    if (!item.settings) {
                                        item.settings = {};
                                    }

                                    //COLOR
                                    if (datasources.enable_styling) {
                                        item.settings.background_color = datasources.background_color ? datasources.background_color : "000000";
                                        item.settings.font_color = datasources.font_color ? datasources.font_color : "FFFFFF";
                                        item.settings.shadow = datasources.shadow;
                                        item.settings.transparent = datasources.transparent;
                                    }

                                    //DATERANGE
                                    if ((item.celltype.value === "table" || item.celltype.value === "number" || item.celltype.value === "chart")) {
                                        if (datasources.daterange && Object.keys(datasources.daterange).length > 0) {
                                            item.loading = true;
                                            item.daterange = datasources.daterange;
                                            delete item.data;
                                            delete item.errors;
                                        }
                                    }

                                    //DATASOURCES
                                    if ((item.celltype.value === "preview" || item.celltype.value === "table" || item.celltype.value === "number" || item.celltype.value === "chart")) {
                                        if ((datasources.channels && Object.keys(datasources.channels).length > 0) || (datasources.accounts && Object.keys(datasources.accounts).length > 0) || (datasources.campaigns && Object.keys(datasources.campaigns).length > 0) || (datasources.adgroups && Object.keys(datasources.adgroups).length > 0) || (datasources.ads && Object.keys(datasources.ads).length > 0)) {
                                            item.loading = true;
                                            item.data_sources = {
                                                channels: datasources.channels ? JSON.parse(JSON.stringify(datasources.channels)) : {},
                                                accounts: datasources.accounts ? JSON.parse(JSON.stringify(datasources.accounts)) : {},
                                                campaigns: datasources.campaigns ? JSON.parse(JSON.stringify(datasources.campaigns)) : {},
                                                adgroups: datasources.adgroups ? JSON.parse(JSON.stringify(datasources.adgroups)) : {},
                                                ads: datasources.ads ? JSON.parse(JSON.stringify(datasources.ads)) : {}
                                            };
                                            delete item.data;
                                            delete item.errors;
                                        }
                                    }

                                }
                            })
                        }

                        await this.promisedSetState({
                            tabs: this.state.tabs,
                            selected_tab: this.state.selected_tab
                        });

                        this.cells.update(this.state.selected_tab.grid_data.filter((item) => { return item.selected }));

                    }}
                    toggle={() => {
                        this.setState({
                            slideinDatasourceclosed: !this.state.slideinDatasourceclosed
                        })
                    }}
                />
                {/*CELL SLIDE IN*/}
                <AdvancedReportCellSlideIn
                    ref="child"
                    premium={this.state.premium}
                    closed={this.state.slideinclosed}
                    toggle={() => {
                        this.setState({
                            slideinclosed: !this.state.slideinclosed
                        })
                    }}
                    close={() => {
                        this.setState({
                            slideinclosed: true
                        })
                    }}
                    masterTemplate={this.state.master_template}
                    template={this.state.template}
                    tab={this.state.selected_tab}
                    cell={this.state.selected_cell}
                    metrics={this.state.metrics}
                    accounts={this.state.accounts}
                    campaigns={this.state.campaigns}
                    adgroups={this.state.adgroups}
                    ads={this.state.ads}
                    getAdgroups={(campaign) => {
                        this.information.adgroups([{ client: campaign.client, channel: campaign.channel, campaigns: [campaign.id] }]);
                    }}
                    getAds={(adgroup) => {
                        this.information.ads([{ client: adgroup.client, channel: adgroup.channel, adgroups: [adgroup.id] }]);
                    }}
                    updateData={(cell) => {
                        if (this.state.selected_tab.id) {
                            if (!(cell.celltype && (cell.celltype.value === 'image' || cell.celltype.value === "text"))) {
                                cell.data = null;
                            }
                            this.cells.create(cell);
                        }
                    }}
                />

                {/*POWER POINT*/}
                <CreatePowerPoint
                    ref={"pptx"}
                    onCreated={() => {
                        this.setState({
                            ppxt_success: true
                        })
                    }}
                />

            </div >
        );
    }
}

export default AdvancedReportInternal;
